import { reactive, ref } from 'vue'
import Uppy from '@uppy/core'
import Transloadit from '@uppy/transloadit'

const MAX_FILE_SIZE = 15 * 1024 * 1024 // 15 MB
const TRANSLOADIT_AUTH_KEY = import.meta.env.VITE_TRANSLOADIT_AUTH_KEY

export const useUploader = (props: any) => {
  const accept = ref(props.accept)

  const state = reactive({
    files: {
      selected: [],
      uploaded: [],
      failed: [],
    },
    progress: null,
    status: 'idle',
  })

  const uppy = new Uppy({
    restrictions: {
      allowedFileTypes: props.accept,
      maxFileSize: MAX_FILE_SIZE,
    },
  }).use(Transloadit, {
    assemblyOptions: {
      params: {
        auth: { key: TRANSLOADIT_AUTH_KEY },
        template_id: props.transloadit_template_id,
      },
    },
  })

  uppy.on('complete', (result) => {
    state.files.failed = result.failed
    state.files.uploaded = result.successful
    state.status = 'complete'
  })

  uppy.on('error', (error) => console.error(error.stack))

  uppy.on('progress', (value) => (state.progress = value))

  uppy.on('upload', (data) => {
    state.progress = 0
    state.status = 'uploading'
  })

  async function upload(files: File[]) {
    // @ts-ignore
    files.forEach((file) => uppy.addFile(file))
    await uppy.upload()
  }

  return {
    accept,
    props,
    state,
    upload,
  }
}
