<script setup lang="ts">
import { toRefs } from 'vue'

const props = defineProps({
  label: String,
  modelValue: String,
  placeholder: String,
  type: String,
})

const { label, modelValue, placeholder, type } = toRefs(props)
</script>

<template>
  <label class="mt-4 block cursor-text rounded-sm bg-gray-200 px-4 py-2">
    <div class="text-sm leading-5 text-gray-500">{{ label }}</div>
    <input
      :type="type"
      class="mt-1 block w-full border-0 bg-transparent p-0 text-base font-normal font-normal leading-6 text-gray-800 placeholder-gray-400 outline-none focus:placeholder-gray-300"
      :placeholder="placeholder"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    />
  </label>
</template>
