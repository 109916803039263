<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { usePortfolioStore } from '@/modules/investing/stores/portfolio-store'
import { useRoute } from 'vue-router'
import { useInvestingInvestorStore } from '@/modules/investing/stores/investor-store'
import { Portfolio, VButton, VIcon } from '@/modules/shared/components'
import IndividualHeader from '@/modules/investing/components/individual-header.vue'
import IndividualNav from '@/modules/investing/components/individual-nav.vue'
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import { rails_url } from '@/modules/shared/utils/rails'
import { useAuthStore } from '@/modules/auth/stores/auth-store'
import { useOtherEntityStore } from '../stores/other-entity-store'
import { useWorkspaceStore } from '@/modules/workspace/stores/workspace-store'

const route = useRoute()
const authStore = useAuthStore()
const portfolioStore = usePortfolioStore()
const investorStore = useInvestingInvestorStore()
const otherEntityStore = useOtherEntityStore()
const workspaceStore = useWorkspaceStore()

const skeleton = ref(true)
const current_individual = ref(null)
const portfolioUtil = ref(null)

const is_viewing_own_profile = computed(
  () => route.params.individual_id === authStore.current_user.investor_id?.toString(),
)
const is_company_link_enabled = computed(() => {
  if (authStore.is_site_or_group_admin) return true
  return (
    is_viewing_own_profile.value &&
    workspaceStore.current.options.can_access_rails &&
    workspaceStore.current.options.is_company_list_enabled
  )
})

const methodOptions = computed(() => {
  const options = [
    {
      label: current_individual.value?.name,
      value: `individual:${route.params.individual_id}`,
    },
  ]

  otherEntityStore.other_entities.forEach((other_entity) => {
    options.push({
      label: other_entity.name,
      value: other_entity._cid,
    })
  })

  return options
})

onMounted(async () => {
  await Promise.all([
    workspaceStore.fetchCurrent(),
    investorStore.fetchIndividual(route.params.individual_id as string),
    portfolioStore.fetchPortfolio({ _cid: `individual:${route.params.individual_id}`, as_of_date: new Date() }),
    otherEntityStore.fetchOtherEntities('individual', route.params.individual_id),
  ])
  portfolioUtil.value.fetch = {
    call: portfolioStore.fetchPortfolio,
    payload: { _cid: `individual:${route.params.individual_id}`, as_of_date: new Date() },
  }
  portfolioUtil.value.setFilterAll()
  current_individual.value = investorStore.items.get(`individual:${route.params.individual_id}`)
  skeleton.value = false
})
</script>

<template>
  <TheLayout>
    <IndividualHeader />
    <IndividualNav />
    <div class="-mt-7 mb-5 flex justify-end">
      <a :href="`${rails_url()}/investments/new`" v-if="authStore.is_site_or_group_admin">
        <VButton size="md" variant="v-blue">
          <div class="flex items-center gap-2">
            <VIcon name="plus" />
            Add Investment
          </div>
        </VButton>
      </a>
    </div>
    <Portfolio
      v-model="portfolioUtil"
      :cid="`individual:${route.params.individual_id}`"
      :customMethodOptions="methodOptions"
      :investments="portfolioStore.investments"
      :is_column_method_visible="true"
      :skeleton="skeleton"
      :is_header_visible="false"
      :is_company_link_disabled="!is_company_link_enabled"
    />
  </TheLayout>
</template>
