<template>
  <svg viewBox="0 0 24 24" fill="none">
    <path
      d="M7 15L12 20L17 15M7 9L12 4L17 9"
      stroke="currentColor"
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
      vector-effect="non-scaling-stroke"
    />
  </svg>
</template>
