<script setup lang="ts">
import { computed } from 'vue'
import { calculate_usage } from '../stores/utils'

const props = withDefaults(
  defineProps<{
    subscription: any
  }>(),
  {
    subscription: null,
  },
)

// prettier-ignore
const usage = computed(() =>
  calculate_usage(
    props.subscription.current_period_start,
    props.subscription.current_period_end
  )
)

const piePath = computed(() => {
  const radius = 50
  const angle = usage.value * Math.PI * 2
  const x = radius + Math.sin(angle) * radius
  const y = radius - Math.cos(angle) * radius
  const largeArcFlag = angle > Math.PI ? 1 : 0

  return `M50,50 L50,1 A50,50 0 ${largeArcFlag},1 ${x},${y} Z`
})
</script>

<template>
  <div class="relative flex h-4 w-4 items-center justify-center rounded-full p-[2px]">
    <svg class="h-3 w-3 rounded-full" viewBox="0 0 100 100">
      <path :d="piePath" fill="currentColor" />
    </svg>
    <svg class="absolute h-4 w-4" viewBox="0 0 24 24" fill="none">
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>
