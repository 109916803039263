<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useAuthStore } from '@/modules/auth/stores/auth-store'
import { createOptions } from '@/modules/shared/utils/form'
import { useWorkspaceStore } from '@/modules/workspace/stores/workspace-store'
import MissingProfile from '@/modules/shared/assets/missing-profile-picture.png'
import { VButton, VDropdownImproved, VIcon, VLink, VNotification } from '@/modules/shared/components'
import { rails_url } from '@/modules/shared/utils/rails'

function titleCase(str) {
  if (!str) return ''
  var splitStr = str.toLowerCase().split(' ')
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  // Directly return the joined string
  return splitStr.join(' ')
}

const route = useRoute()
const router = useRouter()
const authStore = useAuthStore()
const workspaceStore = useWorkspaceStore()
workspaceStore.reset()

const isAdmin = computed(() => authStore.current_user.role !== 'investor')
const current_user = computed(() => authStore.current_user)
const is_nav_open = ref(false)

const profile_dropdown_target = ref(null)
const isProfileClicked = ref(false)
onClickOutside(profile_dropdown_target, (e) => (isProfileClicked.value = false))

///////////////////////////////////////////////////////////////////////////////
// Workspace selector
///////////////////////////////////////////////////////////////////////////////

const selectedGroupKey = ref([route.params.slug])
const selectedGroup = computed(
  () => authStore.current_user?.groups?.find((group) => group.subdomain === route.params.slug),
)
const workspaces = computed(
  () =>
    authStore.current_user?.groups?.reduce((acc, group) => {
      acc[group.subdomain] = { name: group.name }
      return acc
    }, {}),
)
const groupOptions = computed(() => createOptions(workspaces.value, { label: 'name' }))

watch(selectedGroupKey, () => {
  router.push({ name: route.name, params: { slug: selectedGroupKey.value[0] } })
})

///////////////////////////////////////////////////////////////////////////////

const can_view_companies = computed(() => is_current_investor.value)

const is_current_investor = computed(() => ['active', 'past-due'].includes(authStore.current_user.status))

const nav_links = computed(() => {
  if (isAdmin.value) {
    return [
      { label: 'Dashboard', to: { name: 'dashboard' } },
      { label: 'Portfolio', to: { name: 'investing.portfolio' } },
      {
        label: 'Funds',
        to: { name: 'investing.entities', params: { entity_type: 'funds' } },
      },
      {
        label: 'SPVs',
        to: { name: 'investing.entities', params: { entity_type: 'spvs' } },
      },
      { label: 'Investors', to: { name: 'investing.individuals' } },
      { label: 'Companies', to: `${rails_url()}/companies` },
      { label: 'Management', to: `${rails_url()}/group/settings` },
      {
        label: 'Taxes',
        to: {
          name: 'tax.entities',
          params: { slug: route.params.slug },
          query: { year: 2023 },
        },
        is_hidden: !workspaceStore.current.has_managed_entities,
      },
    ]
  } else {
    return [
      { to: { name: 'dashboard' }, icon: 'home', label: 'Home' },
      {
        label: 'Investors',
        to: { name: 'investing.individuals' },
        is_hidden: workspaceStore.current.individual_count === 0,
      },
      {
        label: 'Companies',
        to: `${rails_url()}/companies`,
        is_hidden: !workspaceStore.current.options.is_company_list_enabled || !can_view_companies.value,
      },
    ]
  }
})

const profile_links = computed(() => {
  if (isAdmin.value) {
    return [
      { label: 'Group Settings', to: `${rails_url()}/group/settings` },
      { label: 'Login Settings', to: `${rails_url()}/user/settings` },
      { label: 'Log Out', to: `${rails_url()}/logout` },
    ]
  } else {
    return [
      {
        label: 'My Account',
        to: { name: 'investing.individual.account', params: { individual_id: current_user.value.investor_id } },
      },
      { label: 'Login Settings', to: `${rails_url()}/user/settings` },
      { label: 'Log Out', to: `${rails_url()}/logout` },
    ]
  }
})

const isNavActive = (nav) => {
  if (typeof nav.to === 'string') return false
  if (nav.to.name === 'investing.entities') return route.params.entity_type === nav.to.params.entity_type
  return route.name === nav.to.name
}

const isBannerVisible = computed(
  () => workspaceStore.current.options.is_setup_mode_enabled && authStore.is_site_or_group_admin,
)
onMounted(async () => {
  await workspaceStore.fetchCurrent()
})
</script>

<template>
  <VNotification />
  <div v-if="isBannerVisible" class="fixed left-0 right-0 top-0 z-50 block bg-white/5 backdrop-blur">
    <div
      class="flex h-8 items-center justify-center bg-gradient-to-r from-violet-500 to-indigo-600 text-center text-sm font-medium text-white opacity-90"
    >
      Setup mode is enabled. Automated emails will not be sent.
    </div>
  </div>
  <div class="fixed left-0 right-0 z-50 block bg-white/5 backdrop-blur" :class="isBannerVisible ? 'top-8' : 'top-0'">
    <div class="h-1 bg-gradient-to-r from-[#52B7B9] to-[#3783B0] opacity-90"></div>
  </div>
  <div
    class="fixed z-20 hidden h-[80px] w-full items-center border-b border-gray-300 bg-white px-4 py-3 shadow-sm sm:flex"
    :class="isBannerVisible ? 'top-[36px]' : 'top-1'"
  >
    <div class="flex w-[220px] items-center gap-6">
      <a :href="rails_url()" class="-mt-1 block">
        <img src="/v-logo-192x192.png" class="w-8 hover:opacity-90" />
      </a>
      <div class="relative" v-if="groupOptions.length">
        <VDropdownImproved v-model="selectedGroupKey" aligned="left" :multi="false" :options="groupOptions">
          <VButton>
            <div class="flex items-center space-x-2">
              <div>{{ selectedGroup?.name }}</div>
              <VIcon name="chevron_selector_vertical" />
            </div>
          </VButton>
        </VDropdownImproved>
      </div>
    </div>
    <div class="-mb-[2px] flex flex-grow items-center space-x-6 pl-8 text-lg font-medium">
      <VLink :to="nav.to" v-for="nav in nav_links" v-show="!nav.is_hidden">
        <div
          class="flex items-center gap-1 px-0 py-6"
          :class="[isNavActive(nav) ? 'border-b-2 border-[#3982AE] text-[#3982AE]' : '']"
        >
          <VIcon :name="nav.icon" v-if="nav.icon" class="m-1 h-5 w-5" />
          <span v-if="nav.label">{{ nav.label }}</span>
        </div>
      </VLink>
    </div>
    <div class="relative" ref="profile_dropdown_target">
      <div class="flex cursor-pointer items-center gap-3" @click="isProfileClicked = !isProfileClicked">
        <img
          :src="current_user?.profile_picture ? current_user.profile_picture : MissingProfile"
          class="h-[35px] w-[35px] rounded-full border-[1px] border-gray-300 object-cover"
        />
        <div class="flex min-w-[100px] flex-col">
          <span class="text-sm font-bold">{{ titleCase(current_user?.name) }}</span>
          <span class="text-xs">{{ titleCase(current_user?.role?.replace('_', ' ')) }}</span>
        </div>
        <VIcon name="chevron_down" />
      </div>
      <div
        class="absolute right-0 top-[120%] z-10 flex min-w-[200px] flex-col gap-0.5 rounded-lg border-[1px] border-gray-300 bg-white py-1"
        v-if="isProfileClicked"
      >
        <VLink :to="link.to" v-for="link in profile_links" class="px-5 py-1 hover:bg-[#e8f1f7] hover:text-[#006591]">
          {{ link.label }}
        </VLink>
      </div>
    </div>
  </div>
  <div
    class="fixed z-20 h-fit w-full bg-white px-3 shadow-sm sm:hidden"
    :class="isBannerVisible ? 'top-[36px]' : 'top-1'"
  >
    <div class="flex h-[50px] items-center gap-3">
      <VIcon name="burger-menu" class="block h-5 w-5 cursor-pointer" @click="() => (is_nav_open = !is_nav_open)" />
      <span class="text-lg font-bold">Dashboard</span>
      <div class="relative ml-auto mr-0">
        <div class="flex cursor-pointer items-center gap-3" @click="isProfileClicked = !isProfileClicked">
          <img
            :src="current_user.profile_picture ? current_user.profile_picture : MissingProfile"
            class="h-[35px] w-[35px] rounded-full border-[1px] border-gray-300 object-cover"
          />
        </div>
        <div
          class="absolute right-0 top-[120%] z-10 flex min-w-[150px] flex-col gap-0.5 rounded-lg border-[1px] border-gray-300 bg-white py-1 sm:min-w-[200px]"
          v-if="isProfileClicked"
        >
          <VLink
            :to="link.to"
            v-for="link in profile_links"
            class="sm:text-md px-3 py-1 text-sm hover:bg-[#e8f1f7] hover:text-[#006591] sm:px-5"
          >
            {{ link.label }}
          </VLink>
        </div>
      </div>
    </div>
    <div
      class="flex w-full flex-col gap-3 overflow-hidden transition-all duration-300 ease-in-out"
      :class="[is_nav_open ? 'my-2 h-[280px] ' : 'h-0']"
    >
      <VLink :to="nav.to" v-for="nav in nav_links" :key="nav.to">
        <div :class="[isNavActive(nav) ? 'text-[#3982AE]' : '']">
          {{ nav.label }}
        </div>
      </VLink>
    </div>
  </div>
</template>
