<script setup lang="ts">
import { useExtendedI18n } from '@/i18n'
import { startCase } from 'lodash'
import { useRoute, useRouter } from 'vue-router'
import { VTabListSimple } from '@/modules/shared/components'

const { t } = useExtendedI18n()
const route = useRoute()
const router = useRouter()
const tabs = [
  {
    label: startCase(t('shared.individual', 0)),
    to: { name: 'investing.individuals' },
  },
  {
    label: startCase(t('shared.associated entity', 0)),
    to: { name: 'investing.other-entities' },
  },
  {
    label: startCase(t('shared.subgroup', 0)),
    to: { name: 'investing.subgroups' },
  },
]
const current_index = tabs.findIndex((tab) => tab.to.name === route.name)
const tab_names = tabs.map((tab) => tab.label)

const redirectPage = (index) => {
  const selected_tab = tabs[index]
  router.push(selected_tab.to)
}
</script>

<template>
  <VTabListSimple @update:current="redirectPage" :current="current_index" :tabs="tab_names" class="mb-6" size="base" />
</template>
