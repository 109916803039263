import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useFetch } from '@/modules/shared/composables/use-fetch'
import { addItem, addItems, clearItems } from '@/modules/shared/utils/store'
import { CID } from '@/modules/shared/utils/store.types'
import { Money } from '@/modules/shared/utils/money'
import { useNotificationStore } from '@/modules/shared/stores/notification-store'

///////////////////////////////////////////////////////////////////////////////
// Types
///////////////////////////////////////////////////////////////////////////////

type ID = number | string

type Distribution = {
  _cid: CID
  _key: string
  id: string
  capital: Money
  company: any
  date: string
  investor: {
    _cid: CID
    _custom_type: string
    id: ID
    name: string
    disbursement_method: string
    disbursement_method_confirmed_at: string
    disbursement_method_confirmed_by: string
    disbursement_wire_bank_name: string
    disbursement_wire_bank_address: string
    disbursement_wire_bank_routing_number: string
    disbursement_wire_bank_account_number: string
    disbursement_wire_bank_swift_code: string
    disbursement_wire_account_name: string
    disbursement_wire_for_further_credit_to: string
    disbursement_check_receiver_name: string
    disbursement_other_details: string
  }
  management_fee: Money
  other_fee: Money
  payment_method: string
  payment_status: string
  payment_status_marked_at: string
  total: Money
  type: 'distribution'
}

///////////////////////////////////////////////////////////////////////////////
// Store
///////////////////////////////////////////////////////////////////////////////

type DistributionMap = Map<CID, Distribution>

export const useDistributionStore = defineStore('investing/distributionStore', () => {
  const route = useRoute()
  const notificationStore = useNotificationStore()
  const baseUrl = computed(() => `/${route.params.slug}/investing`)

  const items = ref<DistributionMap>(new Map())
  const distributions = computed(() => Array.from(items.value.keys()).map((key) => items.value.get(key)))

  const fetchDistributions = async (investable_type, investable_id) => {
    const { data, error } = await useFetch(`${baseUrl.value}/${investable_type}/${investable_id}/distributions`)
      .get()
      .json<{ data: Distribution[] }>()

    if (error.value) {
      // TODO: handle error (e.g., display a message to the user)
      console.error(error.value)
      return
    }

    clearItems(items)
    addItems(
      items,
      data.value.data.map((distribution) => ({ ...distribution, _cid: distribution.id })),
    )
  }

  const fetchDistribution = async (distribution_id: CID, investable_type, investable_id) => {
    const { data, error } = await useFetch(
      `${baseUrl.value}/${investable_type}/${investable_id}/distribution/${distribution_id}`,
    )
      .get()
      .json<{ data: Distribution }>()

    if (error.value) {
      // TODO: handle error (e.g., display a message to the user)
      console.error(error.value)
      return
    }

    const distribution = data.value.data

    addItem(items, { ...distribution, _cid: distribution.id })
  }

  type MarkPaymentAsPaidPayload = {
    id: string
    date: string
  }

  const markPaymentAsPaid = async (distribution: MarkPaymentAsPaidPayload, investable_type, investable_id) => {
    await useFetch(
      `${baseUrl.value}/${investable_type}/${investable_id}/distribution/${distribution.id}/mark-payment-as-paid`,
    )
      .post({ distribution })
      .json<{}>()
    await fetchDistribution(distribution.id, investable_type, investable_id)
    await notificationStore.enqueue('success', 'Success!')
  }

  const markPaymentAsUnpaid = async (distribution_id: ID, investable_type, investable_id) => {
    await useFetch(
      `${baseUrl.value}/${investable_type}/${investable_id}/distribution/${distribution_id}/mark-payment-as-unpaid`,
    )
      .post()
      .json<{}>()
    await fetchDistribution(distribution_id, investable_type, investable_id)
    await notificationStore.enqueue('success', 'Success!')
  }

  const removeDistribution = async (distribution_id: ID, investable_type, investable_id) => {
    await useFetch(`${baseUrl.value}/${investable_type}/${investable_id}/distribution/${distribution_id}`).delete()
    await fetchDistributions(investable_type, investable_id)
  }

  return {
    items,
    distributions,

    fetchDistributions,
    fetchDistribution,
    markPaymentAsPaid,
    markPaymentAsUnpaid,
    removeDistribution,
  }
})
