<script setup lang="ts">
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    max?: number
    min?: number
    width?: string
  }>(),
  {
    min: 64,
    max: 120,
  },
)

const computedWidth = computed(() => {
  if (props.width) return props.width

  return `${Math.floor(Math.random() * (props.max - props.min + 1) + props.min)}px`
})
</script>

<template>
  <div class="inline-block h-2.5 animate-pulse rounded-full bg-gray-200" :style="{ width: computedWidth }"></div>
</template>
