<script setup lang="ts">
import { endOfDay } from 'date-fns'
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { VButton, VIcon } from '@/modules/shared/components'
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import { locateData } from '@/modules/shared/utils/data'
import { rails_url } from '@/modules/shared/utils/rails'
import { useInvestorStore } from '@/modules/entity/stores/investor-store'
import CallsList from '../components/calls-list.vue'
import TheHeader from '../components/the-header.vue'
import TheNav from '../components/the-nav.vue'
import { useCallStore } from '../stores/call-store'
import { useEntityStore } from '../stores/entity-store'
import { useInvestingVehicleStore } from '../stores/vehicle-store'
import { getChartData, transformEntity } from '../utils/entity'

const route = useRoute()

///////////////////////////////////////////////////////////////////////////////

const entityStore = useEntityStore()
const currentEntity = ref(null)
const investorStore = useInvestorStore()

const vehicleStore = useInvestingVehicleStore()
const cid = computed(() => `${route.params.entity_type}:${route.params.entity_id}`)
const isAdmin = computed(() => vehicleStore.items.get(cid.value) === 'admin')
const callStore = useCallStore()

const currentCalls = computed(() =>
  callStore.calls.filter((call) => investorStore.state.selectedKeys.includes(call.investor._cid)),
)

const chartData = computed(() => {
  const entities = currentEntity.value
    ? transformEntity([currentEntity.value] || [], cid.value, true, investorStore.state.selectedKeys)
    : []
  return getChartData(entities, true, investorStore.state.selectedKeys)
})

const skeleton = ref(true)

onMounted(async () => {
  await entityStore.fetchEntity({
    type: route.params.entity_type as string,
    id: route.params.entity_id as string,
  })

  await investorStore.reset()
  // prettier-ignore
  await Promise.all([
    vehicleStore.fetchVehicles(),
    callStore.fetchCalls(route.params.entity_type, route.params.entity_id),
    investorStore.listProfiles(),
  ])

  currentEntity.value = entityStore.items.get(`${route.params.entity_type}:${route.params.entity_id}`)
  skeleton.value = false
})
</script>

<template>
  <TheLayout>
    <TheHeader />
    <TheNav />
    <CallsList
      :calls="currentCalls"
      :currency="currentEntity?.currency || 'USD'"
      :disabledColumns="['entity.name']"
      :investable_id="route.params.entity_id as string"
      :investable_type="route.params.entity_type as string"
      :isAdmin="isAdmin"
      :skeleton="skeleton"
      :chartData="chartData"
    >
      <template #actions>
        <a
          :href="`${rails_url()}/funds/${route.params.entity_id}/capital-calls/new`"
          class="inline-block"
          v-if="isAdmin"
        >
          <VButton size="md" variant="v-blue">
            <div class="mr-1 flex items-center space-x-2">
              <div><VIcon name="plus" /></div>
              <div>Call capital</div>
            </div>
          </VButton>
        </a>
      </template>
    </CallsList>
  </TheLayout>
</template>
