import { defineStore } from 'pinia'
import { ref, Ref } from 'vue'

type NotificationType = 'error' | 'info' | 'success' | 'warning'
type Notification = {
  id: number
  type: NotificationType
  message: string
}

export const useNotificationStore = defineStore('notificationStore', () => {
  const notifications: Ref<Notification[]> = ref([])

  const enqueue = (type: NotificationType, message: string) => {
    const id = new Date().getTime()
    notifications.value.push({ id, type, message })

    setTimeout(() => {
      remove(id)
    }, 5000)
  }

  const dequeue = () => {
    notifications.value.shift()
  }

  const remove = (id: number) => {
    notifications.value = notifications.value.filter((notification) => notification.id !== id)
  }

  return {
    notifications,
    enqueue,
    dequeue,
    remove,
  }
})
