<script setup lang="ts">
import { capitalize } from 'lodash'
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useExtendedI18n } from '@/i18n'
import { useAuthStore } from '@/modules/auth/stores/auth-store'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import {
  RouterLinkBack,
  TheContent,
  VButton,
  VButtonInvisible,
  VDescriptionList,
  VIcon,
  VModal,
  VSection,
  VTable,
} from '@/modules/shared/components'
import VSelect from '../components/v-select.vue'
import { useModal } from '@/modules/shared/composables/use-modal'
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import { markDisabledOptions } from '@/modules/shared/utils/form'
import { rails_url } from '@/modules/shared/utils/rails'
import { useInvestingInvestorStore } from '../stores/investor-store'
import { useSubgroupStore } from '../stores/subgroup-store'
import { VDropdownMultiple, VTextField } from '@/modules/shared/components'

const { n, t } = useExtendedI18n()
const route = useRoute()
const authStore = useAuthStore()
const investingInvestorStore = useInvestingInvestorStore()
const subgroupStore = useSubgroupStore()
const isAdmin = computed(() => authStore.is_site_or_group_admin)
const skeleton = ref(true)
const currentSubgroup = computed(() => subgroupStore.items.get(`subgroup:${route.params.subgroup_id}`))

///////////////////////////////////////////////////////////////////////////////
// EDIT SUBGROUP
///////////////////////////////////////////////////////////////////////////////
const subgroupFormModal = useModal()
const subgroupInitialState = {
  id: null,
  name: null,
  admin_ids: [],
}
const subgroup = ref({ ...subgroupInitialState })
const subgroup_rules = {
  name: { required },
}
const vs$ = useVuelidate(subgroup_rules, subgroup, { $lazy: true })

const subgroupIndividualOptions = computed(() => {
  const disabledIndividuals = []
  const options = investingInvestorStore.individuals.map((individual) => ({
    label: individual.name,
    value: individual._cid,
  }))
  return markDisabledOptions(options, disabledIndividuals).sort((a, b) => a.label.localeCompare(b.label))
})

const resetSubgroupFields = () => {
  v$.value.$reset()
  subgroup.value = { ...subgroupInitialState }
}

const showSubgroupForm = () => {
  resetSubgroupFields()
  subgroup.value = {
    id: currentSubgroup.value.id,
    name: currentSubgroup.value.name,
    admin_ids: currentSubgroup.value.admins.map((admin) => admin._cid),
  }
  subgroupFormModal.open()
}

const saveSubgroup = async () => {
  const valid = await vs$.value.$validate()
  if (!valid) return

  subgroup.value.admin_ids = [...subgroup.value.admin_ids].map(
    (admin_id) => investingInvestorStore.items.get(admin_id).id,
  )
  await subgroupStore.updateSubgroup(subgroup.value)
  subgroupFormModal.close()
}

///////////////////////////////////////////////////////////////////////////////
// ADD INVESTOR
///////////////////////////////////////////////////////////////////////////////
const addIndividualFormModal = useModal()
const initialState = {
  individual_id: null,
}
const individual = ref({ ...initialState })
const rules = {
  individual_id: { required },
}
const v$ = useVuelidate(rules, individual, { $lazy: true })

const resetFields = () => {
  v$.value.$reset()
  individual.value = { ...initialState }
}

const showAddIndividualForm = () => {
  resetFields()
  addIndividualFormModal.open()
}

const individualOptions = computed(() => {
  const disabledIndividuals = [...currentSubgroup.value?.individuals.map((c) => c._cid)]
  const options = investingInvestorStore.individuals.map((individual) => ({
    label: individual.name,
    value: individual._cid,
  }))
  return markDisabledOptions(options, disabledIndividuals)
})

const saveIndividual = async () => {
  const valid = await v$.value.$validate()
  if (!valid) return

  const individual_id = investingInvestorStore.items.get(individual.value.individual_id).id
  await subgroupStore.addIndividual(route.params.subgroup_id, individual_id)
  resetFields()
}

const removeIndividual = async (individual_id: string) => {
  const subgroup_id = route.params.subgroup_id.toString()
  if (!window.confirm('Are you sure?')) return
  await subgroupStore.removeIndividual(subgroup_id, individual_id)
}

onMounted(async () => {
  await Promise.all([
    subgroupStore.fetchSubgroup(route.params.subgroup_id),
    investingInvestorStore.fetchIndividuals('all'),
  ])
  skeleton.value = false
})
</script>

<template>
  <TheLayout>
    <!-- this is same as the-header.vue in shared/components -->
    <header class="mb-6 px-4 sm:px-0">
      <div class="-mt-8 mb-6">
        <RouterLinkBack
          :to="{
            name: 'investing.subgroups',
          }"
        >
          <VButtonInvisible
            >← {{ capitalize(t('shared.back')) }} to all {{ capitalize(t('shared.subgroup', 0)) }}
          </VButtonInvisible>
        </RouterLinkBack>
      </div>
      <div class="flex flex-col-reverse sm:flex-row sm:items-start sm:justify-between">
        <div>
          <h2 class="mb-2 text-3xl font-bold leading-7 text-gray-900 dark:text-gray-300">
            {{ currentSubgroup?.name }}
          </h2>
        </div>
      </div>
    </header>
    <TheContent>
      <template #main>
        <VSection>
          <div class="mb-3">
            <div v-if="authStore.is_site_or_group_admin" class="flex items-center justify-end space-x-1.5">
              <VButton :click="showAddIndividualForm" size="md" variant="v-blue" v-if="isAdmin">
                <div class="mr-1 flex items-center space-x-2">
                  <div><VIcon name="plus" /></div>
                  <div>{{ capitalize(t('shared.add individual', 0)) }}</div>
                </div>
              </VButton>
            </div>
          </div>
          <VTable
            :columns="[
              {
                key: 'name',
                name: capitalize(t('shared.individual')),
                type: 'string',
                align: 'left',
                is_visible: true,
              },
              {
                key: 'actions',
                name: '',
                type: 'actions',
                align: 'right',
                is_visible: true,
              },
            ]"
            :items="currentSubgroup?.individuals || []"
            :name="`subgroups-${route.params.subgroup_id}-individuals`"
            :skeleton="skeleton"
            :slots="['actions', 'name']"
            no_data_message="Admins of Subgroups are able to view the entire account details of all individuals in a Subgroup. Please proceed with caution."
          >
            <template #name="{ item }">
              <a
                class="cursor-pointer whitespace-break-spaces font-medium text-[#51889B] underline decoration-[#51889B]/50"
                :href="`${rails_url()}/investors/${item.id}`"
              >
                {{ item.name }}
              </a>
            </template>
            <template #actions="{ item }" v-if="isAdmin">
              <div class="flex items-center justify-end space-x-1.5">
                <!-- <VButton size="xs">View As</VButton> -->
                <VButton :click="() => removeIndividual(item.id)" size="xs">{{
                  capitalize(t('shared.remove'))
                }}</VButton>
              </div>
            </template>
          </VTable>
        </VSection>
      </template>

      <template #aside>
        <VSection :label="capitalize(t('shared.detail', 0))">
          <template #actions v-if="isAdmin">
            <VButton size="xs" :click="showSubgroupForm">{{ capitalize(t('shared.edit')) }}</VButton>
          </template>

          <template #default>
            <VDescriptionList
              class="mb-1"
              :items="[
                {
                  term: capitalize(t('shared.admin', 0)),
                  description: '',
                },
              ]"
              :skeleton="skeleton"
              :layout="currentSubgroup?.admins?.length > 1 ? 'stacked' : 'adjacent'"
            >
              <template #description="{ item }">
                <span v-for="(admin, index) in currentSubgroup?.admins" :key="admin.id">
                  <RouterLink
                    class="text-[#3b88af] underline decoration-[#3b88af]/50 hover:text-gray-900 hover:decoration-gray-900/50"
                    :to="{ name: 'investing.individual-overview', params: { individual_id: admin.id } }"
                  >
                    {{ admin.name }}
                  </RouterLink>
                  <span v-if="index !== currentSubgroup?.admins.length - 1">, </span>
                </span>
              </template>
            </VDescriptionList>
          </template>
        </VSection>
      </template>
    </TheContent>

    <VModal :modalStore="addIndividualFormModal">
      <template #main>
        <VSection :label="capitalize(t('shared.add individual'))">
          <form @submit.prevent="saveIndividual">
            <div class="mt-6 space-y-3">
              <VSelect
                v-model="individual.individual_id"
                label="Please select an individual to add to the subgroup"
                :options="individualOptions"
                property="individual_id"
                :v$="v$"
              />
            </div>
          </form>
        </VSection>
      </template>
      <template #footer>
        <div class="flex items-center justify-between space-x-3">
          <VButton :click="addIndividualFormModal.close" size="lg">{{ capitalize(t('shared.close')) }}</VButton>
          <VButton :click="saveIndividual" class="w-full" size="lg" variant="primary">
            <span>{{ capitalize(t('shared.add individual')) }}</span>
          </VButton>
        </div>
      </template>
    </VModal>

    <VModal :modalStore="subgroupFormModal">
      <template #main>
        <VSection :label="capitalize(t('shared.add subgroup'))">
          <form @submit.prevent="saveSubgroup">
            <div class="mt-6 space-y-3">
              <VTextField v-model="subgroup.name" :label="capitalize(t('shared.name'))" property="name" :v$="vs$" />
              <div class="mb-5">
                <label for="" class="mb-1 block text-sm font-medium text-gray-700">
                  {{ capitalize(t('shared.admin', 0)) }}
                </label>
                <VDropdownMultiple
                  aligned="left"
                  class="z-auto"
                  :options="subgroupIndividualOptions"
                  property="investor_admin_ids"
                  v-model="subgroup.admin_ids"
                  :show-selected-list="true"
                >
                  <VButton size="xl" class="w-full">
                    <div class="flex items-center justify-between">
                      <div>Select multiple...</div>
                      <VIcon name="chevron_selector_vertical" />
                    </div>
                  </VButton>
                </VDropdownMultiple>
              </div>
            </div>
          </form>
        </VSection>
      </template>
      <template #footer>
        <div class="flex items-center justify-between space-x-3">
          <VButton :click="subgroupFormModal.close" size="lg">{{ capitalize(t('shared.close')) }}</VButton>
          <VButton :click="saveSubgroup" class="w-full" size="lg" variant="primary">
            <span>{{ capitalize(t('shared.confirm change', 0)) }}</span>
          </VButton>
        </div>
      </template>
    </VModal>
  </TheLayout>
</template>
