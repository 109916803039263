<script setup lang="ts">
import { useExtendedI18n } from '@/i18n'
import { capitalize, startCase } from 'lodash'
import { ref } from 'vue'
import { rails_url } from '@/modules/shared/utils/rails'
import { RouterLinkBack, VButton, VButtonInvisible, VIcon, VSection, TheUploader } from '@/modules/shared/components'
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import { useInvestingInvestorStore } from '../stores/investor-store'
import { useRouter } from 'vue-router'

const { t } = useExtendedI18n()
const router = useRouter()
const investingInvestorStore = useInvestingInvestorStore()

const csvFile = ref(null)
const submit = async () => {
  if (!csvFile.value) return
  await investingInvestorStore.importIndividuals(csvFile.value)
  router.push({ name: 'investing.individuals' })
}
</script>

<template>
  <TheLayout>
    <div class="-mt-8 mb-6 flex items-center justify-between">
      <div>
        <RouterLinkBack class="text-lg" :fallback="{ name: 'investing.individuals' }">
          <VButtonInvisible>← {{ capitalize(t('shared.back')) }}</VButtonInvisible>
        </RouterLinkBack>
      </div>
    </div>

    <VSection :label="capitalize(t('shared.import individual', 0))">
      <div class="">To import investors, upload a CSV file containing all your investors.</div>
      <div class="mb-12">
        Any CSV file with a minimum of a name and email fields will work and other fields may be ignored.
      </div>

      <div class="flex gap-20">
        <VSection class="w-1/2" :label="startCase(t('shared.template CSV file'))">
          <div class="mb-5">You may download this template CSV to reference or use before importing.</div>
          <div class="flex items-center gap-1">
            <VIcon name="csv-file" class="block h-5 w-5" />
            <a :href="`${rails_url()}/sample/template/investors-csv`" target="_blank" class="hyperlink">
              {{ startCase(t('shared.investor CSV template')) }}
            </a>
          </div>
        </VSection>
        <VSection class="w-1/2" :label="startCase(t('shared.upload CSV file'))">
          <TheUploader :accept="['.csv']" @chosen="(f) => (csvFile = f)" :multiple_files="false" />
          <div class="mt-10 flex items-center justify-center gap-3">
            <VButton variant="v-blue" size="lg" :click="submit"> {{ startCase(t('shared.import')) }} </VButton>
          </div>
        </VSection>
      </div>
    </VSection>
  </TheLayout>
</template>
