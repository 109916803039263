export const colors = [
  '#52b7b9',
  // '#49b2b9',
  // '#41acba',
  // '#3aa7ba',
  '#34a1b9',
  // '#319bb8',
  // '#2f95b7',
  // '#308fb5',
  '#3389b3',
  // '#3783b0',
  // '#307fb4',
  // '#2d7ab8',
  '#2f75ba',
  // '#3770bc',
  // '#4269bd',
  // '#4e62bc',
  '#5b5aba',
  // '#6951b7',
  '#7647b1',
]
