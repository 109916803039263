<script setup lang="ts">
import { useExtendedI18n } from '@/i18n'
import { VSkeletonBar, VIcon } from '@/modules/shared/components'
import { format } from '@/modules/shared/utils/v-table'
import { toNumber, validMoney } from '@/modules/shared/utils/money'
import { Stat } from './types'

withDefaults(
  defineProps<{
    rounded?: boolean | 'top' | 'bottom'
    skeleton?: boolean
    stats: Stat[]
  }>(),
  {
    rounded: true,
  },
)

const { n } = useExtendedI18n()

const totalSpan = (stats: Stat[]) => stats.reduce((acc, curr) => acc + (curr.colspan || 1), 0)

const isFirstCol = (index: number, stats: Stat[]) => totalSpan(stats.slice(0, index)) % 4 === 0

function isLastCol(index: number, stats: Stat[]) {
  let curStat = stats[index]
  let nextStat = stats[index + 1]

  // if current stat has a colspan of 2 and is at index 0 or 2, it's not rightmost
  if (curStat.colspan === 2 && (index % 4 === 0 || index % 4 === 2)) return false

  // if the next stat doesn't exist, current stat is rightmost
  if (!nextStat) return true

  // if the next stat has a colspan of 2 and the current index is 1, then it's rightmost
  if (nextStat.colspan === 2 && index % 4 === 1) return true

  // if index is 3 (the last position in a row), then it's rightmost
  return index % 4 === 3
}

const isFirstRow = (index: number, stats: Stat[]) => totalSpan(stats.slice(0, index + 1)) <= 4

const isLastRow = (index: number, stats: Stat[]) => totalSpan(stats.slice(index)) <= 4
</script>

<template>
  <div
    class="grid grid-cols-4 gap-px border border-gray-200 bg-gray-200 dark:border-[#161618] dark:bg-[#1B1B1F]"
    :class="{
      'rounded-lg': rounded === true,
      'rounded-t-lg': rounded === 'top',
      'rounded-b-lg': rounded === 'bottom',
    }"
  >
    <div
      v-for="(stat, index) in stats"
      :class="{
        'rounded-tl-[7px]':
          (rounded === true || rounded === 'top') && isFirstRow(index, stats) && isFirstCol(index, stats),
        'rounded-tr-[7px]':
          (rounded === true || rounded === 'top') && isFirstRow(index, stats) && isLastCol(index, stats),
        'rounded-bl-[7px]':
          (rounded === true || rounded === 'bottom') && isLastRow(index, stats) && isFirstCol(index, stats),
        'rounded-br-[7px]':
          (rounded === true || rounded === 'bottom') && isLastRow(index, stats) && isLastCol(index, stats),
        'col-span-2': stat.colspan === 2,
        'col-span-3': stat.colspan === 3,
        'col-span-4': stat.colspan === 4,
      }"
      class="bg-white px-4 py-3 dark:bg-[#161618]"
    >
      <div class="mb-1 flex items-center justify-between text-sm font-medium text-gray-700">
        <div class="whitespace-nowrap">{{ stat.label }}</div>
        <div v-if="stat.info" class="group relative hover:block">
          <div
            class="rounded-full border-none bg-white font-medium text-gray-700 shadow-sm ring-1 ring-gray-700/20 hover:shadow-md hover:ring-gray-700/30 active:shadow-sm"
          >
            <VIcon name="help" class="h-5 w-5 text-gray-700" />
          </div>
          <div class="absolute bottom-full left-1/2 hidden w-80 -translate-x-1/2 transform pb-2 group-hover:block">
            <!-- <div class="rounded-md bg-white px-3 py-1.5 text-sm text-gray-700 shadow-lg ring-[1px] ring-gray-700/20"> -->
            <div class="rounded-md bg-gray-900 px-3.5 py-2.5 text-sm text-gray-100 shadow-lg">
              {{ stat.info }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="skeleton" class="text-2xl font-medium tracking-tight">
        <VSkeletonBar class="inline-block" />
      </div>
      <h3 v-else class="text-xl font-medium tracking-tight sm:text-2xl" :class="[stat.color || 'text-gray-800']">
        <span
          v-if="stat.type === 'currency' && stat.value && validMoney(stat.value)"
          class="inline-flex items-end space-x-1"
        >
          <span v-if="toNumber(stat.value) < 0" class="inline-block text-[#d16b6b]">-</span>
          <span>{{ n(stat.value, 'currency')?.replace('-', '') }}</span>
        </span>

        <span v-else-if="stat.type === 'multiple'" class="inline-flex items-end space-x-1">
          <span>{{ format(stat.value, 'multiple') }}</span>
        </span>

        <span v-else-if="stat.type === 'percent'" class="inline-flex items-center space-x-1">
          <span
            v-if="stat.value && parseFloat((stat.value as number).toFixed(2)) >= 0"
            class="inline-block text-[#7abfb8]"
            >+</span
          >
          <span
            v-if="stat.value && parseFloat((stat.value as number).toFixed(2)) < 0"
            class="inline-block text-[#d16b6b]"
            >-</span
          >
          <span>
            {{
              (stat.value as number) < 0
                ? n(stat.value as number, 'percent').substring(1)
                : n(stat.value as number, 'percent')
            }}
          </span>
        </span>

        <span v-else class="inline-flex items-end space-x-1">{{ stat.value }}</span>
      </h3>
    </div>
  </div>
</template>
