<script setup lang="ts">
import { onBeforeMount, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useAuthStore } from '@/modules/auth/stores/auth-store'
import { VIcon, VLoading } from '@/modules/shared/components'
import { useFetch } from '@/modules/shared/composables/use-fetch'
import InputGroup from '../components/input-group.vue'
import FormError from '../components/form-error.vue'
import VButton from '../components/v-button.vue'
import AuthLayout from '../layouts/auth-layout.vue'

///////////////////////////////////////////////////////////////////////////////
// Types
///////////////////////////////////////////////////////////////////////////////

type LoginPayload = {
  email: string
  password: string
  remember_device: boolean
}

type LoginResponse = {
  access_token: string
  user: {
    email: string
    is_site_admin: boolean
    name: string
    two_factor_enabled_at: string
  }
}

type LoginResponseError = {
  reason: string
}

///////////////////////////////////////////////////////////////////////////////
// Login
///////////////////////////////////////////////////////////////////////////////

const authStore = useAuthStore()
const route = useRoute()
const router = useRouter()

const organizationName = route.query?.account || 'Venture360'
const payload = ref<LoginPayload>({
  email: null,
  password: null,
  remember_device: false,
})

const { data, error, execute, isFetching } = useFetch('/auth/login', { immediate: false })
  .post(payload.value)
  .json<{ data: LoginResponse | LoginResponseError }>()

const login = async () => {
  await execute()

  if (error.value) {
    error.value = (data.value.data as LoginResponseError).reason
    return
  }

  const { access_token, user } = data.value.data as LoginResponse
  authStore.access_token = access_token
  authStore.current_user = user
  router.push({ name: 'auth.verify' })
}

const isPasswordVisible = ref(false)

onBeforeMount(() => {
  if (route.query.slug) {
    authStore.slug = route.query.slug as string
  }
})
</script>

<template>
  <AuthLayout>
    <template v-slot:icon>
      <svg width="72" height="96" viewBox="0 0 72 96" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M63 39H9C5.68629 39 3 41.6863 3 45V87C3 90.3137 5.68629 93 9 93H63C66.3137 93 69 90.3137 69 87V45C69 41.6863 66.3137 39 63 39Z"
          fill="#111827"
          stroke="#111827"
          stroke-width="6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15 39V24C15 12.4 24.4 3 36 3C47.56 3 57 12.4 57 24V39"
          stroke="#111827"
          stroke-width="6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path d="M36 60V72" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </template>

    <div class="flex h-full w-[360px] flex-col justify-between">
      <header class="mt-24">
        <h1 class="text-3xl leading-9 text-gray-900">Login to {{ organizationName }}</h1>
        <p class="mt-5 text-sm leading-5 text-gray-700">
          Welcome back! Please enter the email and password you used during registration or received from an admin.
        </p>
      </header>
      <main class="animate mb-16 mt-24 flex-grow" :class="{ 'animate-shake': error !== null }">
        <InputGroup label="Email" placeholder="you@example.com" v-model="payload.email" @keydown.enter="login" />
        <div class="relative">
          <InputGroup
            label="Password"
            :type="isPasswordVisible ? 'text' : 'password'"
            placeholder="••••••••••••"
            v-model="payload.password"
            @keydown.enter="login"
          />
          <div
            @click="isPasswordVisible = !isPasswordVisible"
            class="absolute right-3 top-6 cursor-pointer p-1 text-gray-500 hover:text-gray-800"
          >
            <VIcon name="eye" v-show="isPasswordVisible" />
            <VIcon name="eye-off" v-show="!isPasswordVisible" />
          </div>
        </div>
        <FormError :error="error" v-show="error !== null" />
        <div class="mt-4 flex items-center justify-between">
          <label
            class="block inline-flex cursor-pointer items-center"
            @click="payload.remember_device = !payload.remember_device"
          >
            <div class="flex h-4 w-4 items-center justify-center rounded-sm bg-gray-200 text-gray-800">
              <svg
                v-show="payload.remember_device"
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M5 13l4 4L19 7" />
              </svg>
            </div>
            <span class="ml-2 text-xs font-semibold leading-4 text-gray-500 hover:text-gray-700"
              >Remember this device</span
            >
          </label>
          <router-link
            :to="{ name: 'request-password-reset' }"
            class="text-xs text-gray-500 underline hover:text-gray-700"
            >Forgot your password?</router-link
          >
        </div>
        <VButton @click="login" :disabled="isFetching">
          <span v-if="isFetching" class="p-0.5"><VLoading /></span>
          <span v-else>Login</span>
        </VButton>
      </main>
      <footer class="mb-12">
        <div class="text-center">
          <!-- <a href="#" class="underline text-xs text-gray-500">Or register for an account</a> -->
        </div>
      </footer>
    </div>
  </AuthLayout>
</template>
