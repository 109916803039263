<script setup lang="ts">
import { ref } from 'vue'
import { requestTwoFactorAuthenticationResetService } from '../api'
import AuthLayout from '../layouts/auth-layout.vue'
import InputGroup from '../components/input-group.vue'
import FormError from '../components/form-error.vue'
import VButton from '../components/v-button.vue'
import VSpinner from '../components/v-spinner.vue'
import { useRoute, useRouter } from 'vue-router'

const router = useRouter()

// state
const loading = ref(false)
const error = ref(null)
const errorAnimated = ref(false)

// form
const email = ref(null)
const submitted = ref(false)

// need to resquest a verification code on page load/

// submit form, handle states, and redirect
const request_two_factor_reset = async () => {
  // prevent multiple requests
  if (loading.value === true) {
    return
  }

  // start
  loading.value = true

  // contact api
  const payload = {
    email: email.value,
  }
  const res = await requestTwoFactorAuthenticationResetService(payload)
  const { status } = res

  // handle success state
  if (status == 'success') {
    submitted.value = true
  }

  // finally,
  loading.value = false
}
</script>

<template>
  <AuthLayout>
    <template v-slot:icon>
      <svg width="90" height="85" viewBox="0 0 90 85" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M70.5737 40.695L61.4537 31.575L69.9337 23.087L79.0137 32.167C81.3737 34.447 85.1737 34.367 87.4937 32.007C89.7337 29.647 89.7337 25.967 87.4937 23.647L78.3737 14.527L82.5977 10.295C84.9177 7.93503 84.9177 4.13503 82.5977 1.77503C80.2377 -0.584968 76.4377 -0.584968 74.0777 1.73503L31.0137 44.775V44.771C20.7737 39.251 8.05368 43.051 2.53368 53.251C-2.98632 63.451 0.813678 76.171 11.0137 81.691C21.2137 87.171 33.9337 83.371 39.4537 73.171C42.8137 66.931 42.8137 59.411 39.4137 53.171L52.7737 39.811L61.8537 48.931C64.1737 51.251 67.9737 51.251 70.3337 48.891C72.6537 46.531 72.6537 42.731 70.2937 40.371L70.5737 40.695Z"
          fill="#111827"
        />
        <path
          d="M21.1976 55.855C19.2085 55.855 17.3009 56.6452 15.8943 58.0517C14.4878 59.4582 13.6976 61.3659 13.6976 63.355C13.6976 65.3441 14.4878 67.2518 15.8943 68.6583C17.3009 70.0648 19.2085 70.855 21.1976 70.855C23.1868 70.855 25.0944 70.0648 26.5009 68.6583C27.9075 67.2518 28.6976 65.3441 28.6976 63.355C28.6976 61.3659 27.9075 59.4582 26.5009 58.0517C25.0944 56.6452 23.1868 55.855 21.1976 55.855Z"
          stroke="white"
          stroke-width="6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </template>

    <div v-if="submitted" class="flex h-full w-[360px] flex-col justify-between">
      <header class="mt-24">
        <h1 class="text-3xl leading-9 text-gray-900">
          Thanks, check your email for instructions to reset your two-factor authentication
        </h1>
        <p class="mt-5 text-sm leading-5 text-gray-700">
          Didn't get the email? Check your spam folder or
          <a @click="router.go(0)" class="underline hover:text-gray-700">resend</a>
        </p>
      </header>
    </div>

    <div v-else class="flex h-full w-[360px] flex-col justify-between">
      <header class="mt-24">
        <h1 class="text-3xl leading-9 text-gray-900">Reset your two-factor authentication</h1>
        <p class="mt-5 text-sm leading-5 text-gray-700">
          Enter the email address associated with your account and we'll send you a link to reset your two-factor
          authentication.
        </p>
      </header>
      <main
        class="animate mb-16 mt-24 flex-grow"
        :class="{ 'animate-shake': errorAnimated === false && error !== null }"
      >
        <InputGroup
          label="Email"
          placeholder="you@example.com"
          v-model="email"
          @keydown.enter="request_two_factor_reset"
        />
        <FormError :error="error" v-show="error !== null" />
        <VButton @click="request_two_factor_reset" :disabled="loading">
          <span v-if="loading" class="p-0.5"><VSpinner /></span>
          <span v-else>Continue</span>
        </VButton>
      </main>
      <footer class="mb-12">
        <div class="text-center">
          <router-link class="text-xs text-gray-500 underline" :to="{ name: 'login' }">Return to login</router-link>
        </div>
      </footer>
    </div>
  </AuthLayout>
</template>
