<script setup lang="ts">
import { computed } from 'vue'
import { startCase } from 'lodash'
import { VBadge, VButton } from '@/modules/shared/components'
import { is_pm_default } from '../utils/stripe'

const props = withDefaults(
  defineProps<{
    actions?: boolean
    billable: any
    default_payment_method_id?: string
    payment_method: any
    setDefaultPaymentMethod?: any
  }>(),
  {
    actions: true,
    billable: null,
    default_payment_method_id: '',
    payment_method: null,
    setDefaultPaymentMethod: () => {},
  },
)

const is_default = computed(() =>
  is_pm_default(props.payment_method, {
    billable: props.billable,
    default_payment_method_id: props.default_payment_method_id,
  }),
)
</script>

<template>
  <div class="mb-6 items-center md:flex md:space-x-2">
    <template v-if="payment_method.type === 'card'">
      <div>
        {{ startCase(payment_method.card.brand) }}
        <span class="tracking-tighter text-gray-500">&#x2022;&#x2022;&#x2022;&#x2022;</span>
        {{ payment_method.card.last4 }}
      </div>
      <div class="hidden text-gray-300 md:block">&#x2022;</div>
      <div>Expires {{ payment_method.card.exp_month }}/{{ payment_method.card.exp_year }}</div>
      <div class="pl-2" v-if="is_default">
        <VBadge color="sky">Default</VBadge>
      </div>
    </template>
    <template v-if="payment_method.type === 'us_bank_account'">
      <div>
        {{ startCase(payment_method.us_bank_account.bank_name) }}
        <span class="tracking-tighter text-gray-500">&#x2022;&#x2022;&#x2022;&#x2022;</span>
        {{ payment_method.us_bank_account.last4 }}
      </div>
      <div class="hidden text-gray-300 md:block">&#x2022;</div>
      <div>{{ startCase(payment_method.us_bank_account.account_type) }}</div>
    </template>
    <template v-if="actions">
      <div class="pl-2" v-if="!is_default">
        <VButton :click="() => setDefaultPaymentMethod(payment_method.id)" size="xs">Set as default</VButton>
      </div>
      <div class="pl-2">
        <RouterLink
          :to="{
            name:
              billable.type === 'group'
                ? 'billing-group-remove-payment-method'
                : 'billing-entity-remove-payment-method',
            params: { payment_method_id: payment_method.id },
          }"
        >
          <VButton size="xs">Remove&hellip;</VButton>
        </RouterLink>
      </div>
    </template>
  </div>
</template>
