import { Middleware } from '@/modules/shared/routes/types'
import { useAuthStore } from '../stores/auth-store'

///////////////////////////////////////////////////////////////////////////////
// Utils
///////////////////////////////////////////////////////////////////////////////

const getHostname = () => window.location.hostname

const extractSubdomain = (hostname: string): string | null => {
  const parts = hostname.split('.')

  if (parts.length > 2) {
    return parts.slice(0, -2).join('.')
  }

  return null
}

///////////////////////////////////////////////////////////////////////////////

export const handleLogout: Middleware = async (_to, _from, next) => {
  const hostname = getHostname()
  const subdomain = extractSubdomain(hostname)

  if (subdomain === 'auth') {
    await useAuthStore().logout()
  }

  useAuthStore().reset()

  next()
}

export const redirectToAuthSubdomain: Middleware = (_to, _from, next) => {
  const hostname = getHostname()
  const subdomain = extractSubdomain(hostname)

  if (subdomain === 'app') {
    window.location.href = window.location.href.replace('://app.', '://auth.')
    return
  }

  next()
}

// export const redirectToSessionIfAuthenticated: Middleware = (_to, _from, next) => {
//   const authStore = useAuthStore()

//   if (authStore.is_authenticated) {
//     // copied from /app/src/modules/auth/pages/verify.vue
//     const baseUrl = import.meta.env.VITE_VENTURE360_SESSION_URL
//     const slugParam = authStore?.slug ? `slug=${authStore.slug}` : ''
//     const accessTokenParam = `access_token=${authStore?.access_token}`
//     const queryParams = [accessTokenParam, slugParam].filter((param) => param).join('&')

//     window.location.href = `${baseUrl}/redirect?${queryParams}`
//     return
//   }

//   next()
// }

export const requireAuthentication: Middleware = (to, _from, next) => {
  const authStore = useAuthStore()

  if (to.name !== 'login' && !authStore.is_authenticated) {
    next({ name: 'login' })
    return
  }

  next()
}

export const resetAuthStore: Middleware = (_to, _from, next) => {
  useAuthStore().reset()
  next()
}
