interface ArrayItem {
  [key: string]: any
}

const createObjectFromArray = (array: ArrayItem[], key: string): { [key: string]: ArrayItem } => {
  return array.reduce((obj, item) => {
    if (typeof item !== 'object' || item === null || !(key in item)) {
      throw new Error(`Item does not have key ${key}: ${JSON.stringify(item)}`)
    }

    return {
      ...obj,
      [item[key]]: item,
    }
  }, {})
}

export { createObjectFromArray }
