import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useFetch } from '@/modules/shared/composables/use-fetch'
import { Money } from '@/modules/shared/utils/money'
import { useNotificationStore } from '@/modules/shared/stores/notification-store'

///////////////////////////////////////////////////////////////////////////////
// Types
///////////////////////////////////////////////////////////////////////////////

type ID = number | string

export type Outstanding = {
  documents_count: number
  invoices_count: number
}

///////////////////////////////////////////////////////////////////////////////
// Store
///////////////////////////////////////////////////////////////////////////////

export const useOutstandingStore = defineStore('dashboard/outstandingStore', () => {
  const route = useRoute()
  const notificationStore = useNotificationStore()
  const baseUrl = computed(() => `/${route.params.slug}/outstandings`)

  const outstanding = ref({
    documents_count: 0,
    invoices_count: 0,
  })

  const fetchOutstandings = async (cid: string = 'current') => {
    const { data, error } = await useFetch(`${baseUrl.value}?cid=${cid}`).get().json<{ data: Outstanding }>()

    if (error.value) {
      // TODO: handle error (e.g., display a message to the user)
      console.error(error.value)
      notificationStore.enqueue('error', 'Outstandings failed to load')
      return
      return
    }

    outstanding.value = data.value.data
  }

  return {
    outstanding,

    fetchOutstandings,
  }
})
