<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { capitalize } from 'lodash'
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useExtendedI18n } from '@/i18n'
import {
  RouterLinkBack,
  VButton,
  VButtonInvisible,
  VIcon,
  VModal,
  VSection,
  VTable,
  VTextField,
} from '@/modules/shared/components'
import { useModal } from '@/modules/shared/composables/use-modal'
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import { rails_url } from '@/modules/shared/utils/rails'
import VSelect from '../components/v-select.vue'
import { useCompanyStore } from '../stores/company-store'
import { useGuestStore } from '../stores/guest-store'

///////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////

const { t } = useExtendedI18n()
const route = useRoute()

const guestStore = useGuestStore()
const companyStore = useCompanyStore()

const companyOptions = computed(() => {
  return companyStore.companies.map((company) => ({
    label: company.name,
    value: company.id,
  }))
})

///////////////////////////////////////////////////////////////////////////////
// Add guest
///////////////////////////////////////////////////////////////////////////////

const guestFormModal = useModal()

const initialState = {
  id: null,
  company_id: null,
  email: '',
  name: '',
}

const rules = {
  company_id: { required },
  email: { required, email },
  name: { required },
}

const guest = ref({ ...initialState })
const v$ = useVuelidate(rules, guest, { $lazy: true })

const showGuestForm = () => {
  v$.value.$reset()
  guest.value = { ...initialState }

  guestFormModal.open()
}

const loading = ref(false)

const saveGuest = async () => {
  const valid = await v$.value.$validate()
  if (!valid) return

  loading.value = true

  await guestStore.addGuest(guest.value)

  v$.value.$reset()
  guest.value = { ...initialState }

  loading.value = false
  guestFormModal.close()
}

///////////////////////////////////////////////////////////////////////////////
// Remove guest
///////////////////////////////////////////////////////////////////////////////

const skeleton = ref(false)

const removeGuest = async (guest_id: number | string) => {
  if (!window.confirm('Are you sure?')) return
  await guestStore.removeGuest(guest_id)
}

///////////////////////////////////////////////////////////////////////////////
// Mounted
///////////////////////////////////////////////////////////////////////////////

onMounted(async () => {
  skeleton.value = true

  await guestStore.fetchGuests()
  await companyStore.fetchCompanies()

  skeleton.value = false
})
</script>

<template>
  <TheLayout>
    <div class="-mt-6 mb-6 flex items-center justify-between sm:-mt-8">
      <div>
        <RouterLinkBack :to="`${rails_url()}/investors`">
          <VButtonInvisible>← {{ capitalize(t('shared.back')) }}</VButtonInvisible>
        </RouterLinkBack>
      </div>
      <div>
        <VButton :click="showGuestForm" size="md" variant="v-blue">
          <div class="mr-1 flex items-center space-x-2">
            <div><VIcon name="plus" /></div>
            <div>Invite Guest</div>
          </div>
        </VButton>
      </div>
    </div>
    <VSection :label="capitalize(t('shared.guest', 0))">
      <VTable
        :columns="[
          {
            key: 'name',
            name: capitalize(t('shared.name')),
            type: 'string',
            align: 'left',
            is_visible: true,
          },
          {
            key: 'email',
            name: capitalize(t('shared.email')),
            type: 'string',
            align: 'left',
            is_visible: true,
          },
          {
            key: 'company',
            name: capitalize(t('shared.company')),
            type: 'string',
            align: 'left',
            is_visible: true,
          },
          {
            key: 'date_invited',
            name: capitalize(t('shared.date invited')),
            type: 'date',
            align: 'center',
            is_visible: true,
          },
          {
            key: 'invited_by',
            name: capitalize(t('shared.invited by')),
            type: 'string',
            align: 'left',
            is_visible: true,
          },
          {
            key: 'actions',
            name: '',
            type: 'actions',
            align: 'right',
            is_visible: true,
          },
        ]"
        :items="guestStore.guests"
        :name="`${route.params.slug}-guests`"
        :skeleton="skeleton"
        :slots="['actions', 'company', 'email']"
      >
        <template #company="{ item }">
          <a :href="`${rails_url()}/companies/${item.company_id}`" class="hyperlink">
            {{ item.company }}
          </a>
        </template>
        <template #email="{ item }">
          <a :href="`mailto:${item.email}`" class="hyperlink">
            {{ item.email }}
          </a>
        </template>
        <template #actions="{ item }">
          <div class="flex justify-end space-x-2">
            <VButton :click="() => removeGuest(item.id)" size="xs" variant="secondary">{{
              capitalize(t('shared.remove'))
            }}</VButton>
          </div>
        </template>
      </VTable>
      <VModal :modalStore="guestFormModal">
        <template #main>
          <VSection :label="capitalize(t('shared.invite guest'))">
            <div class="mt-6 space-y-3">
              <VTextField v-model="guest.name" :label="capitalize(t('shared.name'))" property="name" :v$="v$" />
              <VTextField v-model="guest.email" :label="capitalize(t('shared.email'))" property="email" :v$="v$" />
              <VSelect
                v-model="guest.company_id"
                :disabled="false"
                :label="capitalize(t('shared.company'))"
                :options="companyOptions"
                property="company_id"
                :v$="v$"
              />
            </div>
          </VSection>
        </template>
        <template #footer>
          <div class="flex items-center justify-between space-x-3">
            <VButton :click="guestFormModal.close" variant="secondary" size="lg">
              {{ capitalize(t('shared.close')) }}
            </VButton>
            <VButton :click="saveGuest" class="w-full" :loading="loading" size="lg" variant="primary">
              {{ capitalize(t('shared.invite guest')) }}
            </VButton>
          </div>
        </template>
      </VModal>
    </VSection>
  </TheLayout>
</template>
