import CallsPage from '../pages/calls-page.vue'
import DistributionsPage from '../pages/distributions-page.vue'
import EntitiesPage from '../pages/entities-page.vue'
import EntityOverviewPage from '../pages/entity-overview-page.vue'
import EntityPortfolioPage from '../pages/entity-portfolio-page.vue'
import GuestsPage from '../pages/guests-page.vue'
import IndividualAccountPage from '../pages/individual-account-page.vue'
import IndividualBeneficiariesPage from '../pages/individual-beneficiaries-page.vue'
import IndividualContactsPage from '../pages/individual-contacts-page.vue'
import IndividualInvoicesPage from '../pages/individual-invoices-page.vue'
import IndividualOverviewPage from '../pages/individual-overview-page.vue'
import IndividualTaxesPage from '../pages/individual-taxes-page.vue'
import IndividualsImportPage from '../pages/individuals-import-page.vue'
import IndividualsPage from '../pages/individuals-page.vue'
import IndividualEditPage from '../pages/individual-edit-page.vue'
import InvestorsPage from '../pages/investors-page.vue'
import OtherEntitiesPage from '../pages/other-entities-page.vue'
import OtherEntityDistributionsPage from '../pages/other-entity-distribution-page.vue'
import OtherEntityEditPage from '../pages/other-entity-edit-page.vue'
import OtherEntityInvestorsPage from '../pages/other-entity-investors-page.vue'
import OtherEntityInvoicesPage from '../pages/other-entity-invoices-page.vue'
import OtherEntityNewPage from '../pages/other-entity-new-page.vue'
import OtherEntityOverviewPage from '../pages/other-entity-overview-page.vue'
import OtherEntityPortfolioPage from '../pages/other-entity-portfolio-page.vue'
import InvdividualPortfolioPage from '../pages/individual-portfolio-page.vue'
import SubgroupsPage from '../pages/subgroups-page.vue'
import SubgroupOverviewPage from '../pages/subgroup-overview-page.vue'
import OwnershipTransfersPage from '../pages/ownership-transfers-page.vue'
import PortfolioPage from '../pages/portfolio-page.vue'

export default [
  {
    component: CallsPage,
    name: 'investing.calls',
    path: '/:slug/investing/:entity_type/:entity_id/calls',
  },
  {
    component: DistributionsPage,
    name: 'investing.distributions',
    path: '/:slug/investing/:entity_type/:entity_id/distributions',
  },
  {
    component: EntitiesPage,
    name: 'investing.entities',
    path: '/:slug/investing/:entity_type',
  },
  {
    component: EntityOverviewPage,
    name: 'investing.entity-overview',
    path: '/:slug/investing/:entity_type/:entity_id/overview',
  },
  {
    component: GuestsPage,
    name: 'investing.guests',
    path: '/:slug/investing/guests',
  },
  {
    component: IndividualsPage,
    name: 'investing.individuals',
    path: '/:slug/investing/individuals',
  },
  {
    component: IndividualBeneficiariesPage,
    name: 'investing.individual.beneficiaries',
    path: '/:slug/investing/individual/:individual_id/beneficiaries',
  },
  {
    component: IndividualContactsPage,
    name: 'investing.individual.contacts',
    path: '/:slug/investing/individual/:individual_id/contacts',
  },
  {
    component: IndividualInvoicesPage,
    name: 'investing.individual-invoices',
    path: '/:slug/investing/individual/:individual_id/invoices',
  },
  {
    component: IndividualOverviewPage,
    name: 'investing.individual-overview',
    path: '/:slug/investing/individual/:individual_id/overview',
  },
  {
    component: IndividualTaxesPage,
    name: 'investing.individual.taxes',
    path: '/:slug/investing/individual/:individual_id/taxes',
  },
  {
    component: IndividualAccountPage,
    name: 'investing.individual.account',
    path: '/:slug/investing/individual/:individual_id/account',
  },
  {
    component: IndividualEditPage,
    name: 'investing.individual.edit',
    path: '/:slug/investing/individual/:individual_id/edit',
  },
  {
    component: IndividualsImportPage,
    name: 'investing.individuals.import',
    path: '/:slug/investing/individuals/import',
  },
  {
    component: InvestorsPage,
    name: 'investing.investors',
    path: '/:slug/investing/:entity_type/:entity_id/investors',
  },
  {
    component: OtherEntitiesPage,
    name: 'investing.other-entities',
    path: '/:slug/investing/associated-entities',
  },
  {
    component: OtherEntityDistributionsPage,
    name: 'investing.other-entity-distributions',
    path: '/:slug/investing/associated-entity/:other_entity_id/distributions',
  },
  {
    component: OtherEntityEditPage,
    name: 'investing.other-entity.edit',
    path: '/:slug/investing/associated-entity/:other_entity_id/edit',
  },
  {
    component: OtherEntityInvoicesPage,
    name: 'investing.other-entity.invoices',
    path: '/:slug/investing/associated-entity/:other_entity_id/invoices',
  },
  {
    component: OtherEntityInvestorsPage,
    name: 'investing.other-entity.investors',
    path: '/:slug/investing/associated-entity/:other_entity_id/investors',
  },
  {
    component: OtherEntityNewPage,
    name: 'investing.other-entity.new',
    path: '/:slug/investing/associated-entity/new',
  },
  {
    component: OtherEntityOverviewPage,
    name: 'investing.other-entity.overview',
    path: '/:slug/investing/associated-entity/:other_entity_id/overview',
  },
  {
    component: OtherEntityPortfolioPage,
    name: 'investing.other-entity.portfolio',
    path: '/:slug/investing/associated-entity/:other_entity_id/portfolio',
  },
  {
    component: SubgroupsPage,
    name: 'investing.subgroups',
    path: '/:slug/investing/subgroups',
  },
  {
    component: SubgroupOverviewPage,
    name: 'investing.subgroup-overview',
    path: '/:slug/investing/subgroup/:subgroup_id/overview',
  },
  {
    component: OwnershipTransfersPage,
    name: 'investing.transfers',
    path: '/:slug/investing/:entity_type/:entity_id/transfers',
  },
  {
    component: PortfolioPage,
    name: 'investing.portfolio',
    path: '/:slug/portfolio',
  },
  {
    component: InvdividualPortfolioPage,
    name: 'investing.individual.portfolio',
    path: '/:slug/investing/individual/:individual_id/portfolio',
  },
  {
    component: EntityPortfolioPage,
    name: 'investing.entity.portfolio',
    path: '/:slug/investing/:entity_type/:entity_id/portfolio',
  },
  {
    path: '/:slug/investing/:entity_type/:entity_id',
    redirect: (to) => {
      return { name: 'investing.entity-overview', params: to.params }
    },
  },
  {
    path: '/:slug/investing/individual/:individual_id',
    redirect: (to) => {
      return { name: 'investing.individual-overview', params: to.params }
    },
  },
  {
    path: '/:slug/investing/associated-entity/:other_entity_id',
    redirect: (to) => {
      return { name: 'investing.other-entity.overview', params: to.params }
    },
  },
]
