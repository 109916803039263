<script setup lang="ts">
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    tabs: string[]
    current: number
    size?: string
  }>(),
  {
    size: 'sm',
  },
)

const emit = defineEmits<{
  (event: 'update:current', index: number)
}>()

const selectTab = (index: number) => {
  emit('update:current', index)
}

const text_size = computed(() => `text-${props.size}`)
</script>

<template>
  <div class="border-b border-gray-200 dark:border-zinc-800">
    <nav class="relative -mb-px flex space-x-3 sm:space-x-6" aria-label="Tabs">
      <div
        v-for="(tab, index) in tabs"
        :key="tab"
        @click.prevent="selectTab(index)"
        class="sm:text-md relative flex cursor-pointer overflow-x-clip whitespace-nowrap border-b py-1.5 font-medium leading-7"
        :class="[
          index === current
            ? 'border-current font-semibold text-[#3b88af]'
            : 'border-transparent font-medium text-gray-700 hover:border-gray-300 dark:text-zinc-300',
          text_size,
        ]"
      >
        <div>{{ tab }}</div>
        <div
          v-if="index === current"
          class="pointer-events-none absolute -left-4 top-[41px] flex h-8 rotate-180 items-end overflow-hidden"
        >
          <div class="-mb-px flex h-[2px] w-24 rotate-180">
            <div
              class="w-full flex-none blur-sm [background-image:linear-gradient(-90deg,rgba(56,189,248,0)_0%,#0EA5E9_32.29%,rgba(236,72,153,0.3)_67.19%,rgba(236,72,153,0)_100%)]"
            ></div>
            <div
              class="-ml-[100%] w-full flex-none blur-[1px] [background-image:linear-gradient(90deg,rgba(56,189,248,0)_0%,#0EA5E9_32.29%,rgba(236,72,153,0.3)_67.19%,rgba(236,72,153,0)_100%)]"
            ></div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>
