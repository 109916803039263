<script setup lang="ts">
import { capitalize } from 'lodash'
import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useExtendedI18n } from '@/i18n'
import {
  RouterLinkBack,
  VButton,
  VButtonInvisible,
  VIcon,
  VSection,
  VTable,
  ActionItem,
  ActionsGroup,
  ActionsMenu,
  IconLinkExternal,
  IconTrash,
} from '@/modules/shared/components'
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import { rails_url } from '@/modules/shared/utils/rails'
import { useOtherEntityStore } from '../stores/other-entity-store'
import { unflattenKey } from '@/modules/shared/utils/v-table'
import { useAuthStore } from '@/modules/auth/stores/auth-store'
import IndividualsNav from '../components/individuals-nav.vue'

///////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////

const { t } = useExtendedI18n()
const route = useRoute()
const router = useRouter()

const authStore = useAuthStore()
const otherEntityStore = useOtherEntityStore()

///////////////////////////////////////////////////////////////////////////////
// Mounted
///////////////////////////////////////////////////////////////////////////////

const skeleton = ref(true)

const removeOtherEntity = async (item) => {
  if (!window.confirm('Are you sure?')) return
  await otherEntityStore.removeOtherEntity(item.id)
  await otherEntityStore.fetchOtherEntities()
}

onMounted(async () => {
  await otherEntityStore.fetchOtherEntities()
  skeleton.value = false
})
</script>

<template>
  <TheLayout>
    <div class="-mt-6 mb-6 flex items-center justify-between sm:-mt-8">
      <div>
        <RouterLinkBack :to="{ name: 'dashboard' }">
          <VButtonInvisible>← {{ capitalize(t('shared.back')) }}</VButtonInvisible>
        </RouterLinkBack>
      </div>
    </div>

    <VSection>
      <IndividualsNav />
      <div class="mb-3 flex items-center justify-end">
        <div v-if="authStore.is_site_or_group_admin" class="flex items-center space-x-1.5">
          <RouterLinkBack :to="{ name: 'investing.other-entity.new' }" v-if="authStore.is_site_or_group_admin">
            <VButton size="md" variant="v-blue">
              <div class="mr-1 flex items-center space-x-2">
                <div><VIcon name="plus" /></div>
                <div>Add Associated Entity</div>
              </div>
            </VButton>
          </RouterLinkBack>
        </div>
      </div>
      <VTable
        :columns="[
          {
            key: 'name',
            name: capitalize(t('shared.name')),
            type: 'string',
            align: 'left',
            fixed: true,
            is_visible: true,
          },
          {
            key: 'admins',
            name: capitalize(t('shared.admin', 0)),
            type: 'string',
            align: 'left',
            is_visible: true,
          },
          {
            key: 'number_of_investors',
            name: capitalize(t('shared.number of investor', 0)),
            type: 'number',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'type',
            name: capitalize(t('shared.type')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'street1',
            name: capitalize(t('shared.street')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'street2',
            name: capitalize('Street (continued)'),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'city',
            name: capitalize(t('shared.city')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'state',
            name: capitalize(t('shared.state')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'zip',
            name: capitalize(t('shared.zip')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'tax_id',
            name: capitalize(t('shared.tax id')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'disbursement_method',
            name: capitalize(t('shared.disbursement method')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'disbursement_wire_bank_name',
            name: capitalize(t('shared.bank name')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'disbursement_wire_bank_address',
            name: capitalize(t('shared.bank address')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'disbursement_wire_bank_routing_number',
            name: capitalize(t('shared.bank routing number')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'disbursement_wire_bank_account_number',
            name: capitalize(t('shared.bank account number')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'disbursement_wire_bank_swift_code',
            name: capitalize(t('shared.bank swift code')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'disbursement_wire_account_name',
            name: capitalize(t('shared.bank account name')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'disbursement_wire_for_further_credit_to',
            name: capitalize(t('shared.for further credit to')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'disbursement_check_receiver_name',
            name: capitalize(t('shared.check payable to')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'disbursement_other_details',
            name: capitalize(t('shared.other detail', 0)),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'actions',
            name: '',
            type: 'actions',
            align: 'right',
            is_visible: true,
          },
        ]"
        :items="otherEntityStore.other_entities"
        :name="`${route.params.slug}-other-entities`"
        :skeleton="skeleton"
        :slots="['actions', 'name', 'admins']"
        no_data_message="Associated Entities are any type of entity an investor would use to invest such as IRAs, Trusts, LLCs, etc. Associated Entities are also set-up for institutional investors. Administrators can be added to act on behalf of these. When you create an Associated Entity, you can also add investors as owners to be able to view the portfolio on their personal page."
      >
        <template #name="{ item }">
          <RouterLink
            class="cursor-pointer whitespace-break-spaces font-medium text-[#51889B] underline decoration-[#51889B]/50"
            :to="{
              name: 'investing.other-entity.overview',
              params: {
                other_entity_id: item.id,
                slug: route.params.slug,
              },
            }"
          >
            {{ item.name }}
          </RouterLink>
        </template>
        <template #admins="{ item }">
          <span v-for="(admin, index) in unflattenKey(item, 'admins')" :key="admin.id">
            <RouterLink
              v-if="authStore.is_site_or_group_admin"
              class="text-[#3b88af] underline decoration-[#3b88af]/50 hover:text-gray-900 hover:decoration-gray-900/50"
              :to="{ name: 'investing.individual-overview', params: { individual_id: admin.id } }"
            >
              {{ admin.name }}
            </RouterLink>
            <span class="text-gray-900" v-else>{{ admin.name }}</span>
            <span v-if="index !== unflattenKey(item, 'admins').length - 1">, </span>
          </span>
        </template>
        <template #actions="{ item }">
          <div class="flex items-center justify-end space-x-1.5">
            <ActionsMenu v-if="authStore.is_site_or_group_admin">
              <ActionsGroup :label="capitalize(t('shared.select action'))">
                <ActionItem
                  @click="
                    () => router.push({ name: 'investing.other-entity.edit', params: { other_entity_id: item.id } })
                  "
                  :icon="IconLinkExternal"
                  :text="capitalize(t('shared.edit'))"
                  tag="a"
                />
                <ActionItem
                  :icon="IconTrash"
                  :text="capitalize(t('shared.delete'))"
                  @click="() => removeOtherEntity(item)"
                />
              </ActionsGroup>
            </ActionsMenu>
          </div>
        </template>
      </VTable>
    </VSection>
  </TheLayout>
</template>
