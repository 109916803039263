<script setup lang="ts">
import { useAuthStore } from '@/modules/auth/stores/auth-store'
import { VSection, VTable, VTabListSimple } from '@/modules/shared/components'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useCan } from '@/modules/auth/composables/use-can'
import { isEntityAdmin } from '../utils/entity'
import { capitalize, isEmpty } from 'lodash'
import { unflattenKey } from '@/modules/shared/utils/v-table'
import { useRoute } from 'vue-router'

const props = defineProps<{
  cid: string
  filterKeys?: string[]
  is_entity_link_disabled?: boolean
  funds: any[]
  other_entities: any[]
  spvs: any[]
  skeleton: boolean
  selected_tab?: string
}>()

const { t } = useI18n()
const { can } = useCan()
const route = useRoute()
const authStore = useAuthStore()

const cid = computed(() => props.cid)
const entityTabs = ['Funds', 'SPVs', 'Associated Entities']
const entityTabIndex = ref(0)

const fundCount = computed(() => props.funds.length)
const spvCount = computed(() => props.spvs.length)
const otherEntityCount = computed(() => props.other_entities.length)
const entityTabsAvailable = computed(() => {
  return entityTabs.filter((tab) => {
    if (tab === 'Funds') return fundCount.value > 0
    if (tab === 'SPVs') return spvCount.value > 0
    if (tab === 'Associated Entities') return otherEntityCount.value > 0
    return false
  })
})
const entityTabSelected = computed(() => entityTabsAvailable.value[entityTabIndex.value]?.toLowerCase())

const entities = computed(() => {
  if (entityTabSelected.value === 'associated entities') return []

  return props[entityTabSelected.value] || []
})

const is_loaded = computed(() => !props.skeleton)
const selected_tab = computed(() => props.selected_tab)
watch([is_loaded, selected_tab], ([s, st]) => {
  if (s && st) {
    const index = entityTabsAvailable.value.findIndex((tab) => tab.toLowerCase() === st?.toLowerCase())
    entityTabIndex.value = index >= 0 ? index : 0
  }
})
</script>

<template>
  <VSection v-if="entityTabsAvailable.length > 0" id="all-entities-table">
    <VTabListSimple
      @update:current="(index: number) => (entityTabIndex = index)"
      :current="entityTabIndex"
      :tabs="entityTabsAvailable"
    />
    <template v-if="entityTabSelected !== 'associated entities'">
      <VTable
        :columns="[
          {
            key: 'name',
            name: capitalize(t('shared.name')),
            type: 'string',
            align: 'left',
            is_visible: true,
            fixed: true,
          },
          {
            key: 'committed',
            name: capitalize(t('shared.committed')),
            type: 'currency',
            align: 'right',
            is_visible: true,
            // aggregate: 'sum',
          },
          {
            key: 'called',
            name: capitalize(t('shared.called')),
            type: 'currency',
            align: 'right',
            is_visible: true,
            // aggregate: 'sum',
          },
          {
            key: 'committed_remaining',
            name: capitalize(t('shared.commitment remaining')),
            type: 'currency',
            align: 'right',
            is_visible: true,
            // aggregate: 'sum',
          },
          {
            key: 'distributed',
            name: capitalize(t('shared.distributed')),
            type: 'currency',
            align: 'right',
            is_visible: true,
            // aggregate: 'sum',
          },
          {
            key: 'management_fee',
            name: capitalize(t('shared.management fee', 0)),
            type: 'currency',
            align: 'right',
            is_visible: false,
            // aggregate: 'sum',
          },
          {
            key: 'other_fees',
            name: capitalize(t('shared.other fee', 0)),
            type: 'currency',
            align: 'right',
            is_visible: false,
            // aggregate: 'sum',
          },
          {
            key: 'carried_interest',
            name: capitalize(t('shared.carried interest')),
            type: 'currency',
            align: 'right',
            is_visible: false,
            // aggregate: 'sum',
          },
          {
            key: 'legal_name',
            name: capitalize(t('shared.legal name')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'date_established',
            name: capitalize(t('shared.date established')),
            type: 'date',
            align: 'right',
            is_visible: true,
          },
          {
            key: 'group.name',
            name: capitalize(t('shared.group')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'group.subdomain',
            name: capitalize(t('shared.subdomain')),
            type: 'string',
            align: 'left',
            is_accessible: can('admin', cid),
            is_visible: false,
          },
          {
            key: 'type',
            name: capitalize(t('shared.legal structure')),
            type: 'string',
            align: 'left',
            is_visible: true,
          },
          {
            key: 'tax_id',
            name: capitalize(t('shared.EIN')),
            type: 'string',
            align: 'left',
            is_accessible: can('admin', cid),
            is_visible: true,
          },
          {
            key: 'document_status',
            name: capitalize(t('shared.document status')),
            type: 'string',
            align: 'left',
            is_accessible: can('admin', cid),
            is_visible: true,
          },
          {
            key: 'selected_subscriptions',
            csv_key: 'selected_services',
            name: capitalize(t('shared.subscription')),
            type: 'string',
            align: 'left',
            is_accessible: can('admin', cid),
            is_visible: true,
          },
          {
            key: 'managers',
            name: capitalize(t('shared.manager', 0)),
            type: 'string',
            align: 'left',
            is_visible: true,
          },
          {
            key: 'address.line1',
            name: capitalize(t('shared.line 1')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'address.line2',
            name: capitalize(t('shared.line 2')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'address.city',
            name: capitalize(t('shared.city')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'address.state',
            name: capitalize(t('shared.state')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'address.zip',
            name: capitalize(t('shared.postal code')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'address.country',
            name: capitalize(t('shared.country')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
        ]"
        :items="entities"
        :name="`individuals-overview-${route.params.slug}-${cid}`"
        :skeleton="skeleton"
        :slots="['name', 'managers', 'selected_subscriptions']"
      >
        <template #name="{ item }">
          <div>
            <div class="mb-1 sm:w-[400px]">
              <RouterLink
                class="cursor-pointer whitespace-break-spaces font-medium text-[#51889B] underline decoration-[#51889B]/50"
                :to="{
                  name: 'investing.entity-overview',
                  params: { entity_id: item.id, entity_type: item.entity_type },
                }"
                v-if="!is_entity_link_disabled"
              >
                {{ item.name }}
                {{ isEntityAdmin(item, cid) ? '(Admin)' : '' }}
              </RouterLink>
              <span class="whitespace-break-spaces font-medium" v-else>
                {{ item.name }}
                {{ isEntityAdmin(item, cid) ? '(Admin)' : '' }}
              </span>
              <span class="ml-2 text-xs text-gray-300" v-if="item.entity_status">•</span>
              <span class="ml-2 text-sm" v-if="item.entity_status">
                <VBadge class="w-fit" style="display: unset">In process</VBadge>
              </span>
            </div>
            <div class="flex flex-wrap items-center justify-start space-x-2">
              <div class="whitespace-normal text-sm text-gray-600" v-if="!isEmpty(item.legal_name)">
                {{ item.legal_name }}
              </div>
            </div>
          </div>
        </template>
        <template #managers="{ item }">
          <span v-for="(manager, index) in unflattenKey(item, 'managers')" :key="manager.id">
            <RouterLink
              v-if="can('admin', cid)"
              class="text-[#3b88af] underline decoration-[#3b88af]/50 hover:text-gray-900 hover:decoration-gray-900/50"
              :to="{ name: 'investing.individual-overview', params: { individual_id: manager.id } }"
            >
              {{ manager.name }}
            </RouterLink>
            <span class="text-gray-900" v-else>{{ manager.name }}</span>
            <span v-if="index !== unflattenKey(item, 'managers').length - 1">, </span>
          </span>
        </template>
        <template #selected_subscriptions="{ item }">
          <div class="flex flex-col px-3 text-left text-sm text-gray-600">
            <a
              v-for="subscription in unflattenKey(item, 'selected_subscriptions')"
              class="mb-1 inline-block w-fit whitespace-nowrap rounded bg-white px-1.5 py-0.5 text-xs font-medium text-gray-600 ring-1 ring-gray-300"
              :href="`/${item['group.subdomain']}/billing/${item.entity_type}/${item.id}`"
            >
              <span class="pr-1.5">{{ subscription.name }}</span>
              <span class="border-l border-gray-300 pl-1.5">{{ subscription.status }}</span>
            </a>
          </div>
        </template>
      </VTable>
    </template>
    <div class="mt-6" v-else>
      <VTable
        :columns="[
          {
            key: 'name',
            name: capitalize(t('shared.name')),
            type: 'string',
            align: 'left',
            is_visible: true,
            fixed: true,
          },
          {
            key: 'admins',
            name: capitalize(t('shared.admin', 0)),
            type: 'string',
            align: 'left',
            is_visible: true,
          },
        ]"
        :items="other_entities"
        :name="`individuals-overview-investment-vehicles-${route.params.slug}-${cid}`"
        :skeleton="skeleton"
        :slots="['admins', 'name']"
      >
        <template #admins="{ item }">
          <span v-for="(admin, index) in unflattenKey(item, 'admins')" :key="admin.id">
            <RouterLink
              v-if="authStore.is_site_or_group_admin"
              class="text-[#3b88af] underline decoration-[#3b88af]/50 hover:text-gray-900 hover:decoration-gray-900/50"
              :to="{ name: 'investing.individual-overview', params: { individual_id: admin.id } }"
            >
              {{ admin.name }}
            </RouterLink>
            <span class="text-gray-900" v-else>{{ admin.name }}</span>
            <span v-if="index !== unflattenKey(item, 'admins').length - 1">, </span>
          </span>
        </template>
        <template #name="{ item }">
          <RouterLink
            v-if="!is_entity_link_disabled"
            class="cursor-pointer whitespace-break-spaces font-medium text-[#51889B] underline decoration-[#51889B]/50"
            :to="{ name: 'investing.other-entity.overview', params: { other_entity_id: item.id } }"
          >
            {{ item.name }}
          </RouterLink>
          <span class="text-gray-900" v-else>{{ item.name }}</span>
        </template>
      </VTable>
    </div>
  </VSection>
</template>
