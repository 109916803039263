import axios from 'axios'
import { useAuthStore } from './stores/auth-store'

// config
axios.defaults.baseURL = `${import.meta.env.VITE_VENTURE360_API_URL}/auth/`

enum Status {
  success = 'success',
  fail = 'fail',
  error = 'error',
}

interface Response {
  code?: number
  status: Status
  data?: any
  message?: string
}

interface LoginPayload {
  email: string
  password: string
  remember_device?: boolean
}

interface LoginResponse extends Response {
  data?: {
    // success
    access_token?: string
    user?: {
      id: number
      name: string
      email: string
      two_factor_number: string
      two_factor_number_verified_at: string
      two_factor_enabled_at: string
      two_factor_skipped_at: string
      two_factor_last_verified_at: string
    }
    redirect_url?: string

    // fail
    reason?: string
  }
}

interface VerifyPayload {
  verification_code: string
}

interface VerifyResponse extends Response {}

const post = async (url: string, payload?: any) => {
  const authStore = useAuthStore()
  const headers = {
    Authorization: `Bearer ${authStore.access_token}`,
  }

  try {
    const { data } = await axios.post(url, payload || {}, { headers })
    return data
  } catch (err) {
    const { data } = err.response
    return data
  }
}

interface EnablePayload {
  verification_code: string
}

interface EnableResponse extends Response {
  two_factor_phone_number: string
}

interface PasswordResetPayload {
  email: string
}

interface ResetPasswordPayload {
  reset_token: string
  new_password: string
}

interface VerificationPayload {
  channel: string
}

// prettier-ignore
export const loginService = async (payload: LoginPayload): Promise<LoginResponse> => post('/login', payload)
// prettier-ignore
export const verifyService = async (payload: VerifyPayload): Promise<VerifyResponse> => post('/verify', payload)
// prettier-ignore
export const enableTwoFactorService = async (payload: EnablePayload): Promise<EnableResponse> => post('/enable-two-factor-authentication', payload)
// prettier-ignore
export const skipEnableTwoFactorService = async (): Promise<Response> => post('/skip-enable-two-factor-authentication')
// prettier-ignore
export const requestVerificationService = async (payload: VerificationPayload): Promise<Response> => post('/send-verification-code', payload)
// prettier-ignore
export const requestPasswordResetService = async (payload: PasswordResetPayload): Promise<Response> => post('/request-password-reset', payload)
// prettier-ignore
export const resetPasswordService = async (payload: ResetPasswordPayload): Promise<Response> => post('/reset-password', payload)
// prettier-ignore
export const requestTwoFactorAuthenticationResetService = async (payload: PasswordResetPayload): Promise<Response> => post('/request-two-factor-authentication-reset', payload)
// prettier-ignore
export const resetTwoFactorAuthenticationService = async (payload: ResetPasswordPayload): Promise<Response> => post('/reset-two-factor-authentication', payload)
