<template>
  <fieldset>
    <label :for="id" class="block text-sm font-medium text-gray-700">
      <span>{{ label }}</span>
      <span v-if="required">*</span>
    </label>
    <div :class="{ 'mt-1': !inline }">
      <textarea
        v-model="value"
        :class="[
          'block min-h-[8rem] w-full',
          {
            'border-gray-300 shadow-sm focus:border-sky-300 focus:ring focus:ring-sky-200 focus:ring-opacity-50':
              !error,
            ' border-red-300 focus:border-red-300 focus:ring-red-200': error,
            'mt-1 rounded-md border': !inline,
            'border-none': inline,
            'bg-gray-50 text-gray-500': disabled,
          },
        ]"
        :disabled="disabled"
        :id="id"
        :name="name"
        :placeholder="placeholder"
      />
    </div>
    <template v-if="error && !inline">
      <p class="mt-2 text-sm text-red-500">{{ error }}</p>
    </template>
    <template v-if="description">
      <p class="mt-2 text-sm text-gray-500" :id="`${id}-description`">{{ description }}</p>
    </template>
  </fieldset>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: {
    type: [String, Number],
    required: true,
  },
  description: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  error: {
    type: String,
    default: '',
  },
  id: {
    type: String,
    required: false,
  },
  inline: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: false,
  },
  property: {
    type: String,
    default: undefined,
  },
  placeholder: {
    type: String,
    default: '',
  },
  required: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: 'text',
  },
  v$: {
    type: Object,
    default: {},
  },
})

// NOTE because data-attributes are case sensitive, using camelCase will cause issues
//      an alternative approach is to use a computed property with a getter and setter
// https://vuejs.org/guide/components/events.html#usage-with-v-model
const value = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
})

const error = computed(() => props.error || props.v$[props.property || props.name]?.$errors[0]?.$message)
</script>
