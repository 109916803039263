// prettier-ignore
const createQueryString = (query: Record<string, any>) => {
  return new URLSearchParams(query)
    .toString()
    .replace(/%2C/g, ',')
    .replace(/%3A/g, ':')
    .toLowerCase()
  }

export { createQueryString }
