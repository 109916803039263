<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M12.0007 9.22522C10.1267 9.22522 8.60205 10.7498 8.60205 12.6239C8.60205 14.4979 10.1267 16.0225 12.0007 16.0225C13.8747 16.0225 15.3993 14.4979 15.3993 12.6239C15.3993 10.7479 13.8747 9.22522 12.0007 9.22522ZM12.0007 15.4522C10.4397 15.4522 9.17047 14.183 9.17047 12.622C9.17047 11.061 10.4397 9.79179 12.0007 9.79179C13.5617 9.79179 14.8309 11.061 14.8309 12.622C14.8309 14.183 13.5617 15.4522 12.0007 15.4522Z"
      fill="white"
      stroke="white"
      stroke-width="0.4"
    />
    <path
      d="M18.2278 6.98455H15.3034L14.497 5.11237C14.4528 5.00871 14.3491 4.94147 14.2358 4.94147H9.76192C9.64861 4.94147 9.54495 5.0087 9.50075 5.11425L8.70006 6.98643L5.7738 6.98656C4.97312 6.98656 4.32031 7.63936 4.32031 8.44005V16.808C4.32031 17.6087 4.97312 18.2615 5.7738 18.2615H18.2295C19.0302 18.2615 19.683 17.6087 19.683 16.808V8.43834C19.6811 7.63755 19.0283 6.98471 18.2277 6.98471L18.2278 6.98455ZM19.113 16.8059C19.113 17.2936 18.7154 17.6911 18.2278 17.6911H5.77378C5.28611 17.6911 4.88861 17.2936 4.88861 16.8059V8.43801C4.88861 7.95033 5.28614 7.55284 5.77378 7.55284H8.89023C9.00354 7.55284 9.10721 7.4856 9.15141 7.38006L9.9521 5.50788H14.0515L14.858 7.38006C14.9022 7.48372 15.0059 7.55096 15.1192 7.55096H18.2297C18.7174 7.55096 19.1149 7.94849 19.1149 8.43613V16.8058L19.113 16.8059Z"
      fill="white"
      stroke="white"
      stroke-width="0.4"
    />
  </svg>
</template>
