import SigningBuildTemplateView from '../views/build-template.vue'
import SigningEditTemplateView from '../views/edit-template.vue'
import SigningDuplicateTemplateView from '../views/duplicate-template.vue'

export default [
  {
    path: '/:slug/signing/:templateable_type/:templateable_id/build-template',
    name: 'signing-entity-build-template',
    component: SigningBuildTemplateView,
  },
  {
    path: '/:slug/signing/:templateable_type/:templateable_id/template/:template_id/modify',
    name: 'signing-entity-edit-template',
    component: SigningEditTemplateView,
  },
  {
    path: '/:slug/signing/:templateable_type/:templateable_id/template/:template_id/duplicate',
    name: 'signing-entity-duplicate-template',
    component: SigningDuplicateTemplateView,
  },
  {
    path: '/:slug/signing/group/build-template',
    name: 'signing-group-build-template',
    component: SigningBuildTemplateView,
  },
]
