<script setup lang="ts">
import { computed } from 'vue'
import hash from 'hash.js'
import { colors } from '../utils/chart'

///////////////////////////////////////////////////////////////////////////////

function textToRandomNumber(text: string, min: number, max: number): number {
  const hashHex = hash.sha256().update(text).digest('hex')
  const integerHash = BigInt('0x' + hashHex)
  const rangeSize = BigInt(max - min + 1)
  const randomNumber = min + Number(integerHash % rangeSize)

  return randomNumber
}

///////////////////////////////////////////////////////////////////////////////

const props = withDefaults(
  defineProps<{
    image: string | null
    initials: string
    size?: string
  }>(),
  {
    size: 'sm',
    image: null,
    initials: '',
  },
)

const base = '!no-underline shadow-sm hover:shadow-sm hover:scale-105 ring-1 ring-inset ring-black/5'

// prettier-ignore
const sizes = {
  'sm': 'h-6 w-6 text-[9px] font-bold tracking-wide text-white',
  'lg': 'h-28 w-28 text-4xl font-medium tracking-wide text-white'
}

const rand = computed(() => {
  const min = 0
  const max = colors.length - 1
  return textToRandomNumber(props.initials, min, max)
})

const randomColor = computed(() => colors[rand.value])
</script>

<template>
  <div
    class="flex items-center justify-center overflow-hidden rounded-full"
    :class="[base, sizes[size]]"
    :style="{ background: image ? 'transparent' : randomColor }"
  >
    <img v-if="image" :src="image" :alt="initials" class="h-full w-full object-cover" />
    <div v-else class="white">{{ initials }}</div>
  </div>
</template>
