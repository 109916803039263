interface StripeInvoice {
  stripe_cache?: {
    [key: string]: any
  }
  total?: number
}

const invoice_amount_remaining = (invoices: StripeInvoice[]): number => {
  return invoices.reduce((total, invoice) => total + invoice.stripe_cache.amount_remaining, 0)
}

const invoice_total = (invoices: StripeInvoice[]): number => {
  return invoices.reduce((total, invoice) => total + invoice.total, 0)
}

const invoice_subtotal = (invoices: StripeInvoice[]): number => {
  return invoices.reduce((total, invoice) => total + invoice.stripe_cache.subtotal, 0)
}

const subscription_upcoming_invoice_subtotal = (subscriptions: any): number => {
  return subscriptions.reduce((total, subscription) => total + subscription.stripe_upcoming_invoice_cache.subtotal, 0)
}

const subscriptions_has_any_discount = (subscriptions: any) => {
  return subscriptions.some(sub_has_discount)
}

const is_invoice_due = (invoice: any): boolean => {
  if (!is_invoice_open(invoice)) return false
  // https://stripe.com/docs/api/invoices/object#invoice_object-due_date
  // The date on which payment for this invoice is due. This value will be null for invoices where collection_method=charge_automatically.

  if (invoice.stripe_cache.due_date === null) return true

  const due_date = new Date(invoice.stripe_cache.due_date * 1000)

  return due_date <= new Date()
}

const is_invoice_open = (invoice: any): boolean => invoice.stripe_cache.status === 'open'

const is_pm_default = (
  payment_method: any,
  context: { default_payment_method_id: string; billable: { type: string; id: string } },
): boolean => {
  const { default_payment_method_id, billable } = context

  // Check the metadata for the billable
  if (payment_method.metadata?.billables !== undefined && billable && billable.type !== 'group') {
    return payment_method.metadata.billables.split(',').includes(`${billable.type}:${billable.id}`)
  }

  // Default to the invoice settings
  return payment_method?.stripe_payment_method_id === default_payment_method_id
}

const is_sub = (id: string) => /^sub_(?!sched_).*$/.test(id)

const is_sub_canceled = (subscription: any) => subscription.status === 'canceled'

const is_sub_ended = (subscription: any) => {
  if (subscription.status === 'incomplete_expired') return true
  if (subscription.cancel_at === null) return false

  return subscription.cancel_at <= Math.floor(Date.now() / 1000)
}

const is_sub_ending = (subscription: any) => {
  if (subscription.cancel_at === null || subscription.status === 'canceled') return false

  return subscription.cancel_at > Math.floor(Date.now() / 1000)
}

const is_sub_invoice = (subscription: any, invoice: any): boolean => {
  return invoice.stripe_cache.subscription === subscription.id || invoice.stripe_subscription_id === subscription.id
}

const is_sub_renewing = (subscription: any) => {
  if (subscription.status !== 'active') return false

  const now = Math.floor(Date.now() / 1000)
  return subscription.cancel_at_period_end === false && subscription.current_period_end > now
}

const is_sub_sched = (id: string) => /^sub_sched_.*$/.test(id)

const is_sub_trialing = (subscription: any) => subscription.status === 'trialing'

const sub_has_discount = (subscription: any) => {
  if (subscription.stripe_cache.discount === null) return false
  if (subscription.stripe_cache.discount.coupon.applies_to === undefined) return true

  const subscription_product_id = subscription.items[0].price.product
  if (
    !subscription.stripe_cache.discount.coupon.applies_to.products.some(
      (product_id) => product_id === subscription_product_id,
    )
  )
    return false

  return true
}

const subscription_amount = (subscription: any, prices: any) => {
  // this process doesn't include discounts and other factors that can affect the due amount -> need to revise this in the future
  let amount = 0
  subscription.items.forEach((item) => {
    let price = prices[item.price.id]
    price = price?.metadata?.reference_price_id ? prices[price.metadata.reference_price_id] : price
    amount += item.quantity * price.unit_amount
  })

  return amount
}

// prettier-ignore
export {
  invoice_amount_remaining,
  invoice_subtotal,
  invoice_total,
  is_invoice_due,
  is_invoice_open,
  is_pm_default,
  is_sub,
  is_sub_canceled,
  is_sub_ended,
  is_sub_ending,
  is_sub_invoice,
  is_sub_renewing,
  is_sub_sched,
  is_sub_trialing,
  sub_has_discount,
  subscription_upcoming_invoice_subtotal,
  subscription_amount,
  subscriptions_has_any_discount,
}
