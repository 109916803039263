import EntitiesPage from '../pages/entities-page.vue'

export default [
  {
    path: '/:slug/tax/entities',
    name: 'tax.entities',
    component: EntitiesPage,
    meta: {},
  },
]
