<script setup lang="ts">
import { computed } from 'vue'
import { VSkeletonBar, VIcon } from '@/modules/shared/components'

type Pair = {
  term: string
  description: string | object
  info?: string
}

const props = withDefaults(
  defineProps<{
    items: Pair[]
    layout?: 'adjacent' | 'stacked'
    skeleton?: boolean
    item_class?: string
    theme?: 'plain' | 'form'
  }>(),
  {
    layout: 'adjacent',
    item_class: '',
    theme: 'plain',
  },
)

const isAdjacent = computed(() => props.layout === 'adjacent')
</script>

<template>
  <dl>
    <div
      :class="[{ 'mb-1': index < items.length - 1, 'flex items-center justify-between': isAdjacent }, item_class]"
      :key="item.term"
      v-for="(item, index) in items"
    >
      <dt
        class="text-sm font-semibold"
        :class="{
          'mb-1': !isAdjacent,
          ' text-gray-700 dark:text-zinc-600': theme === 'plain',
          'text-gray-500': theme === 'form',
        }"
      >
        <div class="flex items-center gap-1">
          <div class="">
            <slot name="term" :item="item">{{ item.term }}</slot>
          </div>
          <div v-if="item.info" class="group relative hover:block">
            <div
              class="rounded-full border-none bg-white font-medium text-gray-700 shadow-sm ring-1 ring-gray-700/20 hover:shadow-md hover:ring-gray-700/30 active:shadow-sm"
            >
              <VIcon name="help" class="h-4 w-4 text-gray-700" />
            </div>
            <div class="absolute bottom-full left-1/2 hidden w-80 -translate-x-1/2 transform pb-2 group-hover:block">
              <div class="rounded-md bg-gray-900 px-3.5 py-2.5 text-sm text-gray-100 shadow-lg">
                {{ item.info }}
              </div>
            </div>
          </div>
        </div>
      </dt>
      <dd
        class="text-sm text-gray-700 dark:text-zinc-600"
        :class="{
          'font-semibold': theme === 'form',
        }"
      >
        <VSkeletonBar class="w-1/2" v-if="skeleton" />
        <slot v-else name="description" :item="item">{{ item.description }}</slot>
      </dd>
    </div>
  </dl>
</template>
