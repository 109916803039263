const to_currency = (amount: number, currency: string, locale: string = 'en-US') => {
  return amount.toLocaleString(locale, {
    currency: currency.toUpperCase(),
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: 'currency',
  })
}

export { to_currency }
