<script setup lang="ts">
import { useExtendedI18n } from '@/i18n'
import { capitalize } from 'lodash'
import { RouterLinkBack, VButtonInvisible, VSection } from '@/modules/shared/components'
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import TaxesList from '@/modules/attaching/components/taxes-list.vue'
import { useInvestingInvestorStore } from '@/modules/investing/stores/investor-store'
import { computed, onMounted, ref } from 'vue'
import { createOptions } from '@/modules/shared/utils/form'

const { t } = useExtendedI18n()
const investorStore = useInvestingInvestorStore()
const taxesUtil = ref(null)

const profiles = computed(() => {
  const filtered_keys = Object.keys(investorStore.profiles).filter(
    (profile_key) => !!profile_key.match('individual') || !!profile_key.match('funding-entity'),
  )

  return filtered_keys.reduce((acc, key) => {
    if (investorStore.profiles.hasOwnProperty(key)) {
      acc[key] = investorStore.profiles[key]
    }
    return acc
  }, {})
})

const profileOptions = computed(() => createOptions(profiles.value, { label: 'name' }))

onMounted(async () => {
  await Promise.all([investorStore.fetchProfiles()])
})
</script>

<template>
  <TheLayout>
    <div class="-mt-8 mb-6">
      <RouterLinkBack :to="{ name: 'dashboard' }">
        <VButtonInvisible>← {{ capitalize(t('shared.back')) }}</VButtonInvisible>
      </RouterLinkBack>
    </div>
    <TaxesList v-model="taxesUtil" label="Tax Returns" :filter_options="profileOptions" />
  </TheLayout>
</template>
