import Big from 'big.js'
import { assertNumeric } from '../math'

export function roi(args: { current_value: number; distributed: number; initial_value: number }) {
  const { current_value, distributed, initial_value } = args

  assertNumeric(current_value)
  assertNumeric(distributed)
  assertNumeric(initial_value)

  if (initial_value === 0) {
    return 0.0
  }

  // prettier-ignore
  return Big(current_value)
    .plus(distributed)
    .minus(initial_value)
    .div(initial_value)
    .toNumber()
}
