<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { RouterLinkBack, VButton, VButtonInvisible } from '@/modules/shared/components'
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import { useProductStore } from '../stores/product-store'

const productStore = useProductStore()

const recurring_interval = ref('month')

onMounted(async () => {
  await Promise.all([productStore.list()])
})

const submit = async () => {
  console.log(recurring_interval.value)
}
</script>

<template>
  <TheLayout>
    <div class="py-12 sm:mx-12">
      <div class="mb-12">
        <div class="flex items-center justify-between">
          <RouterLink :to="{ name: 'billing-group' }">
            <VButtonInvisible>← Back</VButtonInvisible>
          </RouterLink>
        </div>
      </div>
      <div class="mb-12">
        <div class="mb-6 border-b px-0 pb-1">
          <h3 class="text-base font-medium leading-7">Select Plan</h3>
        </div>
        <div class="-mt-4 flex items-center justify-center">
          <div
            class="inline-flex items-center space-x-1 rounded-full bg-white p-1 text-xs font-medium text-gray-700 shadow-sm ring-1 ring-gray-700/10"
          >
            <div
              class="cursor-pointer rounded-full px-2.5 py-1"
              :class="
                recurring_interval === 'month'
                  ? 'bg-gray-700 text-white hover:bg-gray-600'
                  : 'bg-gray-100 hover:bg-gray-200'
              "
              @click="recurring_interval = 'month'"
            >
              Monthly
            </div>
            <div
              class="cursor-pointer rounded-full px-2.5 py-1"
              :class="
                recurring_interval === 'year'
                  ? 'bg-gray-700 text-white hover:bg-gray-600'
                  : 'bg-gray-100 hover:bg-gray-200'
              "
              @click="recurring_interval = 'year'"
            >
              Annually
            </div>
          </div>
        </div>
        <div class="relative items-start justify-between lg:flex">
          <div v-for="product in productStore.group_products">
            <h1 class="mb-1 text-xl text-gray-800">{{ product.name }}</h1>
            <div class="mb-6">
              <div v-for="price in product.stripe_prices" v-show="price.recurring_interval === recurring_interval">
                {{
                  (price.unit_amount / 100).toLocaleString('en-US', {
                    style: 'currency',
                    currency: price.currency.toUpperCase(),
                  })
                }}
                {{ price.currency.toUpperCase() }} /
                {{ price.recurring_interval }}
              </div>
            </div>
            <div class="space-x-2">
              <VButton :click="submit">Subscribe to plan</VButton>
              <RouterLinkBack>
                <VButton>Go back</VButton>
              </RouterLinkBack>
            </div>
          </div>
          <div class="flex items-center space-x-2"></div>
        </div>
      </div>
    </div>
  </TheLayout>
</template>
