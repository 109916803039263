<script setup lang="ts">
import OtherEntityNav from '../components/other-entity-nav.vue'
import OtherEntityHeader from '../components/other-entity-header.vue'
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import { useCommitmentStore } from '../stores/commitment-store'
import { useRoute } from 'vue-router'
import { onMounted, ref, computed } from 'vue'
import { useExtendedI18n } from '@/i18n'
import { capitalize, get } from 'lodash'
import {
  VTable,
  ActionsMenu,
  ActionItem,
  ActionsGroup,
  IconLinkExternal,
  IconTrash,
  VSection,
  VModal,
  VButton,
} from '@/modules/shared/components'
import { useAuthStore } from '@/modules/auth/stores/auth-store'
import { useModal } from '@/modules/shared/composables/use-modal'
import { required, requiredIf } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import VSelect from '../components/v-select.vue'
import { VTextField, VIcon } from '@/modules/shared/components'
import { createOptions, markDisabledOptions } from '@/modules/shared/utils/form'
import { useInvestingInvestorStore } from '../stores/investor-store'
import { useOtherEntityStore } from '../stores/other-entity-store'
import { parse } from '@/modules/shared/utils/v-table'

const { n, t } = useExtendedI18n()

///////////////////////////////////////////////////////////////////////////////
//
///////////////////////////////////////////////////////////////////////////////

const addressColumns = [
  {
    key: 'investor.address.street1',
    name: capitalize(t('shared.line 1')),
    type: 'string',
    align: 'left',
    is_visible: false,
  },
  {
    key: 'investor.address.street2',
    name: capitalize(t('shared.line 2')),
    type: 'string',
    align: 'left',
    is_visible: false,
  },
  {
    key: 'investor.address.city',
    name: capitalize(t('shared.city')),
    type: 'string',
    align: 'left',
    is_visible: false,
  },
  {
    key: 'investor.address.state',
    name: capitalize(t('shared.state')),
    type: 'string',
    align: 'left',
    is_visible: false,
  },
  {
    key: 'investor.address.zip',
    name: capitalize(t('shared.postal code')),
    type: 'string',
    align: 'left',
    is_visible: false,
  },
  {
    key: 'investor.address.country',
    name: capitalize(t('shared.country')),
    type: 'string',
    align: 'left',
    is_visible: false,
  },
]

///////////////////////////////////////////////////////////////////////////////

const route = useRoute()
const skeleton = ref(true)
const authStore = useAuthStore()
const otherEntityStore = useOtherEntityStore()
const investorStore = useInvestingInvestorStore()
const commitmentStore = useCommitmentStore()
const investorFormModal = useModal()
const isAdmin = computed(() => authStore.is_site_or_group_admin)
const currentOtherEntity = ref(null)

const investorOptions = computed(() => {
  const disabledInvestors = [
    ...commitmentStore.commitments.map((c) => c.investor._cid),
    `funding-entity:${route.params.other_entity_id}`, // current entity can't invest in itself
  ]

  return markDisabledOptions(createOptions(investorStore.investorItems, { label: 'name' }), disabledInvestors)
})

const initialState = {
  id: null,
  date: new Date(),
  investor_id: null,
  ownership_percentage: null,
  amount: null,
}

const rules = {
  date: { required },
  investor_id: { required },
  ownership_override: { required: requiredIf(computed(() => !currentOtherEntity.value?.input_capital)) },
  amount: { required: requiredIf(computed(() => currentOtherEntity.value?.input_capital)) },
}
const commitment = ref({ ...initialState })
const v$ = useVuelidate(rules, commitment, { $lazy: true })

const openCommitmentForm = (item?) => {
  // reset
  v$.value.$reset()
  commitment.value = { ...initialState }

  // assign item to commitment
  if (item) {
    commitment.value = {
      id: get(item, 'id'),
      investor_id: get(item, 'investor._cid'),
      date: get(item, 'date'),
      ownership_override: (parse(get(item, 'ownership_override') || 0, 'number') as number) * 100,
      amount: get(item, 'capital_committed.amount'),
      carried_interest_percentage: item.carried_interest_percentage
        ? (parse(item.carried_interest_percentage, 'number') as number) * 100
        : null,
      management_fee_percentage: item.management_fee_percentage
        ? (parse(item.management_fee_percentage, 'number') as number) * 100
        : null,
      preferred_return_percentage: item.preferred_return_percentage
        ? (parse(item.preferred_return_percentage, 'number') as number) * 100
        : null,
    }
  }

  investorFormModal.open()
}

const saveCommitment = async () => {
  const valid = await v$.value.$validate()
  if (!valid) return

  const data = {
    date: commitment.value.date,
    investor_id: commitment.value.investor_id,
    capital: commitment.value.amount,
    ownership_override: commitment.value.ownership_override / 100,
  }

  if (commitment.value.id) {
    await commitmentStore.updateCommitment(commitment.value.id, data, 'funding-entity', route.params.other_entity_id)
  } else {
    await commitmentStore.addCommitment(data, 'funding-entity', route.params.other_entity_id)
  }
  investorFormModal.close()
}

const removeCommitment = async (item) => {
  if (!window.confirm('Are you sure?')) return
  await commitmentStore.removeCommitment(item.id, 'funding-entity', route.params.other_entity_id)
}

onMounted(async () => {
  await Promise.all([
    otherEntityStore.fetchOtherEntity(route.params.other_entity_id),
    commitmentStore.fetchCommitments('funding-entity', route.params.other_entity_id),
    investorStore.fetchInvestors(),
  ])
  currentOtherEntity.value = otherEntityStore.items.get(`funding-entity:${route.params.other_entity_id}`)
  skeleton.value = false
})
</script>

<template>
  <TheLayout>
    <OtherEntityHeader />
    <OtherEntityNav />
    <div class="-mt-7 mb-5 flex justify-end" v-if="isAdmin">
      <VButton :click="openCommitmentForm" size="md" variant="v-blue">
        <div class="mr-1 flex items-center space-x-2">
          <div><VIcon name="plus" /></div>
          <div>{{ capitalize(t('shared.add investor')) }}</div>
        </div>
      </VButton>
    </div>
    <VTable
      :columns="[
        {
          key: 'investor.name',
          name: capitalize(t('shared.investor')),
          sorted: true,
          type: 'string',
          align: 'left',
          fixed: true,
          is_visible: true,
        },
        {
          key: 'commitment',
          name: capitalize(t('shared.capital')),
          type: 'currency',
          aggregate: 'sum',
          align: 'right',
          is_visible: currentOtherEntity?.input_capital,
        },
        {
          key: 'ownership',
          name: capitalize(t('shared.ownership')),
          type: 'percent',
          align: 'center',
          is_visible: true,
        },
        {
          key: 'ownership_computed',
          name: capitalize(t('shared.ownership computed')),
          type: 'percent',
          align: 'center',
          is_visible: false,
          is_accessible: authStore.is_site_admin,
        },
        {
          key: 'ownership_override',
          name: capitalize(t('shared.ownership override')),
          type: 'percent',
          align: 'center',
          is_visible: false,
          is_accessible: authStore.is_site_admin,
        },
        {
          key: 'ownership_by_commitment',
          name: capitalize(t('shared.ownership by commitment')),
          type: 'percent',
          align: 'center',
          is_visible: false,
          is_accessible: authStore.is_site_admin,
        },
        {
          key: 'date',
          name: capitalize(t('shared.date')),
          type: 'date',
          align: 'left',
          is_visible: true,
        },
        {
          key: 'investor.email',
          name: capitalize(t('shared.email')),
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'investor.investing_name',
          name: capitalize(t('shared.legal name')),
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'investor.tax_id',
          name: `${t('shared.SSN')} / ${t('shared.EIN')}`,
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'investor.type',
          name: capitalize(t('shared.type')),
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'address.phone',
          name: capitalize(t('shared.phone number')),
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        ...addressColumns,
        {
          key: 'actions',
          name: '',
          type: 'actions',
          align: 'right',
          is_visible: true,
        },
      ]"
      :items="commitmentStore.commitments"
      :name="`other-entity-${route.params.other_entity_id}-investors`"
      :skeleton="skeleton"
      :slots="['actions', 'investor.name']"
    >
      <template #investor.name="{ item }">
        <RouterLink
          class="hyperlink"
          :to="
            get(item, 'investor.ruby_type') === 'fundingentity'
              ? { name: 'investing.other-entity.overview', params: { other_entity_id: get(item, 'investor.id') } }
              : { name: 'investing.individual-overview', params: { individual_id: get(item, 'investor.id') } }
          "
        >
          {{ get(item, 'investor.name') }}
        </RouterLink>
      </template>
      <template #actions="{ item }">
        <ActionsMenu v-if="isAdmin">
          <ActionsGroup :label="capitalize(t('shared.select action'))">
            <ActionItem
              :icon="IconLinkExternal"
              :text="capitalize(t('shared.edit'))"
              @click="() => openCommitmentForm(item)"
              tag="a"
            />
            <ActionItem
              :icon="IconTrash"
              :text="capitalize(t('shared.delete'))"
              @click="() => removeCommitment(item)"
            />
          </ActionsGroup>
        </ActionsMenu>
      </template>
    </VTable>

    <VModal :modalStore="investorFormModal">
      <template #main>
        <VSection :label="commitment.id ? 'Modify investor' : 'Add investor'">
          <form @submit.prevent="saveCommitment">
            <div class="mt-6 space-y-3">
              <VTextField
                v-model="commitment.date"
                :label="capitalize(t('shared.date'))"
                property="date"
                ref="autofocus"
                type="date"
                :v$="v$"
              />
              <VSelect
                v-model="commitment.investor_id"
                :disabled="commitment.id"
                :label="capitalize(t('shared.investor'))"
                :options="investorOptions"
                property="investor_id"
                :v$="v$"
              />
              <VTextField
                v-model="commitment.amount"
                :label="capitalize(t('shared.capital'))"
                :placeholder="n({ amount: '0', currency: currentOtherEntity?.currency, date: null }, 'currency')"
                property="amount"
                :v$="v$"
                v-if="currentOtherEntity?.input_capital"
              />
              <VTextField
                v-model="commitment.ownership_override"
                :label="capitalize(t('shared.ownership override'))"
                :placeholder="n(0, 'percent')"
                property="ownership_override"
                :v$="v$"
                v-else
              />
            </div>
          </form>
        </VSection>
      </template>
      <template #footer>
        <div class="flex items-center justify-between space-x-3">
          <VButton :click="investorFormModal.close" size="lg">{{ capitalize(t('shared.close')) }}</VButton>
          <VButton :click="saveCommitment" class="w-full" size="lg" variant="primary">
            <span v-if="commitment.id">{{ capitalize(t('shared.modify investor')) }}</span>
            <span v-else>{{ capitalize(t('shared.add investor')) }}</span>
          </VButton>
        </div>
      </template>
    </VModal>
  </TheLayout>
</template>
