import { reactive, readonly, watch } from 'vue'

export default function useFeatureFlags(knownFeatures: string[] = [], { route }) {
  const flags = reactive(
    knownFeatures.reduce((acc: any, feature: string) => {
      acc[feature] = false
      return acc
    }, {}),
  )

  const setFeatureFlag = (feature: string, value: boolean) => {
    if (feature in flags) {
      flags[feature] = value
    }
  }

  const setFlagsFromRoute = (features: string | string[]) => {
    for (const feature in flags) {
      setFeatureFlag(feature, false)
    }

    if (features) {
      const featureArray = Array.isArray(features) ? features : features.split(',')
      featureArray.forEach((feature) => setFeatureFlag(feature, true))
    }
  }

  setFlagsFromRoute(route.query.ff)

  watch(route, () => setFlagsFromRoute(route.query.ff))

  return {
    flags: readonly(flags),
  }
}
