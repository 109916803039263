import Big from 'big.js'
import { assertNumeric } from '../math'

export function dpi(args: { called: number; distributed: number }) {
  const { called, distributed } = args

  assertNumeric(called)
  assertNumeric(distributed)

  if (called === 0) {
    return 0.0
  }

  // prettier-ignore
  return Big(distributed)
    .div(called)
    .toNumber()
}
