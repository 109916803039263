<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useAuthStore } from '@/modules/auth/stores/auth-store'
import { VLoading } from '@/modules/shared/components'
import { useFetch } from '@/modules/shared/composables/use-fetch'
import InputGroup from '../components/input-group.vue'
import FormError from '../components/form-error.vue'
import VButton from '../components/v-button.vue'
import AuthLayout from '../layouts/auth-layout.vue'

///////////////////////////////////////////////////////////////////////////////
// Utils
///////////////////////////////////////////////////////////////////////////////

const redirectToSessionHandler = () => {
  const authStore = useAuthStore()

  // redirect to the Rails session URL to complete the login process
  const baseUrl = import.meta.env.VITE_VENTURE360_SESSION_URL
  const accessTokenParam = `access_token=${authStore?.access_token}`
  const slugParam = authStore?.slug ? `slug=${authStore.slug}` : ''
  const queryParams = [accessTokenParam, slugParam].filter((param) => param).join('&')

  window.location.href = `${baseUrl}/redirect?${queryParams}`
}

///////////////////////////////////////////////////////////////////////////////
// Types
///////////////////////////////////////////////////////////////////////////////

enum VerificationChannels {
  sms = 'sms',
  email = 'email',
}

type VerifyPayload = {
  channel: VerificationChannels
  verification_code: string | null
}

type VerifyResponse = {}

type VerifyResponseError = {
  reason: string
}

///////////////////////////////////////////////////////////////////////////////
// Verify
///////////////////////////////////////////////////////////////////////////////

const router = useRouter()
const route = useRoute()
const authStore = useAuthStore()
const error_message = ref(null)

const isProcessing = ref(false)
const payload = ref<VerifyPayload>({
  channel: VerificationChannels.email,
  verification_code: null,
})

const { data, error, execute } = useFetch('/auth/verify', { immediate: false })
  .post(payload.value)
  .json<{ data: VerifyResponse | VerifyResponseError }>()

const verify = async () => {
  isProcessing.value = true

  await execute()

  if (error.value) {
    error_message.value = (data.value.data as VerifyResponseError).reason
    isProcessing.value = false
    return
  }

  redirectToSessionHandler()
}

///////////////////////////////////////////////////////////////////////////////
// Request verification code
///////////////////////////////////////////////////////////////////////////////

const calculateTimeRemaining = (startTime: string | Date | null, duration: number = 15 * 60 * 1000) => {
  const now = new Date().getTime()
  const timeStartedAt = new Date(startTime || 0).getTime()
  const timeElapsed = now - timeStartedAt

  return Math.max(0, duration - timeElapsed)
}

const canRequestVerificationCode = (requestedAt: string | Date | null, cooldown: number) => {
  return calculateTimeRemaining(requestedAt, cooldown) === 0
}

const requestVerificationCode = async (channel: VerificationChannels, cooldown: number) => {
  payload.value.channel = channel

  await authStore.refresh(null, route)

  if (authStore.current_user.two_factor_enabled_at === null && channel === VerificationChannels.sms) {
    router.push({ name: 'auth.enable-two-factor' })
    return
  }

  if (!canRequestVerificationCode(authStore.current_user.two_factor_requested_at, cooldown)) {
    return
  }

  const { error, data } = await authStore.requestVerificationCode(channel)

  if (error.value) {
    error_message.value = (data.value.data as VerifyResponseError).reason
  }
}

///////////////////////////////////////////////////////////////////////////////
// Skip verification
///////////////////////////////////////////////////////////////////////////////

type SkipEnableTwoFactorAuthenticationResponseError = {
  reason: string
}

const canSkipTwoFactorVerification = computed(() => authStore.current_user.two_factor_enabled_at === null)

const skipTwoFactorVerification = async () => {
  const { data, error } = await useFetch('/auth/skip-enable-two-factor-authentication').post().json()

  if (error.value) {
    error_message.value = (data.value.data as SkipEnableTwoFactorAuthenticationResponseError).reason
    return
  }

  redirectToSessionHandler()
}

onMounted(() => {
  const COOLDOWN = 15 * 60 * 1000
  requestVerificationCode(payload.value.channel, COOLDOWN)
})
</script>

<template>
  <AuthLayout>
    <template v-slot:icon>
      <svg width="90" height="85" viewBox="0 0 90 85" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M70.5737 40.695L61.4537 31.575L69.9337 23.087L79.0137 32.167C81.3737 34.447 85.1737 34.367 87.4937 32.007C89.7337 29.647 89.7337 25.967 87.4937 23.647L78.3737 14.527L82.5977 10.295C84.9177 7.93503 84.9177 4.13503 82.5977 1.77503C80.2377 -0.584968 76.4377 -0.584968 74.0777 1.73503L31.0137 44.775V44.771C20.7737 39.251 8.05368 43.051 2.53368 53.251C-2.98632 63.451 0.813678 76.171 11.0137 81.691C21.2137 87.171 33.9337 83.371 39.4537 73.171C42.8137 66.931 42.8137 59.411 39.4137 53.171L52.7737 39.811L61.8537 48.931C64.1737 51.251 67.9737 51.251 70.3337 48.891C72.6537 46.531 72.6537 42.731 70.2937 40.371L70.5737 40.695Z"
          fill="#111827"
        />
        <path
          d="M21.1976 55.855C19.2085 55.855 17.3009 56.6452 15.8943 58.0517C14.4878 59.4582 13.6976 61.3659 13.6976 63.355C13.6976 65.3441 14.4878 67.2518 15.8943 68.6583C17.3009 70.0648 19.2085 70.855 21.1976 70.855C23.1868 70.855 25.0944 70.0648 26.5009 68.6583C27.9075 67.2518 28.6976 65.3441 28.6976 63.355C28.6976 61.3659 27.9075 59.4582 26.5009 58.0517C25.0944 56.6452 23.1868 55.855 21.1976 55.855Z"
          stroke="white"
          stroke-width="6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </template>

    <div class="flex h-full w-[360px] flex-col justify-between">
      <header class="mt-24">
        <h1 class="mb-8 text-3xl leading-9 text-gray-900">
          Check your {{ payload.channel === VerificationChannels.email ? 'email' : 'phone' }}
        </h1>
        <div class>
          <InputGroup
            label="Verification Code"
            placeholder="000000"
            v-model="payload.verification_code"
            @keydown.enter="verify"
          />
        </div>
        <FormError :error="error_message" v-show="error_message !== null" />
        <VButton @click="verify" :disabled="isProcessing">
          <span v-if="isProcessing" class="p-0.5"><VLoading /></span>
          <span v-else>Verify</span>
        </VButton>
        <p class="mt-5 text-sm">
          <a href="#" class="text-gray-800 underline" @click.prevent="requestVerificationCode(payload.channel, 0)">
            Didn’t receive a code? Send another one now
          </a>
        </p>
        <p class="mt-5 text-sm">
          <a
            href="#"
            class="text-gray-800 underline"
            @click.prevent="
              requestVerificationCode(
                payload.channel === VerificationChannels.email ? VerificationChannels.sms : VerificationChannels.email,
                0,
              )
            "
          >
            Want a text instead? Send to your phone
          </a>
        </p>
        <p class="mt-5 text-sm">
          <a
            href="#"
            v-show="canSkipTwoFactorVerification"
            class="text-gray-800 underline"
            @click.prevent="skipTwoFactorVerification"
          >
            Having as issue? Skip verification this time
          </a>
        </p>
        <p v-if="false" class="mt-5 text-sm leading-5 text-gray-700">
          <router-link :to="{ name: 'request-two-factor-reset' }" class="underline"
            >Forgot your phone number or need to use a different phone number?</router-link
          >
        </p>
        <p class="mt-5 text-sm italic leading-5 text-gray-700">
          - The code is valid for 15 minutes.<br />
          - Only share the code with people you trust.<br />
          - Venture360 support will never ask you for the code.
        </p>
      </header>
      <main class="animate mb-16 mt-24 flex-grow" :class="{ 'animate-shake': error !== null }">
        <div class="mt-4 flex items-center justify-end"></div>
      </main>
      <footer class="mb-12"></footer>
    </div>
  </AuthLayout>
</template>
