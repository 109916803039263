import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { useAuthStore } from '../../auth/stores/auth-store'
import { useTemplateService } from '../services/template-service'

// Utils

const handle_error = (err: any) => {
  // TODO handle error
  console.error(err)
  return err
}

export const useTemplateStore = defineStore('template', () => {
  const authStore = useAuthStore()
  const route = useRoute()
  const service = useTemplateService({
    headers: {
      Authorization: `Bearer ${authStore.access_token}`,
    },
    route,
  })

  /////////////////////////////////////////////////////////////////////////////
  // STATE
  /////////////////////////////////////////////////////////////////////////////

  const item = ref(null)
  const items = ref([])

  /////////////////////////////////////////////////////////////////////////////
  // GETTERS
  /////////////////////////////////////////////////////////////////////////////

  const getters = {}

  /////////////////////////////////////////////////////////////////////////////
  // ACTIONS
  /////////////////////////////////////////////////////////////////////////////

  const actions = {
    generate_embed: async (payload: object) => {
      const [err, data] = await service.generate_embed(null, payload)
      if (err) return handle_error(err)
      return data
    },

    retrieve: async (template_id) => {
      const [err, data] = await service.retrieve(null, { template_id })
      if (err) return handle_error(err)

      item.value = data.data
    },

    // POST

    create: async (payload: object) => {
      const [err, data] = await service.create(null, payload)
      if (err) return handle_error(err)
    },

    duplicateTemplate: async (payload: object) => {
      const [err, data] = await service.duplicateTemplate(null, payload)
      if (err) return handle_error(err)
    },

    replaceTemplate: async (payload: object) => {
      const [err, data] = await service.replaceTemplate(null, payload)
      if (err) return handle_error(err)
    },
  }

  /////////////////////////////////////////////////////////////////////////////
  // RETURN
  /////////////////////////////////////////////////////////////////////////////

  return {
    // state
    item,
    items,
    ...actions,
    ...getters,
  }
})
