<script setup lang="ts">
import { useDark } from '@vueuse/core'
import { onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'
import { setLocale } from '@/i18n'
import useFeatureFlags from '@/modules/shared/composables/use-feature-flags'
import { useAuthStore } from './modules/auth/stores/auth-store'

const route = useRoute()
const authStore = useAuthStore()
const { flags } = useFeatureFlags(['lang:en', 'lang:es', 'theme:dark'], { route })

///////////////////////////////////////////////////////////////////////////////
// Locale
///////////////////////////////////////////////////////////////////////////////

watch(flags, () => {
  if (flags['lang:en']) setLocale('en')
  if (flags['lang:es']) setLocale('es')
})

///////////////////////////////////////////////////////////////////////////////
// Theme
///////////////////////////////////////////////////////////////////////////////

const isDark = useDark()

// for now, always default to lightmode
isDark.value = false

watch(flags, () => {
  if (flags['theme:dark']) isDark.value = true
})

onMounted(() => {
  if (window.Intercom === undefined) return

  window.Intercom('boot', {
    api_base: 'https://api-iam.intercom.io',
    app_id: 'wcxrr2t8',
    email: authStore.current_user.email,
    user_id: authStore.current_user.id,
    user_hash: authStore.current_user.intercom_hash,
  })
})
</script>

<template>
  <RouterView :key="$route.fullPath" />
</template>
