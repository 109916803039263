<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{
  to: any
}>()

const isExternal = computed(() => typeof props.to === 'string' && props.to.startsWith('http'))
</script>

<template>
  <a v-if="isExternal" :href="to"><slot /></a>
  <RouterLink v-else v-bind="$props"><slot /></RouterLink>
</template>
