import { useHead } from '@vueuse/head'
import { createI18n, useI18n } from 'vue-i18n'
import shared_en_us from './modules/shared/locales/en-us.json'
import shared_es_cr from './modules/shared/locales/es-cr.json'
import { Money, toNumber, validMoney } from './modules/shared/utils/money'

type Locales = 'en-AU' | 'en-CA' | 'en-GB' | 'en-US' | 'fr-CA' | 'fr-FR'

const numberFormats = {
  'en-AU': {},
  'en-CA': {},
  'en-GB': {},
  'en-US': {
    percent: {
      style: 'percent',
      useGrouping: false,
      minimumFractionDigits: 1,
      maximumFractionDigits: 2,
    },
  },
  'fr-CA': {},
  'fr-FR': {},
}

const messages = {
  'en-US': {
    shared: shared_en_us,
  },
  'es-CR': {
    shared: shared_es_cr,
  },
}

const i18n = createI18n({
  legacy: false,
  locale: 'en-US',
  fallbackLocale: 'en-US',
  numberFormats,
  messages,
})

function setLocale(locale: Locales) {
  i18n.global.locale.value = locale
  useHead({
    htmlAttrs: {
      lang: locale,
    },
  })
}

function useExtendedI18n() {
  const i18n = useI18n()

  // TODO options should be an object and not depend on the order of the arguments
  const n = (value: Money | number, key?: string, locale?: string, useCommon?: boolean, precision?: number) => {
    const whichLocale = locale || i18n.locale.value

    if (key === 'currency') {
      if (!validMoney(value)) return null

      // Experiment with different formats
      // => $100,000.00 USD
      // return (
      //   new Intl.NumberFormat(whichLocale, {
      //     style: 'currency',
      //     currency: (value as Amount).currency,
      //     currencyDisplay: 'narrowSymbol',
      //   }).format((value as Amount).value) + (value as Amount).currency
      // )

      // => US$100,000.00

      precision = precision === null || precision === undefined ? 2 : precision

      const amount = useCommon ? toNumber(value, useCommon) : toNumber(value)

      return new Intl.NumberFormat(whichLocale, {
        style: 'currency',
        currency: useCommon ? value.common_currency : value.currency,
        currencyDisplay: 'symbol',
        maximumFractionDigits: precision,
        minimumFractionDigits: precision,
      }).format(amount)
    }

    return i18n.n(parseFloat(value), key, whichLocale)
  }

  return { ...i18n, n }
}

export { i18n, setLocale, useExtendedI18n }
