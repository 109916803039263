<script setup lang="ts">
import { capitalize, get } from 'lodash'
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useExtendedI18n } from '@/i18n'
import { useAuthStore } from '@/modules/auth/stores/auth-store'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import {
  IconLinkExternal,
  IconTrash,
  RouterLinkBack,
  VButton,
  VButtonInvisible,
  VDropdownMultiple,
  VIcon,
  VModal,
  VSection,
  VTable,
  VTextField,
} from '@/modules/shared/components'
import { useModal } from '@/modules/shared/composables/use-modal'
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import { markDisabledOptions } from '@/modules/shared/utils/form'
import { rails_url } from '@/modules/shared/utils/rails'
import { unflattenKey } from '@/modules/shared/utils/v-table'
import { useInvestingInvestorStore } from '../stores/investor-store'
import { useSubgroupStore } from '../stores/subgroup-store'
import { ActionsMenu, ActionsGroup, ActionItem } from '@/modules/shared/components'
import IndividualsNav from '../components/individuals-nav.vue'

const { t } = useExtendedI18n()
const route = useRoute()
const router = useRouter()
const authStore = useAuthStore()
const investingInvestorStore = useInvestingInvestorStore()
const subgroupStore = useSubgroupStore()
const subgroupFormModal = useModal()
const skeleton = ref(true)
const isAdmin = computed(() => authStore.is_site_or_group_admin)

///////////////////////////////////////////////////////////////////////////////
// ADD AND EDIT SUBGROUP
///////////////////////////////////////////////////////////////////////////////
const initialState = {
  id: null,
  name: null,
  admin_ids: [],
}
const subgroup = ref({ ...initialState })
const rules = {
  name: { required },
}
const v$ = useVuelidate(rules, subgroup, { $lazy: true })

const individualOptions = computed(() => {
  const disabledIndividuals = []
  const options = investingInvestorStore.individuals.map((individual) => ({
    label: individual.name,
    value: individual._cid,
  }))
  return markDisabledOptions(options, disabledIndividuals).sort((a, b) => a.label.localeCompare(b.label))
})

const resetFields = () => {
  v$.value.$reset()
  subgroup.value = { ...initialState }
}

const showSubgroupForm = (item?) => {
  resetFields()

  if (item) {
    subgroup.value = {
      id: get(item, 'id'),
      name: get(item, 'name'),
      admin_ids: unflattenKey(item, 'admins').map((admin) => admin._cid),
    }
  }
  subgroupFormModal.open()
}

const saveSubgroup = async () => {
  const valid = await v$.value.$validate()
  if (!valid) return

  subgroup.value.admin_ids = [...subgroup.value.admin_ids].map(
    (admin_id) => investingInvestorStore.items.get(admin_id).id,
  )

  if (subgroup.value.id) {
    await subgroupStore.updateSubgroup(subgroup.value)
  } else {
    const new_subgroup = await subgroupStore.addSubgroup(subgroup.value)
    router.push({ name: 'investing.subgroup-overview', params: { subgroup_id: new_subgroup.id } })
  }
  resetFields()
}

const removeSubgroup = async (subgroup_id) => {
  if (!window.confirm('Are you sure?')) return
  await subgroupStore.removeSubgroup(subgroup_id)
}

onMounted(async () => {
  await Promise.all([subgroupStore.fetchSubgroups(), investingInvestorStore.fetchIndividuals('all')])

  skeleton.value = false
})
</script>

<template>
  <TheLayout>
    <div class="-mt-8 mb-6 flex items-center justify-between">
      <div>
        <RouterLinkBack class="text-lg" :to="{ name: 'dashboard' }">
          <VButtonInvisible>← {{ capitalize(t('shared.back')) }}</VButtonInvisible>
        </RouterLinkBack>
      </div>
    </div>

    <VSection>
      <IndividualsNav />
      <div class="mb-3 flex items-center justify-end">
        <div v-if="isAdmin" class="flex items-center space-x-1.5">
          <VButton :click="showSubgroupForm" size="md" variant="v-blue">
            <div class="mr-1 flex items-center space-x-2">
              <div><VIcon name="plus" /></div>
              <div>{{ capitalize(t('shared.add subgroup')) }}</div>
            </div>
          </VButton>
        </div>
      </div>
      <VTable
        :name="`subgroups-${route.params.slug}`"
        :columns="[
          {
            key: 'name',
            name: capitalize(t('shared.name')),
            type: 'string',
            align: 'left',
            is_visible: true,
          },
          {
            key: 'total_active_investor',
            name: capitalize(t('shared.investor', 0)),
            type: 'string',
            align: 'left',
            is_visible: true,
          },
          {
            key: 'admins',
            name: capitalize(t('shared.admin', 0)),
            type: 'string',
            align: 'left',
            is_visible: true,
          },
          {
            key: 'actions',
            name: '',
            type: 'actions',
            align: 'right',
            is_visible: true,
          },
        ]"
        :items="subgroupStore?.subgroups"
        :skeleton="skeleton"
        :slots="['actions', 'name', 'total_active_investor', 'admins']"
        no_data_message="Subgroups are created as a way to group investors. Administrators of a Subgroup will have full access to the investors accounts in their subgroup, so proceed with caution. Subgroups are most often used for financial advisors, managers, or assistants to be able to access information for their clients."
      >
        <template #name="{ item }">
          <RouterLink
            class="cursor-pointer whitespace-break-spaces font-medium text-[#51889B] underline decoration-[#51889B]/50"
            :to="{
              name: 'investing.subgroup-overview',
              params: { subgroup_id: item.id },
            }"
          >
            {{ item.name }}
          </RouterLink>
        </template>
        <template #total_active_investor="{ item }"
          ><span>{{ unflattenKey(item, 'individuals').length }}</span>
        </template>
        <template #admins="{ item }" v-if="isAdmin">
          <span v-for="(admin, index) in unflattenKey(item, 'admins')" :key="admin.id">
            <RouterLink
              v-if="authStore.is_site_or_group_admin"
              class="text-[#3b88af] underline decoration-[#3b88af]/50 hover:text-gray-900 hover:decoration-gray-900/50"
              :to="{ name: 'investing.individual-overview', params: { individual_id: admin.id } }"
            >
              {{ admin.name }}
            </RouterLink>
            <span class="text-gray-900" v-else>{{ admin.name }}</span>
            <span v-if="index !== unflattenKey(item, 'admins').length - 1">, </span>
          </span>
        </template>
        <template #actions="{ item }" v-if="isAdmin">
          <ActionsMenu>
            <ActionsGroup>
              <ActionItem
                tag="button"
                :text="capitalize(t('shared.modify'))"
                @click="() => showSubgroupForm(item)"
                :icon="IconLinkExternal"
              />
              <ActionItem
                tag="button"
                :text="capitalize(t('shared.remove'))"
                @click="() => removeSubgroup(item.id)"
                :icon="IconTrash"
              />
            </ActionsGroup>
          </ActionsMenu>
        </template>
      </VTable>
    </VSection>

    <VModal :modalStore="subgroupFormModal">
      <template #main>
        <VSection :label="capitalize(t('shared.add subgroup'))">
          <form @submit.prevent="saveSubgroup">
            <div class="mt-6 space-y-3">
              <VTextField v-model="subgroup.name" :label="capitalize(t('shared.name'))" property="name" :v$="v$" />
              <div class="mb-5">
                <label for="" class="mb-1 block text-sm font-medium text-gray-700">
                  {{ capitalize(t('shared.admin', 0)) }}
                </label>
                <VDropdownMultiple
                  aligned="left"
                  class="z-auto"
                  :options="individualOptions"
                  property="investor_admin_ids"
                  v-model="subgroup.admin_ids"
                  :show-selected-list="true"
                >
                  <VButton size="xl" class="w-full">
                    <div class="flex items-center justify-between">
                      <div>Select multiple...</div>
                      <VIcon name="chevron_selector_vertical" />
                    </div>
                  </VButton>
                </VDropdownMultiple>
              </div>
            </div>
          </form>
        </VSection>
      </template>
      <template #footer>
        <div class="flex items-center justify-between space-x-3">
          <VButton :click="subgroupFormModal.close" size="lg">{{ capitalize(t('shared.close')) }}</VButton>
          <VButton :click="saveSubgroup" class="w-full" size="lg" variant="primary">
            <span>{{
              subgroup.id ? capitalize(t('shared.confirm change', 0)) : capitalize(t('shared.add subgroup'))
            }}</span>
          </VButton>
        </div>
      </template>
    </VModal>
  </TheLayout>
</template>
