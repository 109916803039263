import { reactive } from 'vue'

interface File {
  name: string
  size: string
}

interface Template {
  file_url: Array<File>
  hellosign_template_id: string
  name: string | null
  message: string | null
  signing_roles: Array<Object>
  title: string | null
  uses_signing_order: boolean
}

interface State {
  template?: Template
  ui: any
}

const state = reactive(<State>{})

export default state

export { Template }
