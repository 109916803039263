import axios, { AxiosError } from 'axios'

type EndpointConfig = {
  files?: boolean
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
  name: string
  url: string
}

interface Actions {
  [key: string]: (id?: number | string, body?: Object, files?: FileList) => Promise<[Error, any]>
}

interface ServiceConfig {
  base_url: string
  headers?: Record<string, string>
  endpoints: EndpointConfig[]
}

interface UseServiceConfig {
  headers?: Record<string, string>
  route?: any
}

const createURL = (url: string, params: any) => {
  const keys = Object.keys(params)

  if (keys.length === 0) {
    return url
  }

  keys.forEach((key) => {
    url = url.replace(`:${key}`, params[key])
  })

  return url
}

// interface UseServiceConfig {
//   route: {
//     params: {
//       slug?: string
//     }
//   }
// }

function createService(createConfig: ServiceConfig) {
  return (useConfig: UseServiceConfig) => {
    const actions: Actions = {}

    createConfig.endpoints.forEach((endpoint) => {
      actions[endpoint.name] = (id?: number | string, body?: Object, files?: FileList) => {
        const requestOptions: RequestConfig = {
          body,
          headers: {
            ...createConfig.headers,
            ...useConfig.headers,
          },
          method: endpoint.method,
          url: createURL(`${createConfig.base_url}${endpoint.url}`, {
            ...body,
            id,
            slug: useConfig.route?.params?.slug,
          }),
        }

        if (endpoint.files) {
          requestOptions.files = files
        }

        return makeRequest(requestOptions)
      }
    })

    return {
      ...actions,
    }
  }
}

interface RequestConfig {
  url: string
  method: string
  headers?: Record<string, string>
  body?: Record<string, any> | FormData
  files?: FileList
}

async function makeRequest(config: RequestConfig): Promise<[Error | AxiosError, any]> {
  try {
    let body: any = null
    let formData = new FormData()
    let headers = { ...config.headers }

    // If `body` is specified and it's an object, append its fields to the form data.
    if (config.body && typeof config.body === 'object') {
      body = JSON.stringify(config.body)
    }

    // If `files` is specified, append them to the form data.
    if (config.files) {
      headers = {
        ...headers,
        'Content-Type': 'multipart/form-data',
      }

      formData.append('file', config.files[0])

      body = formData
    }

    const axiosConfig = {
      method: config.method,
      url: config.url,
      data: body,
      headers: headers,
    }

    const response = await axios(axiosConfig)

    return [null, response.data]
  } catch (err) {
    return [err, null]
  }
}

export { createService }
