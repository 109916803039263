import { ref, watch } from 'vue'
import { useDebounceFn } from '@vueuse/core'

export function useSearch() {
  const query = ref('')

  function clearQuery() {
    query.value = ''
  }

  function watchQuery(fn: Function) {
    watch(query, useDebounceFn(fn, 300))
  }

  return {
    query,

    clearQuery,
    watchQuery,
  }
}
