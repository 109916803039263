export interface Tier {
  up_to: number | null
  flat_amount: number
  unit_amount: number
}

const calculate_graduated_pricing = (quantity: number, tiers: Tier[]): number => {
  tiers = preprocess_tiers(tiers)

  let amount = 0

  for (const [index, tier] of tiers.entries()) {
    if (quantity <= 0) {
      break
    }

    const prev_up_to = index === 0 ? 0 : tiers[index - 1].up_to
    const tier_units = tier.up_to === null ? quantity : tier.up_to - prev_up_to
    amount += tier.flat_amount + tier_units * tier.unit_amount
    quantity -= tier_units
  }

  return amount
}

const getCurrentTimeInSeconds = () => {
  return Math.floor(Date.now() / 1000)
}

const clamp = (value: number, min: number, max: number) => {
  return Math.min(Math.max(value, min), max)
}

const calculate_usage = (current_period_start: number, current_period_end: number) => {
  const currentTime = getCurrentTimeInSeconds()
  const progress = (currentTime - current_period_start) / (current_period_end - current_period_start)
  return clamp(progress, 0, 1)
}

const preprocess_tiers = (tiers: Tier[]): Tier[] => {
  return tiers.map((tier) => ({
    ...tier,
    flat_amount: tier.flat_amount ?? 0,
    unit_amount: tier.unit_amount ?? 0,
  }))
}

export { calculate_graduated_pricing, calculate_usage, preprocess_tiers }
