import { omit } from 'lodash'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useAuthStore } from '@/modules/auth/stores/auth-store'
import { useNotificationStore } from '@/modules/shared/stores/notification-store'
import { useFilingLocatorService } from '../services/filing-locator-service'
import { useEntitySyncService } from '../services/entity-sync-service'

interface Links {
  [key: string]: string
}

interface Investor {
  id: number | string
  type: string
  name: string
  has_tax_documents: boolean
  tax_id: string
}

interface Entity {
  id: number | string
  administrator: string
  formation_date: string
  investors: Investor[]
  legal_name: string
  links: Links
  name: string
  tax_id: string
  type: string
}
export interface FilingLocator {
  id: number | string
  uuid?: string
  approved_for_filing_at: string
  entity: Entity
  finalized_return_document_url: string
  is_final_return: boolean
  prepared_return_document_url: string
  rejected_for_filing_at: string
  return_status: string
  tax_year: number
}

export const useFilingLocatorStore = defineStore('FilingLocator', () => {
  const authStore = useAuthStore()
  const route = useRoute()
  const notificationStore = useNotificationStore()
  const service = useFilingLocatorService({
    headers: {
      Authorization: `Bearer ${authStore.access_token}`,
    },
    route,
  })

  const entitySyncService = useEntitySyncService({
    headers: {
      Authorization: `Bearer ${authStore.access_token}`,
    },
    route,
  })

  /////////////////////////////////////////////////////////////////////////////
  // STATE
  /////////////////////////////////////////////////////////////////////////////

  // const mockItems: FilingLocator[] = [
  //   {
  //     id: 1,
  //     uuid: 'xyz',
  //     tax_year: 2022,
  //     is_final_return: false,
  //     return_status: 'not_ready',

  //     entity: {
  //       id: '1',
  //       administrator: 'John Doe',
  //       formation_date: '2020-01-01',
  //       investors: [
  //         {
  //           id: 1,
  //           type: 'Investor',
  //           name: 'LeBraun Premo',
  //           tax_id: '123456789',
  //         },
  //         {
  //           id: 2,
  //           type: 'Investor',
  //           name: 'Rachael Qualls',
  //           tax_id: null,
  //         },
  //       ],
  //       legal_name: 'XYZ LLC',
  //       links: {
  //         url: '#',
  //       },
  //       name: 'XYZ',
  //       tax_id: null,
  //       type: 'LLC',
  //     },
  //   },
  // ]

  const items = ref<FilingLocator[]>([])
  const item = ref<FilingLocator>()

  /////////////////////////////////////////////////////////////////////////////
  // GETTERS
  /////////////////////////////////////////////////////////////////////////////

  // prettier-ignore
  const company_k1_options = {
    'Not required': 'not-required',
    'Not received': 'not-received',
    'Received': 'received',
  }

  const entity_types = [
    'C-Corp',
    'LLC',
    'LLP',
    'LP',
    'Ltd',
    'Non-Profit Corporation',
    'Other',
    'Pte Ltd',
    'Pty Ltd',
    'S-Corp',
    'Trust',
  ]

  const is_empty = computed(() => items.value.length === 0)

  const is_finalized = computed(
    () =>
      items.value.length > 0 &&
      items.value.every((item) => ['ready_to_file', 'submitted', 'distributed'].includes(item.return_status)),
  )

  const extension_statuses = [
    {
      text: 'Not Filing',
      value: 'not_filing',
      disabled: true,
    },
    {
      text: 'Not Started',
      value: 'not_started',
      disabled: true,
    },
    {
      text: 'Ext Submitted',
      value: 'ext_submitted',
      disabled: true,
    },
    {
      text: 'Rejected',
      value: 'rejected',
      disabled: true,
    },
    {
      text: 'Paper Filing',
      value: 'paper_filing',
      disabled: true,
    },
    {
      text: 'Ext Accepted',
      value: 'accepted',
      disabled: true,
    },
    {
      text: 'Fed Automatic',
      value: 'fed_automatic',
      disabled: true,
    },
  ]

  const return_statuses = [
    {
      text: 'Not ready',
      value: 'not_ready',
      disabled: true,
    },
    {
      text: 'Not required',
      value: 'not_required',
      disabled: true,
    },
    {
      text: 'Disregarded entity',
      value: 'disregarded_entity',
      disabled: true,
    },
    {
      text: 'Extended',
      value: 'extended',
      disabled: true,
    },
    {
      text: 'Needs approval',
      value: 'needs_approval',
      disabled: true,
    },
    {
      text: 'Rejected for filing',
      value: 'rejected_for_filing',
      disabled: true,
    },
    {
      text: 'Approved for filing',
      value: 'approved_for_filing',
      disabled: true,
    },
    {
      text: 'Submitted',
      value: 'submitted',
      disabled: true,
    },
    {
      text: 'Distributed',
      value: 'distributed',
      disabled: true,
    },
  ]

  /////////////////////////////////////////////////////////////////////////////
  // UTILS
  /////////////////////////////////////////////////////////////////////////////

  const handle_error = (err: any) => {
    // TODO handle error
    console.error(err)
  }

  const updateItem = (item: FilingLocator, id: number | string, updates: any) => {
    if (item.id === id) {
      return {
        ...item,
        ...updates,
      }
    }
    return item
  }

  /////////////////////////////////////////////////////////////////////////////
  // ACTIONS
  /////////////////////////////////////////////////////////////////////////////

  const actions = {
    attach_finalized_return_document: async (id: number | string, file: FileList) => {
      const [err, _data] = await service.attach_finalized_return_document(id, {}, file)

      if (err) return handle_error(err)

      await actions.retrieve(id)
    },

    attach_investor_return_document: async (id: number | string, files: FileList) => {
      for (var i = 0; i < files.length; i++) {
        const [err, _data] = await service.attach_investor_return_document(id, {}, [files[i]])

        if (err) return handle_error(err)
      }

      await actions.retrieve(id)
    },

    attach_prepared_return_document: async (id: number | string, file: FileList) => {
      const [err, _data] = await service.attach_prepared_return_document(id, {}, file)

      if (err) return handle_error(err)

      await actions.retrieve(id)
      notificationStore.enqueue('success', 'Prepared return was successfully attached')
    },

    approve_prepared_return: async (id: number | string) => {
      const [err, _data] = await service.approve_prepared_return(id)

      if (err) return handle_error(err)

      await actions.retrieve(id)
    },

    create_efile: async (id: number | string) => {
      const [err, _data] = await entitySyncService.create_efile(id)

      if (err) return handle_error(err)

      await actions.retrieve(id)
    },

    efiles_validation: async (id: number | string) => {
      const [err, _data] = await entitySyncService.efiles_validation(id)

      if (err) return handle_error(err)

      await actions.retrieve(id)
    },

    download_return: async (id: number | string) => {
      const [err, _data] = await entitySyncService.download_return(id)

      if (err) return handle_error(err)

      await actions.retrieve(id)
    },

    import_entity: async (id: number | string) => {
      const [err, _data] = await entitySyncService.import_entity(id)

      if (err) return handle_error(err)

      await actions.retrieve(id)
    },

    print_return: async (id: number | string) => {
      const [err, _data] = await entitySyncService.print_return(id)

      if (err) return handle_error(err)

      await actions.retrieve(id)
    },

    recall_k1s: async (id: number | string) => {
      const [err, _data] = await service.recall_k1s(id)

      if (err) return handle_error(err)

      await actions.retrieve(id)
    },

    reject_prepared_return: async (id: number | string) => {
      const [err, _data] = await service.reject_prepared_return(id)

      if (err) return handle_error(err)

      await actions.retrieve(id)
    },

    remove_finalized_return_document: async (id: number | string) => {
      const [err, _data] = await service.remove_finalized_return_document(id)

      if (err) return handle_error(err)

      await actions.retrieve(id)
    },

    remove_prepared_return_document: async (id: number | string) => {
      const [err, _data] = await service.remove_prepared_return_document(id)

      if (err) return handle_error(err)

      await actions.list()
    },

    list: async () => {
      const [err, data] = await service.list()

      if (err) return handle_error(err)

      items.value = data.data
    },

    mark_as_final_return: async (id: number | string) => {
      const [err, _data] = await service.mark_as_final_return(id)

      if (err) return handle_error(err)

      items.value = items.value.map((item) => updateItem(item, id, { is_final_return: true }))
    },

    set_federal_extension_status: async (id: number | string, federal_extension_status: string) => {
      const [err, _data] = await service.set_federal_extension_status(id, { federal_extension_status })

      if (err) return handle_error(err)

      items.value = items.value.map((item) => updateItem(item, id, { federal_extension_status }))
    },

    set_federal_status: async (id: number | string, federal_status: string) => {
      const [err, _data] = await service.set_federal_status(id, { federal_status })

      if (err) return handle_error(err)

      items.value = items.value.map((item) => updateItem(item, id, { federal_status }))
    },

    set_final_return: async (id: number | string, final_return: boolean) => {
      const [err, _data] = await service.set_final_return(id, { final_return })

      if (err) return handle_error(err)

      items.value = items.value.map((item) => updateItem(item, id, { final_return }))
    },

    set_state_extension_status: async (id: number | string, state_extension_status: string) => {
      const [err, _data] = await service.set_state_extension_status(id, { state_extension_status })

      if (err) return handle_error(err)

      items.value = items.value.map((item) => updateItem(item, id, { state_extension_status }))
    },

    set_state_status: async (id: number | string, state_status: string) => {
      const [err, _data] = await service.set_state_status(id, { state_status })

      if (err) return handle_error(err)

      items.value = items.value.map((item) => updateItem(item, id, { state_status }))
    },

    set_return_status: async (id: number | string, return_status: string) => {
      const [err, _data] = await service.set_return_status(id, { return_status })

      if (err) return handle_error(err)

      items.value = items.value.map((item) => updateItem(item, id, { return_status }))
    },

    toggle_is_final_return: async (id: number | string, checked: boolean) => {
      if (checked) {
        await actions.mark_as_final_return(id)
      } else {
        await actions.unmark_as_final_return(id)
      }
    },

    unmark_as_final_return: async (id: number | string) => {
      const [err, _data] = await service.unmark_as_final_return(id)

      if (err) return handle_error(err)

      items.value = items.value.map((item) => updateItem(item, id, { is_final_return: false }))
    },

    patch: async (store_filing_locator: FilingLocator) => {
      const filing_locator = omit(store_filing_locator, ['entity'])
      const entity = omit(store_filing_locator.entity, ['investors'])
      const investors = store_filing_locator.investing.investors
      const payload = {
        filing_locator,
        entity,
        investors,
      }

      const [err, _data] = await service.patch(filing_locator.id, payload)

      if (err) return handle_error(err)

      // replace entire filing locator item
      items.value = items.value.map((item) => updateItem(item, filing_locator.id, store_filing_locator))
    },

    retrieve: async (id: number | string) => {
      const [err, data] = await service.retrieve(id)

      if (err) return handle_error(err)

      const index = items.value.findIndex((item) => item.id === id)

      items.value[index] = data.data
    },

    select: (id: number | string) => {
      const selected = items.value.find((item) => item.id === id)
      item.value = { ...selected }
    },
  }

  /////////////////////////////////////////////////////////////////////////////
  // RETURN
  /////////////////////////////////////////////////////////////////////////////

  return {
    items,
    item,
    entity_types,
    extension_statuses,
    is_empty,
    is_finalized,
    return_statuses,
    ...actions,
  }
})
