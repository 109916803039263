import { CID } from '@/modules/shared/utils/store.types'
import { useAuthStore } from '../stores/auth-store'

type Action = 'siteadmin' | 'admin' | 'view'

export const useCan = () => {
  const authStore = useAuthStore()

  const can = (action: Action, _object_id: CID) => {
    if (authStore.current_user.role === 'site_admin') return true // site admins can do anything

    // NOTE: front-end can be simpler than the back-end
    // NOTE: this is a bare-minimum implementation based on the current needs of the front-end
    if (action === 'admin') {
      if (authStore.current_user.role === 'group_admin') return true
      if (authStore.current_user.role === 'investor') return false
    }

    return false
  }

  return {
    can,
  }
}
