<template>
  <svg data-name="Layer 1" id="Layer_1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <title />
    <path
      fill="#4caf50"
      d="M16.5,22h-9a3,3,0,0,1-3-3V5a3,3,0,0,1,3-3h6.59a1,1,0,0,1,.7.29l4.42,4.42a1,1,0,0,1,.29.7V19A3,3,0,0,1,16.5,22Z"
    />
    <path
      fill="#b9f6ca"
      d="M18.8,7.74H15.2a1.5,1.5,0,0,1-1.5-1.5V2.64a.55.55,0,0,1,.94-.39L19.19,6.8A.55.55,0,0,1,18.8,7.74Z"
    />
    <path
      fill="#e8f5e9"
      d="M9.19,18.11a1.77,1.77,0,0,1-.82-.17,1.29,1.29,0,0,1-.55-.51,1.54,1.54,0,0,1-.2-.79,1.52,1.52,0,0,1,.2-.79,1.21,1.21,0,0,1,.55-.5,1.77,1.77,0,0,1,.82-.18,1.45,1.45,0,0,1,.35,0,1.47,1.47,0,0,1,.38.11.38.38,0,0,1,.19.16.43.43,0,0,1,.05.23.48.48,0,0,1-.07.21.27.27,0,0,1-.17.13.27.27,0,0,1-.23,0,.92.92,0,0,0-.39-.09.61.61,0,0,0-.47.18.77.77,0,0,0-.16.52.75.75,0,0,0,.16.52.58.58,0,0,0,.47.19l.19,0,.2-.07a.27.27,0,0,1,.23,0,.26.26,0,0,1,.16.13.38.38,0,0,1,.07.21.37.37,0,0,1-.05.23.38.38,0,0,1-.19.16,2.15,2.15,0,0,1-.37.11Z"
    />
    <path
      fill="#e8f5e9"
      d="M11.79,18.11a2.92,2.92,0,0,1-.51,0,1.62,1.62,0,0,1-.47-.13.38.38,0,0,1-.24-.2.39.39,0,0,1,0-.26.35.35,0,0,1,.16-.2.3.3,0,0,1,.28,0,1.93,1.93,0,0,0,.42.12,1.69,1.69,0,0,0,.38,0,.56.56,0,0,0,.3-.06.18.18,0,0,0,.09-.16.15.15,0,0,0-.06-.13A.38.38,0,0,0,12,17l-.63-.11a.91.91,0,0,1-.55-.25.74.74,0,0,1-.2-.53.85.85,0,0,1,.17-.51,1.07,1.07,0,0,1,.46-.33,1.83,1.83,0,0,1,.68-.12,3.17,3.17,0,0,1,.47,0,2,2,0,0,1,.41.13.33.33,0,0,1,.2.2.39.39,0,0,1,0,.26.35.35,0,0,1-.17.19.3.3,0,0,1-.28,0,2.19,2.19,0,0,0-.34-.11,1.36,1.36,0,0,0-.27,0,.62.62,0,0,0-.33.07.18.18,0,0,0-.1.16c0,.1.07.16.21.19l.64.11a1,1,0,0,1,.56.24.7.7,0,0,1,.2.52.83.83,0,0,1-.36.72A1.56,1.56,0,0,1,11.79,18.11Z"
    />
    <path
      fill="#e8f5e9"
      d="M14.84,18.1a.59.59,0,0,1-.57-.4l-.82-1.87a.51.51,0,0,1,0-.45.46.46,0,0,1,.44-.2.44.44,0,0,1,.29.09.63.63,0,0,1,.21.32l.48,1.24.51-1.25a.72.72,0,0,1,.2-.31.53.53,0,0,1,.32-.09.4.4,0,0,1,.27.09.36.36,0,0,1,.13.24.53.53,0,0,1-.05.32l-.84,1.88A.58.58,0,0,1,14.84,18.1Z"
    />
  </svg>
</template>
