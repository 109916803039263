import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useFetch } from '@/modules/shared/composables/use-fetch'
import { addItems, clearItems } from '@/modules/shared/utils/store'
import { CID } from '@/modules/shared/utils/store.types'
import { useNotificationStore } from '@/modules/shared/stores/notification-store'

///////////////////////////////////////////////////////////////////////////////
// Types
///////////////////////////////////////////////////////////////////////////////

type Guest = {
  _cid: CID
  _key: string
  id: string
  company: string
  company_id: string
  date_invited: string
  email: string
  invited_by: string
  name: string
}

///////////////////////////////////////////////////////////////////////////////
// Store
///////////////////////////////////////////////////////////////////////////////

type GuestMap = Map<CID, Guest>

export const useGuestStore = defineStore('investing/guestStore', () => {
  const route = useRoute()
  const notificationStore = useNotificationStore()
  const baseUrl = computed(() => `/${route.params.slug}`)

  const items = ref<GuestMap>(new Map())
  const guests = computed(() => Array.from(items.value.values()))

  type AddGuestPayload = {
    company_id: number | string
    email: string
    name: string
  }

  const addGuest = async (guest: AddGuestPayload) => {
    let url = `${baseUrl.value}/guest/add`

    await useFetch(url).post({ guest }).json<{}>()
    await fetchGuests()

    notificationStore.enqueue('success', 'Guest was successfully added')
  }

  const fetchGuests = async () => {
    let url = `${baseUrl.value}/guests`

    const { data, error } = await useFetch(url).get().json<{ data: Guest[] }>()

    if (error.value) {
      notificationStore.enqueue('error', 'Guests failed to load')
      return
    }

    clearItems(items)
    addItems(items, data.value.data)
  }

  const removeGuest = async (guest_id: number | string) => {
    let url = `${baseUrl.value}/guest/${guest_id}/remove`

    await useFetch(url).delete().json<{}>()
    await fetchGuests()

    notificationStore.enqueue('success', 'Guest was successfully removed')
  }

  return {
    items,
    guests,

    addGuest,
    fetchGuests,
    removeGuest,
  }
})
