<script setup lang="ts">
const one_zero = `100101010010100010100101010010101010010100101010100101010100
    0111101010101001000010101101000010110110101010101010000101011101010100110110
    1000010110110101010101010000101011101010100110010101001100010001001010011000
    0010111010101000010101001111010010101001001100000011100000011110010001001010
    1010010101010001111010101010010000101011010000101101101010101010100001010111
    0101010011011010000101101101010101010100001010111010101001100101010011000100
    0100101001100000101110101010000101010011110100101010010011000000111000000111
    1001000111000101010100100100011110010101010001011100101001010100101000101001
    0101001010101001010010101010010101010001111010101010010000101011010000101101
    1010101010101000010101110101010011011010000101101101010101010100001010111010
    1010011001010100110001000100101001100000101110101010000101010011110100101010
    0100110000001110000001111001000100101010100101010100011110101010100100001010
    1101000010110110101010101010000101011101010100110110100001011011010101010101
    0000101011101010100110010101001100010001001010011000001011101010100001010100
    1111010010101001001100000011100000011110010001110001010101001001000111100101
    0101000101110010100001010010010011010011110100010100100101010010100111101011
    0010100100001011010001101010010001111010101001010011001111010010010010011101
    0101010101010111101010000101010101000101011010010101010001111010101010010000
    1010110100001011011010101010101000010101110101010011011010000101001011101001
    0001011110101001001000101111000000110101011110101011010000101101101010101010
    1000010101110101010011011010000101101101010101010100010010010010110101010010
    1101110001100100001101010000001010100101011110011110010010111010010100111100
    1010100111100010100010101111010010100111101000101001001000100001111010101011
    1010000001010100101000101010100001010101001011010101010011101001000101110010
    1001010100000010110101011101001001101001011000111010010001010111010100100010
    1010111010101001101101000010110110101010101010000101011101010100110010101001
    1010111010101001101101000010110110101010101010000101011101010100110010101001
    `
</script>

<template>
  <main class="flex h-screen select-none items-start bg-gray-700">
    <aside
      class="overflow-none relative flex h-screen w-1/2 items-center justify-center overflow-hidden break-all p-2 text-sm tracking-[6px] text-gray-900"
    >
      <div class="absolute left-6 top-6 z-50">
        <img src="/venture360-logo-dark.svg" class="w-32" />
      </div>
      <!-- <div class="absolute top-8 left-8">ICON</div> -->
      <div class="absolute bottom-0 left-0 right-0 top-0 opacity-[0.1]">{{ one_zero }}</div>
      <svg
        width="32"
        height="900"
        viewBox="0 0 32 900"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="absolute right-0 top-0 h-full w-auto"
      >
        <path d="M32 0V900H0L32 0Z" fill="white" />
      </svg>
      <div>
        <slot name="icon"></slot>
      </div>
    </aside>
    <aside class="relative flex h-full w-1/2 items-center justify-center bg-white">
      <slot></slot>
    </aside>
  </main>
</template>
