<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { VButton, VButtonInvisible, VSection, VLoading } from '@/modules/shared/components'
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import { createTemplateable } from '../utils/create-templateable'
import state from '../state'
import { useTemplateStore } from '../stores/template-store'
import { rails_url } from '@/modules/shared/utils/rails'
import TemplateForm from '../components/template_form.vue'

///////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////

const route = useRoute()
const templateStore = useTemplateStore()
const templateable = createTemplateable(route)
const currentTemplate = computed(() => templateStore.item)
const ready = ref(false)

// set initial state of the template
state.template = {
  id: null,
  name: null,
  title: null,
  message: null,
  file_url: [],
  hellosign_template_id: null,
  hellosign_template_cache: {},
}

const redirect_url =
  templateable.type === 'group'
    ? `${rails_url(route.params.slug)}/group/documents`
    : `${rails_url(route.params.slug)}/funds/${templateable.id}/documents`

onMounted(async () => {
  await templateStore.retrieve(route.params.template_id)

  for (const key in state.template) {
    if (key === 'file_url') {
      state.template.file_url = [currentTemplate.value.file_url]
    } else {
      state.template[key] = currentTemplate.value[key]
    }
  }

  ready.value = true
})
</script>

<template>
  <TheLayout>
    <div class="h-full py-12 sm:mx-12" v-if="!ready">
      <div class="flex h-full items-center justify-center pb-32">
        <VLoading class="h-6 w-6" />
      </div>
    </div>
    <div v-else>
      <VSection>
        <div class="flex items-center justify-between">
          <div>
            <a :href="redirect_url">
              <VButtonInvisible>← Back</VButtonInvisible>
            </a>
          </div>
          <div>
            <a :href="redirect_url">
              <VButton>Cancel</VButton>
            </a>
          </div>
        </div>
      </VSection>

      <TemplateForm
        :template="state.template"
        :save_function="templateStore.duplicateTemplate"
        :redirect_url="redirect_url"
      />
    </div>
  </TheLayout>
</template>

<style>
#hellosign-embed-target .x-hellosign-embedded,
#hellosign-embed-target .x-hellosign-embedded .x-hellosign-embedded__iframe {
  @apply h-full w-full;
}
</style>
