<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="31" height="22" viewBox="0 0 31 22" fill="none">
    <path
      d="M2 11H29.2727"
      stroke="#1F2937"
      stroke-opacity="0.5"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2 1.90909H29.2727"
      stroke="#1F2937"
      stroke-opacity="0.5"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.7266 20.0909H29.272"
      stroke="#1F2937"
      stroke-opacity="0.5"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
