<script setup lang="ts">
import { computed } from 'vue'
import SubscriptionUsage from './subscription-usage.vue'

const props = withDefaults(
  defineProps<{
    status?: string
    subscription?: any
    type: any
  }>(),
  {
    status: null,
    subscription: null,
    type: null,
  },
)

const status = computed(() => (props.status ? props.status : props.subscription.status))

// prettier-ignore
const status_classes = {
  'active': 'bg-gray-700',
  'canceled': 'bg-gray-300',
  'incomplete': 'bg-gray-500',
  'incomplete_expired': 'bg-gray-300',
  'past_due': 'bg-red-500',
  'paused': 'bg-gray-300',
  'scheduled': 'bg-gray-600',
  'trialing': 'bg-[#3A83AF]/75',
  'unpaid': 'bg-red-500',
  'default': 'bg-gray-300',
}
</script>

<template>
  <div class="flex h-8 w-8 items-center justify-center rounded-md text-white" :class="status_classes[status]">
    <template v-if="type === 'one-time' && status === 'trialing'">
      <SubscriptionUsage :subscription="subscription" />
    </template>

    <template v-if="type === 'one-time'">
      <template v-if="['active', 'incomplete', 'past_due', 'unpaid'].includes(status)">
        <!-- check -->
        <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none">
          <path
            d="M20 6L9 17L4 12"
            stroke="currentColor"
            stroke-width="2.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </template>

      <template v-if="status === 'canceled'">
        <!-- slash-circle -->
        <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none">
          <path
            d="M4.93 4.93L19.07 19.07M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </template>

      <template v-if="status === 'incomplete_expired'">
        <!-- circle-minus -->
        <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none">
          <path
            d="M8 12H16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </template>
    </template>

    <template v-if="type === 'recurring'">
      <template v-if="['active', 'incomplete', 'past_due', 'trialing', 'unpaid'].includes(status)">
        <SubscriptionUsage :subscription="subscription" />
      </template>

      <template v-if="status === 'canceled'">
        <!-- slash-circle -->
        <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none">
          <path
            d="M4.93 4.93L19.07 19.07M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </template>

      <template v-if="status === 'incomplete_expired'">
        <!-- circle-minus -->
        <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none">
          <path
            d="M8 12H16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </template>

      <template v-if="status === 'paused'">
        <!-- pause-circle -->
        <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none">
          <path
            d="M9.5 15V9M14.5 15V9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </template>
    </template>

    <template v-if="type === 'scheduled'">
      <!-- placeholder -->
      <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none">
        <path
          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </template>
  </div>
</template>
