import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { createObjectFromArray } from '@/modules/shared/utils/create-object-from-array'
import { useAuthStore } from '../../auth/stores/auth-store'
import { useCustomerService } from '../services/customer-service'
import { useProductStore } from './product-store'

// Utils

const handle_error = (err: any) => {
  // TODO handle error
  console.error(err)
  return err
}

export const useCustomerStore = defineStore('customer', () => {
  const authStore = useAuthStore()
  const productStore = useProductStore()
  const route = useRoute()
  const service = useCustomerService({
    headers: {
      Authorization: `Bearer ${authStore.access_token}`,
    },
    route,
  })

  /////////////////////////////////////////////////////////////////////////////
  // STATE
  /////////////////////////////////////////////////////////////////////////////

  const item = ref(null)

  /////////////////////////////////////////////////////////////////////////////
  // GETTERS
  /////////////////////////////////////////////////////////////////////////////

  const customer = computed(() => item.value)

  const stripe_invoices = computed(() => createObjectFromArray(customer.value.stripe_invoices, 'stripe_invoice_id'))

  const payment_methods = computed(() =>
    createObjectFromArray(customer.value.stripe_payment_methods, 'stripe_payment_method_id'),
  )

  const subscription_schedules = computed(() =>
    createObjectFromArray(customer.value.stripe_subscription_schedules, 'stripe_subscription_schedule_id'),
  )

  const subscriptions = computed(() =>
    createObjectFromArray(customer.value.stripe_subscriptions, 'stripe_subscription_id'),
  )

  const stripe_subscriptions_for_group = computed(() => {
    const group_product_ids = productStore.group_products_ids
    // prettier-ignore
    const subscriptions = customer.value.stripe_subscriptions
      .filter((subscription: any) => {
        return group_product_ids.includes(subscription.items[0].price.product)
      })

    return subscriptions
  })

  const has_active_stripe_subscriptions_for_group = computed(() => {
    const group_product_ids = productStore.group_products_ids
    const subscriptions = customer.value.stripe_subscriptions
      .filter((subscription) => subscription.status == 'active')
      .filter((subscription: any) => {
        return group_product_ids.includes(subscription.items[0].price.product)
      })
    return subscriptions.length > 0
  })

  const has_incomplete_stripe_subscriptions_for_group = computed(() => {
    const group_product_ids = productStore.group_products_ids
    const subscriptions = customer.value.stripe_subscriptions
      .filter((subscription) => subscription.status == 'incomplete')
      .filter((subscription: any) => {
        return group_product_ids.includes(subscription.items[0].price.product)
      })
    return subscriptions.length > 0
  })

  const getters = {}

  /////////////////////////////////////////////////////////////////////////////
  // ACTIONS
  /////////////////////////////////////////////////////////////////////////////

  const actions = {
    cancel_subscription: async (subscription_id: string) => {
      const body = {
        customer_id: customer.value.stripe_customer_id,
        subscription_id,
      }

      const [err, data] = await service.cancel_subscription(null, body)

      if (err) return handle_error(err)

      await actions.retrieve()
    },

    change_subscription: async ({ subscription_id, stripe_price_id }) => {
      const body = {
        customer_id: customer.value.stripe_customer_id,
        subscription_id,
        stripe_price_id,
      }

      const [err, data] = await service.change_subscription(null, body)

      if (err) return handle_error(err)

      await actions.retrieve()
    },

    continue_subscription: async (subscription_id: string) => {
      const body = {
        customer_id: customer.value.stripe_customer_id,
        subscription_id,
      }

      const [err, data] = await service.continue_subscription(null, body)

      if (err) return handle_error(err)

      await actions.retrieve()
    },

    detach_payment_method: async (payment_method_id: string) => {
      const body = {
        customer_id: customer.value.stripe_customer_id,
        payment_method_id,
      }

      const [err, data] = await service.detach_payment_method(null, body)

      if (err) return handle_error(err)

      await actions.retrieve()
    },

    pay_subscription: async (subscription_id: any) => {
      const body = {
        customer_id: customer.value.stripe_customer_id,
        subscription_id,
      }

      const [err, data] = await service.pay_subscription(null, body)

      if (err) return handle_error(err)

      return data.data
    },

    retrieve: async () => {
      const [err, data] = await service.retrieve()

      if (err) return handle_error(err)

      item.value = data.data
    },

    retrieve_setup_intent: async () => {
      const body = {
        customer_id: customer.value.stripe_customer_id,
      }

      const [err, data] = await service.retrieve_setup_intent(null, body)

      if (err) return handle_error(err)

      return data.data
    },

    set_payment_method_as_default: async (payment_method_id: string, billable: any) => {
      const body = {
        billable_id: billable.id,
        billable_type: billable.type,
        customer_id: customer.value.stripe_customer_id,
        payment_method_id,
      }

      const [err, data] = await service.set_payment_method_as_default(null, body)

      if (err) return handle_error(err)

      await actions.retrieve()
    },
    sync_subscription: async (subscription_id: string) => {
      const body = {
        subscription_id: subscription_id,
        customer_id: customer.value.stripe_customer_id,
      }
      const [err, data] = await service.sync_subscription(null, body)

      if (err) return handle_error(err)

      await actions.retrieve()
    },
  }

  /////////////////////////////////////////////////////////////////////////////
  // RETURN
  /////////////////////////////////////////////////////////////////////////////

  return {
    // state
    item,
    customer,
    payment_methods,
    stripe_invoices,
    stripe_subscriptions_for_group,
    has_active_stripe_subscriptions_for_group,
    has_incomplete_stripe_subscriptions_for_group,
    subscription_schedules,
    subscriptions,
    ...actions,
    ...getters,
  }
})
