<template>
  <svg viewBox="0 0 24 24" fill="none">
    <path
      d="M17 20.6622C19.989 18.9332 22 15.7015 22 12.0001C22 8.29866 19.989 5.06694 17 3.33789"
      stroke="currentColor"
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
      vector-effect="non-scaling-stroke"
    />
  </svg>
</template>
