import { createService } from '@/modules/shared/utils/service'

export const useCustomerService = createService({
  base_url: `${import.meta.env.VITE_VENTURE360_API_URL}/:slug/billing/customer`,
  // prettier-ignore
  headers: {
    'Accept': 'application/json',
    'Authorization': `Bearer `,
    'Content-Type': 'application/json',
  },
  endpoints: [
    {
      method: 'GET',
      name: 'retrieve',
      url: '/:id',
    },
    {
      method: 'POST',
      name: 'list_subscriptions',
      url: '/:customer_id/subscriptions',
    },
    ///////////////////////////////////////////////////////////////////////////
    {
      method: 'POST',
      name: 'add_subscription',
      url: '/:customer_id/subscription/add',
    },
    {
      method: 'POST',
      name: 'cancel_subscription',
      url: '/:customer_id/subscription/:subscription_id/cancel',
    },
    {
      method: 'POST',
      name: 'change_subscription',
      url: '/:customer_id/subscription/:subscription_id/change',
    },
    {
      method: 'POST',
      name: 'continue_subscription',
      url: '/:customer_id/subscription/:subscription_id/continue',
    },
    {
      method: 'POST',
      name: 'detach_payment_method',
      url: '/:customer_id/payment-method/:payment_method_id/detach',
    },
    {
      method: 'POST',
      name: 'pay_subscription',
      url: '/:customer_id/subscription/:subscription_id/pay',
    },
    {
      method: 'GET',
      name: 'retrieve_setup_intent',
      url: '/:customer_id/setup-intent/retrieve',
    },
    {
      method: 'POST',
      name: 'set_payment_method_as_default',
      url: '/:customer_id/payment-method/:payment_method_id/set-as-default',
    },
    {
      method: 'PUT',
      name: 'sync_subscription',
      url: '/:customer_id/subscription/:subscription_id/sync',
    },
  ],
})
