import { createService } from '@/modules/shared/utils/service'

export const useProductService = createService({
  base_url: `${import.meta.env.VITE_VENTURE360_API_URL}/:slug/billing/product`,
  // prettier-ignore
  headers: {
    'Accept': 'application/json',
    'Authorization': `Bearer `,
    'Content-Type': 'application/json',
  },
  endpoints: [
    {
      method: 'GET',
      name: 'list',
      url: 's', // looks strange, but pluralizes the base_url
    },
  ],
})
