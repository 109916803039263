import Big from 'big.js'
import { assertNumeric } from '../math'

export function tvpi(args: { called: number; current_value: number; distributed: number }) {
  const { called, current_value, distributed } = args

  assertNumeric(called)
  assertNumeric(current_value)
  assertNumeric(distributed)

  if (called === 0) {
    return 0.0
  }

  // RVPI + DPI
  // prettier-ignore
  return Big(current_value)
    .plus(distributed)
    .div(called)
    .toNumber()
}
