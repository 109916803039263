import EnableTwoFactor from '../pages/enable-two-factor.vue'
import Login from '../pages/login.vue'
import Verify from '../pages/verify.vue'
import RequestResetPassword from '../pages/request-reset-password.vue'
import ResetPassword from '../pages/reset-password.vue'
import RequestTwoFactorReset from '../pages/request-two-factor-reset.vue'
import ResetTwoFactor from '../pages/reset-two-factor.vue'
import { useAuthStore } from '../stores/auth-store'
import { handleLogout, redirectToAuthSubdomain, requireAuthentication, resetAuthStore } from './middleware'

///////////////////////////////////////////////////////////////////////////////
// Routes
///////////////////////////////////////////////////////////////////////////////

// rails logout:
//   - reset session
//   - reset active token
//   - redirect to app. to continue logout
//
// app logout:
//   - check if active token is valid:
//     - yes: redirect to *. to reset session and active token
//     - no: redirect to login

export default [
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: [resetAuthStore, redirectToAuthSubdomain],
  },
  {
    path: '/enable-two-factor',
    name: 'auth.enable-two-factor',
    component: EnableTwoFactor,
    beforeEnter: requireAuthentication,
  },
  {
    path: '/verify',
    name: 'auth.verify',
    component: Verify,
    beforeEnter: requireAuthentication,
  },
  {
    path: '/request-password-reset',
    name: 'request-password-reset',
    component: RequestResetPassword,
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: ResetPassword,
  },
  {
    path: '/request-two-factor-reset',
    name: 'request-two-factor-reset',
    component: RequestTwoFactorReset,
  },
  {
    path: '/reset-two-factor',
    name: 'reset-two-factor',
    component: ResetTwoFactor,
  },
  { path: '/:pathMatch(.*)*', redirect: '/' },
  { path: '/', redirect: '/login' },
  {
    name: 'logout',
    path: '/logout',
    beforeEnter: [
      redirectToAuthSubdomain,
      async (_to, _from, next) => {
        const authStore = useAuthStore()
        const hasValidToken = await authStore.validateToken()

        if (hasValidToken) {
          window.location.href = `${import.meta.env.VITE_VENTURE360_SESSION_URL}/logout`
          return
        }

        next({ name: 'login' })
      },
    ],
  },
]
