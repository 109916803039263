<script setup lang="ts">
import { VBadge, VButton, VIcon, VLink, VTable } from '@/modules/shared/components'
import { capitalize, get } from 'lodash'
import { rails_url } from '@/modules/shared/utils/rails'
import { useI18n } from 'vue-i18n'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'

const { t } = useI18n()
const route = useRoute()
const props = defineProps<{
  invoices: any[]
  investable_id: string
  investable_type: string
  skeleton: boolean
}>()

const selectedInvoiceType = ref('all')
const selectInvoiceType = (type: string) => {
  selectedInvoiceType.value = type
}

const current_invoices = computed(() =>
  props.invoices.filter((invoice) => {
    if (selectedInvoiceType.value === 'all') {
      return true
    }

    if (selectedInvoiceType.value === 'outstanding') {
      return !invoice.marked_as_paid
    }

    if (selectedInvoiceType.value === 'paid') {
      return invoice.marked_as_paid
    }
  }),
)
</script>

<template>
  <div class="mb-3 flex items-center space-x-1.5">
    <VButton :active="selectedInvoiceType === 'all'" class="w-32" @click="selectInvoiceType('all')">
      <span>{{ capitalize(t('shared.all')) }}</span>
    </VButton>
    <VButton :active="selectedInvoiceType === 'outstanding'" class="w-32" @click="selectInvoiceType('outstanding')">
      <span>{{ capitalize(t('shared.outstanding')) }}</span>
    </VButton>
    <VButton :active="selectedInvoiceType === 'paid'" class="w-32" @click="selectInvoiceType('paid')">
      <span>{{ capitalize(t('shared.paid')) }}</span>
    </VButton>
  </div>
  <VTable
    :columns="[
      {
        key: 'name',
        name: capitalize(t('shared.name')),
        type: 'string',
        align: 'left',
        fixed: true,
        is_visible: true,
      },
      {
        key: 'investor',
        name: capitalize(t('shared.investor')),
        type: 'string',
        align: 'left',
        is_visible: route.name === 'investing.individual-invoices',
      },
      {
        key: 'date',
        name: capitalize(t('shared.due date')),
        type: 'date',
        sorted: true,
        align: 'left',
        is_visible: true,
      },
      {
        key: 'status',
        name: capitalize(t('shared.status')),
        type: 'string',
        align: 'left',
        is_visible: true,
      },
      {
        key: 'amount',
        name: capitalize(t('shared.total amount due')),
        type: 'currency',
        align: 'right',
        is_visible: true,
        aggregate: 'sum',
      },
    ]"
    :items="current_invoices"
    :name="`${investable_type}-${investable_id}-invoices`"
    :skeleton="skeleton"
    :slots="['investor', 'invoice_number', 'name', 'status']"
  >
    <template #investor="{ item }">
      <RouterLink
        class="text-[#3b88af] underline decoration-[#3b88af]/50 hover:text-gray-900 hover:decoration-gray-900/50"
        :to="
          get(item, 'investor.ruby_type') === 'fundingentity'
            ? { name: 'investing.other-entity.overview', params: { other_entity_id: get(item, 'investor.id') } }
            : { name: 'investing.individual-overview', params: { individual_id: get(item, 'investor.id') } }
        "
      >
        {{ get(item, 'investor.name') }}
      </RouterLink>
    </template>
    <template #invoice_number="{ item }">
      <a class="hyperlink" :href="`${rails_url()}/invoices/${get(item, 'id')}`"> #{{ get(item, 'id') }} </a>
    </template>
    <template #name="{ item }">
      <a class="hyperlink" :href="`${rails_url()}/invoices/${get(item, 'id')}`">
        {{ get(item, 'name') }}
      </a>
    </template>
    <template #status="{ item }">
      {{ item.marked_as_paid ? 'paid' : 'outstanding' }}
    </template>
  </VTable>
</template>
