<script setup lang="ts">
import { VBadge } from '@/modules/shared/components'
import { useProductStore } from '../stores/product-store'
import { calculate_graduated_pricing } from '../stores/utils'
import SubscriptionStatus from './subscription-status.vue'
import { to_currency, to_date } from '../utils'

const productStore = useProductStore()

///////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////

const props = withDefaults(
  defineProps<{
    id?: string
    subscription_schedule: any
  }>(),
  {
    id: null,
    subscription_schedule: null,
  },
)

const transform_subscription_schedule = (subscription_schedule: any) => {
  // select
  const phase = subscription_schedule.phases[0]
  const item = phase.items[0]
  const price = productStore.prices[item.price]
  const product = productStore.products[price.stripe_product_id]

  // calcualtions
  // prettier-ignore
  const amount = price.billing_scheme === 'tiered' ? calculate_graduated_pricing(item.quantity, price.tiers) : price.unit_amount
  const product_name = price.billing_scheme === 'tiered' ? `${product.name} × ${item.quantity}` : product.name

  return {
    price: {
      amount,
      currency: price.currency.toUpperCase(),
      recurring: {
        interval: price.recurring_interval,
      },
      type: price.type,
    },
    product: {
      name: product_name,
    },
    quantity: item.quantity,
    start_date: phase.start_date,
  }
}

const data = transform_subscription_schedule(props.subscription_schedule)
</script>

<template>
  <div class="flex items-center justify-start space-x-3">
    <aside>
      <SubscriptionStatus status="scheduled" type="scheduled" />
    </aside>
    <div>
      <div class="flex items-center space-x-3">
        <h1 class="mb-0 flex items-center text-xl text-gray-800">
          {{ data.product.name }}
        </h1>
        <VBadge color="gray" size="xxs">scheduled</VBadge>
      </div>
      <div class="mb-0 items-center md:flex md:space-x-2">
        <div>
          {{ to_currency(data.price.amount, data.price.currency.toUpperCase()) }}
          {{ data.price.currency.toUpperCase() }} /
          {{ data.price.recurring.interval }}
        </div>
        <div class="hidden text-gray-300 md:block">&#x2022;</div>
        <div>Plan starts on {{ to_date(data.start_date) }}</div>
      </div>
    </div>
  </div>
</template>
