<script setup lang="ts">
import { rails_url } from '@/modules/shared/utils/rails'
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()

const props = defineProps<{
  fallback?: string | object
  to?: string | object
}>()
const prevUrl = ref({})

onMounted(() => {
  if (router.options.history.state.position === 0) {
    prevUrl.value = props.fallback || rails_url()
  } else {
    prevUrl.value = {}
  }
})
</script>

<template>
  <template v-if="to">
    <a :href="to" v-if="typeof to === 'string'"><slot></slot></a>
    <RouterLink :to="to" v-else><slot></slot></RouterLink>
  </template>
  <template v-else-if="router.options.history.state.position === 0">
    <a :href="prevUrl" v-if="typeof prevUrl === 'string'"><slot></slot></a>
    <RouterLink :to="prevUrl" v-else><slot></slot></RouterLink>
  </template>
  <a @click="$router.back()" v-else><slot></slot></a>
</template>
