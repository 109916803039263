import Big from 'big.js'

export type Money = {
  amount: string
  currency: string
  date: string
  common_amount?: string
  common_currency?: string
}

const initialMoney: Money = {
  amount: '0.0',
  currency: 'USD',
  common_amount: '0.0',
  common_currency: 'USD',
  date: null,
}

const sum = (moneys: Money[]): Money => {
  if (!moneys.length) return null
  if (moneys.some((money) => !validMoney(money))) {
    throw new Error('Invalid argument: values must be an array of valid money type')
  }

  const amount = moneys.reduce((acc: Big, cur: Money) => acc.plus(cur.amount), Big(0)).toString()
  const common_amount = moneys
    .reduce((acc: Big, cur: Money) => acc.plus(cur.common_amount || Big(0)), Big(0))
    .toString()
  const currency = moneys[0]?.currency
  const common_currency = moneys[0]?.common_currency || 'USD'
  const date = moneys[0]?.date

  return {
    amount,
    common_amount,
    currency,
    common_currency,
    date,
  }
}

const times = (money: Money, multiplier: number): Money => {
  if (!validMoney(money)) throw new Error('Invalid argument: first value must be a valid money type')
  const amount = new Big(money.amount).times(multiplier).toString()
  const common_amount = new Big(money.common_amount).times(multiplier).toString()
  const currency = money.currency
  const common_currency = money.common_currency || 'USD'
  const date = money.date

  return {
    amount,
    common_amount,
    currency,
    common_currency,
    date,
  }
}

const minus = (minuend: Money, subtrahend: Money): Money => {
  if (!validMoney(minuend)) throw new Error('Invalid argument: minuend must be a valid money type')
  if (!validMoney(subtrahend)) throw new Error('Invalid argument: subtrahend must be a valid money type')

  const amount = new Big(minuend.amount).minus(subtrahend.amount).toString()
  const common_amount = new Big(minuend.common_amount).minus(subtrahend.common_amount).toString()
  const currency = minuend.currency
  const common_currency = minuend.common_currency || 'USD'
  const date = minuend.date

  return {
    amount,
    common_amount,
    currency,
    common_currency,
    date,
  }
}

const difference = (moneys: Money[]): Money => {
  if (!moneys.length) return null
  if (moneys.some((money) => !validMoney(money))) {
    throw new Error('Invalid argument: values must be an array of valid money type')
  }
  const minuend_money = moneys[0]
  const subtrahends_money = moneys.slice(1)
  const subtrahend_sum = sum(subtrahends_money)

  const amount = new Big(minuend_money.amount).minus(subtrahend_sum.amount).toString()
  const common_amount = new Big(minuend_money.common_amount).minus(subtrahend_sum.common_amount).toString()
  const currency = minuend_money.currency
  const common_currency = minuend_money.common_currency || 'USD'
  const date = minuend_money.date

  return {
    amount,
    common_amount,
    currency,
    common_currency,
    date,
  }
}

const toNumber = (money: Money, useCommon?: boolean) =>
  useCommon ? Big(money.common_amount).toNumber() : Big(money.amount).toNumber()

const validMoney = (value: unknown): value is Money => {
  return value && typeof value === 'object' && 'amount' in value && 'currency' in value && 'date' in value
}

const toMoney = (
  raw_amount: string | number,
  currency: string,
  raw_common_amount?: string | number,
  common_currency?: string,
  date?: string,
): Money => {
  let amount = '0.0'
  if (typeof raw_amount === 'string') amount = new Big(raw_amount).toNumber().toFixed(2)
  if (typeof raw_amount === 'number') amount = raw_amount.toFixed(2)

  let common_amount = amount
  if (raw_common_amount) {
    if (typeof raw_common_amount === 'string') amount = new Big(raw_common_amount).toNumber().toFixed(2)
    if (typeof raw_common_amount === 'number') amount = raw_common_amount.toFixed(2)
  }

  return {
    amount,
    currency,
    common_amount,
    common_currency: common_currency ? common_currency : currency,
    date: null,
  }
}

// prettier-ignore
export {
  difference,
  initialMoney,
  minus,
  times,
  toMoney,
  toNumber,
  sum,
  validMoney,
}
