<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { capitalize } from 'lodash'
import { Ref, ref, onMounted, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import IndividualHeader from '@/modules/investing/components/individual-header.vue'
import IndividualNav from '@/modules/investing/components/individual-nav.vue'
import {
  VTable,
  VButton,
  VModal,
  VSection,
  VTextField,
  VIcon,
  RouterLinkBack,
  VButtonInvisible,
} from '@/modules/shared/components'
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import { useModal } from '@/modules/shared/composables/use-modal'
import VSelect from '../components/v-select.vue'
import { useBeneficiaryStore } from '../stores/beneficiary-store'
import { useAuthStore } from '@/modules/auth/stores/auth-store'
import { useRoute } from 'vue-router'

const { t } = useI18n()
const modal = useModal()
const skeleton = ref(true)
const route = useRoute()
const authStore = useAuthStore()
const beneficiaryStore = useBeneficiaryStore()

const can_edit = computed(() => authStore.current_user?.investor_id?.toString() === route.params.individual_id)

///////////////////////////////////////////////////////////////////////////////
//
///////////////////////////////////////////////////////////////////////////////

enum BeneficiaryTypes {
  Individual = 'individual',
  Organization = 'organization',
  Trust = 'trust',
}

interface Beneficiary {
  birthdate: string | null
  name: string | null
  allocation_percentage: string | null
  relationship: string | null
  tax_id: string | null
  type: BeneficiaryTypes
}

const initialState: Beneficiary = {
  allocation_percentage: null,
  birthdate: null,
  name: '',
  relationship: null,
  tax_id: null,
  type: BeneficiaryTypes.Individual,
}

const loading = ref(false)
const beneficiary: Ref<Beneficiary> = ref({ ...initialState })

const rules = {
  allocation_percentage: { required },
  name: { required },
  type: { required },
}

const v$ = useVuelidate(rules, beneficiary, { $lazy: true })

async function addBeneficiary() {
  if (!can_edit.value) return
  const isValid = await v$.value.$validate()
  if (!isValid) return

  const payload = {
    ...beneficiary.value,
    allocation_percentage: parseFloat(beneficiary.value.allocation_percentage) / 100,
  }

  loading.value = true
  const res = await beneficiaryStore.addBeneficiary(payload)
  loading.value = false
  if (res?.error.value) return
  modal.close()
}

const removeBeneficiary = async (beneficiary_id: number) => {
  if (!can_edit.value) return
  if (!window.confirm('Are you sure?')) return
  await beneficiaryStore.removeBeneficiary(beneficiary_id)
}

const showBeneficiaryForm = () => {
  v$.value.$reset()
  beneficiary.value = { ...initialState }
  modal.open()
}

onMounted(async () => {
  await beneficiaryStore.fetchBeneficiaries()
  skeleton.value = false
})
</script>

<template>
  <TheLayout>
    <div class="-mt-8 mb-6 flex items-center justify-between">
      <div>
        <RouterLinkBack class="text-lg" :to="{ name: 'investing.individual.account' }">
          <VButtonInvisible>← {{ capitalize(t('shared.back')) }}</VButtonInvisible>
        </RouterLinkBack>
      </div>
      <VButton :click="showBeneficiaryForm" size="md" variant="v-blue" v-if="can_edit">
        <div class="flex items-center space-x-2">
          <div><VIcon name="plus" /></div>
          <div>{{ capitalize(t('shared.add beneficiary', 0)) }}</div>
        </div>
      </VButton>
    </div>

    <VSection :label="capitalize(t('shared.beneficiary', 0))">
      <VTable
        :columns="[
          {
            key: 'name',
            name: capitalize(t('shared.name')),
            sorted: true,
            type: 'string',
            align: 'left',
            fixed: true,
            is_visible: true,
          },
          {
            key: 'allocation_percentage',
            name: capitalize(t('shared.allocation percent')),
            type: 'percent',
            align: 'center',
            aggregate: 'sum',
            is_visible: true,
          },
          {
            key: 'type',
            name: capitalize(t('shared.type')),
            type: 'string',
            align: 'center',
            is_visible: true,
          },
          {
            key: 'birthdate',
            name: capitalize(t('shared.birthdate')),
            type: 'date',
            align: 'center',
            is_visible: true,
          },
          {
            key: 'relationship',
            name: capitalize(t('shared.relationship')),
            type: 'string',
            align: 'center',
            is_visible: true,
          },
          {
            key: 'tax_id',
            name: t('shared.SSN / Tax ID'),
            type: 'string',
            align: 'center',
            is_visible: true,
          },
          {
            key: 'actions',
            name: '',
            type: 'actions',
            align: 'right',
            is_visible: true,
          },
        ]"
        :items="beneficiaryStore.beneficiaries"
        :skeleton="skeleton"
        name="individual-beneficiaries"
        :slots="['actions']"
      >
        <template #actions="{ item }">
          <div class="flex space-x-1">
            <VButton :click="() => removeBeneficiary(item.id)" size="xs" v-if="can_edit">
              {{ capitalize(t('shared.remove')) }}
            </VButton>
          </div>
        </template>
      </VTable>

      <VModal :modalStore="modal">
        <template #main>
          <VSection label="Add beneficiary">
            <div class="my-6 border-[1px] bg-[#3982AF]/10 p-2 text-sm text-[#3982AF] ring-[#3982AF]/20">
              <span class="font-bold">DISCLAIMER:</span>
              If married, living in a community property state (for example AK, AZ, CA, ID, LA, NV, NM, TX, WA, or WI),
              and want to designate a primary beneficiary other than your spouse, your spouse must agree in writing to
              your designation and you must submit a physical copy of this form by mail or fax. You should consult your
              legal/tax advisor when completing this form, as there may be tax and/or legal consequences to your
              designation. You have the option to list one or more persons (including your estate or a trust, as
              applicable). If designating multiple beneficiaries, indicate the percentage share each should receive,
              ensuring the total of each adds up to 100%. Designations are effective upon hitting submit on this form,
              and, unless otherwise specified, cancel all previous beneficiary designations on file.
            </div>
            <form @submit.prevent="addBeneficiary">
              <div class="mt-6 space-y-6">
                <VSelect
                  v-model="beneficiary.type"
                  :label="capitalize(t('shared.type'))"
                  :options="[
                    { label: 'Individual', value: 'individual' },
                    { label: 'Organization', value: 'organization' },
                    { label: 'Trust', value: 'trust' },
                  ]"
                  property="type"
                  :required="true"
                  :v$="v$"
                />
                <VTextField
                  v-model="beneficiary.name"
                  :label="capitalize(t('shared.name'))"
                  property="name"
                  ref="autofocus"
                  :required="true"
                  :v$="v$"
                />
                <VTextField
                  v-model="beneficiary.birthdate"
                  :label="capitalize(t('shared.birthdate'))"
                  type="date"
                  property="birthdate"
                  :v$="v$"
                />
                <VTextField
                  v-model="beneficiary.relationship"
                  :label="capitalize(t('shared.relationship'))"
                  property="relationship"
                  :v$="v$"
                />
                <VTextField
                  v-model="beneficiary.allocation_percentage"
                  :label="capitalize(t('shared.allocation percent'))"
                  placeholder="100%"
                  property="allocation_percentage"
                  :required="true"
                  :v$="v$"
                />
                <VTextField v-model="beneficiary.tax_id" :label="t('shared.SSN / Tax ID')" property="ssn" :v$="v$" />
              </div>
            </form>
          </VSection>
        </template>
        <template #footer>
          <div class="flex items-center justify-between space-x-3">
            <VButton :click="modal.close" size="lg">{{ capitalize(t('shared.close')) }}</VButton>
            <VButton :click="addBeneficiary" :loading="loading" class="w-full" size="lg" variant="primary">
              <span>{{ capitalize(t('shared.add beneficiary')) }}</span>
            </VButton>
          </div>
        </template>
      </VModal>
    </VSection>
  </TheLayout>
</template>
