<script setup lang="ts">
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { computed, ref } from 'vue'
import { Doughnut } from 'vue-chartjs'
import { VSkeletonBar } from '@/modules/shared/components'
import { Money } from '@/modules/shared/utils/money'
import { format } from '@/modules/shared/utils/v-table'
import { colors } from '../utils/chart'

ChartJS.register(ArcElement, Tooltip, Legend)

const props = defineProps<{
  items: { id: string | number; name: string; initial_value: Money; current_value: Money; roi: number }[]
  total_roi: number
  skeleton: boolean
}>()

const selected_item = ref(null)

const total_roi = computed(() => props.total_roi)

// Chart
const chart = ref(null)
const labels = computed(() => props.items.map((item) => item.name))
const chartData = computed(() => {
  const roi_values = props.items.map((item) => item.roi)
  return {
    labels: labels.value,
    datasets: [
      {
        label: 'ROI',
        data: roi_values.length === 0 ? [100] : roi_values,
        backgroundColor: colors,
        hoverOffset: 10,
      },
    ],
  }
})
const options = computed(() => {
  return {
    layout: {
      padding: 5,
    },
    responsive: true,
    maintainAspectRatio: false,
    cutout: '75%',
    radius: '100%',
    animation: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    onHover: (event, chartElement) => {
      if (chartElement.length > 0) {
        const index = chartElement[0].index
        selected_item.value = props.items[index]
      } else {
        selected_item.value = null
      }
    },
  }
})
</script>

<template>
  <div>
    <div class="text-center text-lg font-medium sm:-mb-5 sm:block">
      <VSkeletonBar v-if="skeleton" />
      <template v-else>{{ selected_item?.name || 'All' }}</template>
    </div>
    <div class="relative mx-auto w-[70%] sm:-mb-10 sm:w-[220px]">
      <Doughnut :data="chartData" :options="options" ref="chart" />
      <div class="pointer-events-none absolute inset-0 flex flex-col items-center justify-center">
        <div class="text-sm font-medium text-gray-700">ROI</div>
        <VSkeletonBar v-if="skeleton" />
        <div class="font-extrabold text-gray-700" v-else>{{ format(selected_item?.roi || total_roi, 'percent') }}</div>
      </div>
    </div>
  </div>
</template>
