<template>
  <svg viewBox="0 0 24 24" fill="none">
    <path
      d="M4 12H20M20 12L14 6M20 12L14 18"
      stroke="currentColor"
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
      vector-effect="non-scaling-stroke"
    />
  </svg>
</template>
