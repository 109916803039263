import Big from 'big.js'

type Numeric = Big | number

function assertNumeric(value: any): asserts value is Numeric {
  if (!validNumeric(value)) {
    throw new Error('Invalid argument: arguments must be numbers')
  }
}

function createOperation(operator: (a: Big, b: Big) => Big) {
  return (a: Numeric, b: Numeric) => {
    assertNumeric(a)
    assertNumeric(b)

    return operator(Big(a), Big(b)).toNumber()
  }
}

function validNumeric(value: any): boolean {
  return (typeof value === 'number' && value === value) || value instanceof Big
}

///////////////////////////////////////////////////////////////////////////////
// Basic arithmetic operations
///////////////////////////////////////////////////////////////////////////////

const add = createOperation((a, b) => a.plus(b))

const subtract = createOperation((a, b) => a.minus(b))

const multiply = createOperation((a, b) => a.times(b))

const divide = createOperation((a, b) => {
  if (b.eq(0)) {
    throw new Error('Invalid argument: cannot divide by zero')
  }

  return a.div(b)
})

// prettier-ignore
export {
  assertNumeric,
  createOperation,
  validNumeric,
  add,
  subtract,
  multiply,
  divide
}
