<script setup lang="ts">
import { computed } from 'vue'
import { VButtonInvisible } from '@/modules/shared/components'

interface StripeResource {
  [resource: string]: string
}

const props = withDefaults(
  defineProps<{
    livemode?: boolean
    resource: StripeResource
  }>(),
  {
    livemode: true,
  },
)

const stripeLink = computed(() => {
  const { resource, livemode } = props
  const base_url = 'https://dashboard.stripe.com'
  const mode = livemode ? '' : 'test/'
  const resource_type = Object.keys(resource)[0]
  const resource_id = resource[resource_type]

  return `${base_url}/${mode}${resource_type}s/${resource_id}`
})
</script>

<template>
  <a :href="stripeLink" target="_blank" rel="noopener">
    <VButtonInvisible>View in Stripe ↗</VButtonInvisible>
  </a>
</template>
