<script setup lang="ts">
import { computed, ref, watch } from 'vue'

const props = withDefaults(
  defineProps<{
    modelValue: any
    error?: string
    label?: string
    name?: string
    options: Array<{ label: string; value: any }>
    property?: string
    size?: string
    v$?: any
  }>(),
  {
    size: 'md',
    v$: {},
  },
)

const error = computed(() => props.error || props.v$[props.property || props.name]?.$errors[0]?.$message)
const selected_option = ref(props.modelValue)

watch(
  () => props.modelValue,
  (newVal) => {
    selected_option.value = newVal
  },
)

const emit = defineEmits(['update:modelValue'])

const label_base_class = 'font-medium text-gray-700 mb-2'
const label_sizes: any = {
  sm: 'text-xs',
  md: 'text-sm',
  lg: 'text-md',
}

const label_classes = computed(() => {
  let comb_class = [label_base_class, label_sizes[props.size]]

  return comb_class
})
</script>

<template>
  <div class="flex flex-col items-baseline gap-1" v-bind="$attrs">
    <div :class="label_classes" class="w-fit">{{ label }}</div>
    <div class="flex gap-3">
      <div class="flex items-center gap-1" v-for="option in options" :key="option.value">
        <input
          v-model="selected_option"
          type="radio"
          :value="option.value"
          @change="emit('update:modelValue', option.value)"
        />
        <div class="cursor-pointer text-sm" @click="emit('update:modelValue', option.value)">{{ option.label }}</div>
      </div>
    </div>
    <div v-if="error" class="mt-1 text-sm text-red-500">{{ error }}</div>
  </div>
</template>
