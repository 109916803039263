<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router'
import { useOtherEntityStore } from '../stores/other-entity-store'
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import { ref, onMounted } from 'vue'
import OtherEntityForm from '../components/other-entity-form.vue'
import { useExtendedI18n } from '@/i18n'
import { capitalize } from 'lodash'
import { RouterLinkBack, VButtonInvisible } from '@/modules/shared/components'
import { useAuthStore } from '@/modules/auth/stores/auth-store'

const { t } = useExtendedI18n()

const route = useRoute()
const router = useRouter()
const authStore = useAuthStore()
const otherEntityStore = useOtherEntityStore()
const is_ready = ref(false)
const other_entity = ref(null)

onMounted(async () => {
  if (!authStore.is_site_or_group_admin) router.push({ name: 'investing.other-entities' })

  await Promise.all([otherEntityStore.fetchOtherEntity(route.params.other_entity_id)])
  other_entity.value = otherEntityStore.items.get(`funding-entity:${route.params.other_entity_id}`)

  is_ready.value = true
})
</script>

<template>
  <TheLayout>
    <div class="-mt-6 mb-6 flex items-center justify-between sm:-mt-8">
      <div>
        <RouterLinkBack :fallback="{ name: 'investing.other-entity.overview' }">
          <VButtonInvisible>← {{ capitalize(t('shared.back')) }}</VButtonInvisible>
        </RouterLinkBack>
      </div>
    </div>
    <div class="mb-5 text-lg">Edit Associated Entity</div>
    <OtherEntityForm :other_entity="other_entity" v-if="is_ready" />
  </TheLayout>
</template>
