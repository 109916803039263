import { format } from 'date-fns'
import { flatten } from 'flat'
import { parse, unflattenKey } from './v-table'
import { get } from 'lodash'

const sortCsvItems = (items, columns) => {
  // sort items
  const order = 1
  const column = columns[0]
  return [...items].sort((a, b) => {
    const aValue = parse(get(a, column.key), column.type)
    const bValue = parse(get(b, column.key), column.type)

    if (aValue < bValue) return -1 * order
    if (aValue > bValue) return 1 * order
    return 0
  })
}

const export_csv = (filename: string, items: any[], columns: any[], n, sort: boolean = true) => {
  const date = format(new Date(), 'yyyy-MM-dd')

  if (sort) items = sortCsvItems(items, columns)

  let data = ''
  data += columns.map((column) => column.name).join(',')
  data += '\n'

  items
    .map((item) => flatten(item))
    .forEach((item) => {
      data += columns
        .map((column) => {
          const key = column.csv_key || column.key
          let value = item[key]
          if (['currency', 'percent'].includes(column.type) && n)
            value = n(unflattenKey(item, column.key) || 0, column.type)
          return `"${value || ''}"`
        })
        .join(',')
      data += '\n'
    })

  const csvBlob = new Blob([data], { type: 'text/csv' })

  const downloadLink = document.createElement('a')
  downloadLink.href = URL.createObjectURL(csvBlob)
  downloadLink.download = `${filename}-${date}.csv`
  downloadLink.click()
}

export { export_csv, sortCsvItems }
