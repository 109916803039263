<script setup lang="ts">
import { computed } from 'vue'
import { useExtendedI18n } from '@/i18n'
import { VBarChart } from '@/modules/shared/components'
import { divide } from '@/modules/shared/utils/math'
import { Money, toNumber } from '@/modules/shared/utils/money'
import { useRoute } from 'vue-router'
import { useAuthStore } from '@/modules/auth/stores/auth-store'

///////////////////////////////////////////////////////////////////////////////
// Utils
///////////////////////////////////////////////////////////////////////////////

function capitalize(str: string) {
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`
}

const toNumberOrZero = (money: Money) => {
  if (!money) return 0.0
  return toNumber(money)
}

///////////////////////////////////////////////////////////////////////////////

const props = defineProps<{
  committed: Money
  committed_remaining: Money
  called_received: Money
  called_received_capital: Money
  called_received_management_fees: Money
  called_received_other_fees: Money
  called_pending: Money
  called_pending_capital: Money
  called_pending_management_fees: Money
  called_pending_other_fees: Money
  skeleton?: boolean
}>()

const { n, t } = useExtendedI18n()
const route = useRoute()
const authStore = useAuthStore()

const ratio = (numerator: Money, denominator: Money) => {
  if (!numerator || !denominator) return 0.0
  if (toNumber(denominator) === 0.0) return 0.0
  return divide(toNumber(numerator), toNumber(denominator))
}

const denominator = computed<Money>(() => {
  const sorted_values = [
    {
      value: props.committed,
      number: toNumberOrZero(props.committed),
    },
    {
      value: props.called_received,
      number: toNumberOrZero(props.called_received),
    },
    {
      value: props.called_pending,
      number: toNumberOrZero(props.called_pending),
    },
  ].sort((a, b) => b.number - a.number)

  return sorted_values[0].value
})

const isForInvestor = computed(() => {
  if (route.name === 'dashboard') {
    return !authStore.is_site_or_group_admin
  } else {
    return ['investing.individual-overview', 'investing.other-entity.overview', 'investing.calls'].includes(
      route.name as string,
    )
  }
})
</script>

<template>
  <div class="rounded-lg border border-gray-200 bg-white px-0 py-8 dark:border-[#161618] dark:bg-[#1B1B1F]">
    <VBarChart
      :segments="[
        {
          label: capitalize(t('shared.committed')),
          value: n(committed, 'currency'),
          style: 'bg-[#7ABCBF]',
          ratio: ratio(committed, denominator),
        },
        {
          label: isForInvestor ? capitalize(t('shared.total called')) : capitalize(t('shared.received')),
          value: n(called_received, 'currency'),
          style: 'bg-[#5C8DB4]',
          ratio: ratio(called_received, denominator),
          segments: [
            {
              label: capitalize(t('shared.capital')),
              value: n(called_received_capital, 'currency'),
              style: 'bg-[#5C8DB4]/50',
              ratio: ratio(called_received_capital, called_received),
            },
            {
              label: capitalize(t('shared.management fee', 0)),
              value: n(called_received_management_fees, 'currency'),
              style: 'bg-[#5cb493]',
              ratio: ratio(called_received_management_fees, called_received),
            },
            {
              label: capitalize(t('shared.other fee', 0)),
              value: n(called_received_other_fees, 'currency'),
              style: 'bg-[#5cb493]',
              ratio: ratio(called_received_other_fees, called_received),
            },
          ],
        },
        {
          label: capitalize(t('shared.pending')),
          value: n(called_pending, 'currency'),
          style: 'bg-[#7b5bb3]',
          ratio: ratio(called_pending, denominator),
          segments: [
            {
              label: capitalize(t('shared.capital')),
              value: n(called_pending_capital, 'currency'),
              style: 'bg-[#7b5bb3]/75',
              ratio: ratio(called_pending_capital, called_pending),
            },
            {
              label: capitalize(t('shared.management fee', 0)),
              value: n(called_pending_management_fees, 'currency'),
              style: 'bg-[#5cb493]',
              ratio: ratio(called_pending_management_fees, called_pending),
            },
            {
              label: capitalize(t('shared.other fee', 0)),
              value: n(called_pending_other_fees, 'currency'),
              style: 'bg-[#5cb493]',
              ratio: ratio(called_pending_other_fees, called_pending),
            },
          ],
        },
        {
          label: capitalize(t('shared.remaining')),
          value: n(committed_remaining, 'currency'),
          style: 'bg-gray-300',
          ratio: ratio(committed_remaining, denominator),
        },
      ]"
      :skeleton="skeleton"
    />
  </div>
</template>
