import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { useRoute } from 'vue-router'
import { useFetch } from '@/modules/shared/composables/use-fetch'
import { addItem, addItems, clearItems } from '@/modules/shared/utils/store'
import { CID } from '@/modules/shared/utils/store.types'
import { useNotificationStore } from '@/modules/shared/stores/notification-store'
import { Money } from '@/modules/shared/utils/money'

type Investor = {
  _cid: CID
  id: string
  name: string
}

type Subgroup = {
  _key: string
  id: string
  admins: Investor[]
  individuals: Investor[]
  industries: string
  invested_industries: string
  investment_capital: Money
  investments_count: number
  name: string
  skills: string
}

type SubgroupPayload = {
  id: string
  name: string
  admin_ids: string[]
}

///////////////////////////////////////////////////////////////////////////////
// Store
///////////////////////////////////////////////////////////////////////////////

type SubgroupMap = Map<CID, Subgroup>

export const useSubgroupStore = defineStore('investing/subgroupStore', () => {
  const route = useRoute()
  const baseUrl = computed(() => `/${route.params.slug}/investing`)
  const notificationStore = useNotificationStore()

  const items = ref<SubgroupMap>(new Map())
  const subgroups = computed(() => Array.from(items.value.keys()).map((key) => items.value.get(key)))

  const fetchSubgroups = async () => {
    const { data, error } = await useFetch(`${baseUrl.value}/subgroups`).get().json<{ data: Subgroup[] }>()

    if (error.value) {
      console.error(error.value)
      notificationStore.enqueue('error', 'Subgroups failed to load')
      return
    }

    clearItems(items)
    addItems(items, data.value.data)
  }

  const fetchSubgroup = async (subgroup_id: string) => {
    const { data, error } = await useFetch(`${baseUrl.value}/subgroup/${subgroup_id}`).get().json<{ data: Subgroup }>()

    if (error.value) {
      console.error(error.value)
      notificationStore.enqueue('error', 'Subgroup failed to load')
      return
    }

    addItem(items, data.value.data)
  }

  const addIndividual = async (subgroup_id: string, individual_id: any) => {
    await useFetch(`${baseUrl.value}/subgroup/${subgroup_id}/individual/add`).post({ individual_id }).json<{}>()
    await fetchSubgroup(subgroup_id)
    notificationStore.enqueue('success', 'Investor was successfully added')
  }

  const removeIndividual = async (subgroup_id: string, individual_id: string) => {
    const { data, error } = await useFetch(
      `${baseUrl.value}/subgroup/${subgroup_id}/individual/${individual_id}/remove`,
    )
      .delete()
      .json<{}>()

    if (error.value) {
      console.error(error.value)
      notificationStore.enqueue('error', 'Failed to remove investor')
      return
    }

    await fetchSubgroup(subgroup_id)
    notificationStore.enqueue('success', 'Investor was successfully deleted')
  }

  const addSubgroup = async (subgroup: SubgroupPayload) => {
    const { data, error } = await useFetch(`${baseUrl.value}/subgroup/add`)
      .post({ subgroup })
      .json<{ data: Subgroup }>()

    if (error.value) {
      console.error(error.value)
      notificationStore.enqueue('error', 'Failed to remove investor')
      return null
    }

    addItem(items, data.value.data)
    notificationStore.enqueue('success', 'Subgroup was successfully added')
    return data.value.data
  }

  const updateSubgroup = async (subgroup: SubgroupPayload) => {
    const { data, error } = await useFetch(`${baseUrl.value}/subgroup/${subgroup.id}/update`)
      .put({ subgroup })
      .json<{}>()

    if (error.value) {
      console.error(error.value)
      notificationStore.enqueue('error', 'Failed to remove investor')
      return
    }

    await fetchSubgroups()
    notificationStore.enqueue('success', 'Subgroup was successfully updated')
  }

  const removeSubgroup = async (subgroup_id: string) => {
    const { data, error } = await useFetch(`${baseUrl.value}/subgroup/${subgroup_id}/remove`).delete().json<{}>()

    if (error.value) {
      console.error(error.value)
      notificationStore.enqueue('error', 'Failed to remove subgroup')
      return
    }

    await fetchSubgroups()
    notificationStore.enqueue('success', 'Subgroup was successfully deleted')
  }

  return {
    items,
    subgroups,

    fetchSubgroups,
    fetchSubgroup,
    addIndividual,
    removeIndividual,
    addSubgroup,
    removeSubgroup,
    updateSubgroup,
  }
})
