<script setup lang="ts">
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import { onMounted } from 'vue'
import OtherEntityForm from '../components/other-entity-form.vue'
import { useExtendedI18n } from '@/i18n'
import { capitalize } from 'lodash'
import { RouterLinkBack, VButtonInvisible } from '@/modules/shared/components'
import { useAuthStore } from '@/modules/auth/stores/auth-store'
import { useRouter } from 'vue-router'

const { t } = useExtendedI18n()
const router = useRouter()
const authStore = useAuthStore()

onMounted(async () => {
  if (!authStore.is_site_or_group_admin) router.push({ name: 'investing.other-entities' })
})
</script>

<template>
  <TheLayout>
    <div class="-mt-6 mb-6 flex items-center justify-between sm:-mt-8">
      <div>
        <RouterLinkBack :to="{ name: 'investing.other-entities' }">
          <VButtonInvisible>← {{ capitalize(t('shared.back')) }}</VButtonInvisible>
        </RouterLinkBack>
      </div>
    </div>
    <div class="mb-5 text-lg">New Associated Entity</div>
    <OtherEntityForm />
  </TheLayout>
</template>
