import axios, { AxiosInstance, Method } from 'axios'

const API_URL = import.meta.env.VITE_VENTURE360_API_URL

// prettier-ignore
const axiosInstance: AxiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    'Accept': 'application/json',
    'Authorization': `Bearer `,
    'Content-Type': 'application/json',
  },
})

const replacePathParams = (path: string, params: Record<string, string>): string => {
  return path.replace(/:(\w+)/g, (_, key) => params[key])
}

interface RequestConfig {
  accessToken: string
  basePath: string
  params: Record<string, string>
}

const createRequest =
  (method: Method, { accessToken, basePath, params }: RequestConfig) =>
  async (
    path: string,
    payload: Record<string, any> = {},
    queryParams: Record<string, any> = {},
  ): Promise<[Error | null, any]> => {
    axiosInstance.defaults.headers['Authorization'] = `Bearer ${accessToken}`

    const fullPath = replacePathParams(`${basePath}${path}`, params)

    try {
      const response = await axiosInstance({
        data: payload,
        method,
        params: queryParams,
        url: fullPath,
      })
      return [null, response.data]
    } catch (err) {
      console.error(err)
      return [err, null]
    }
  }

const createRequests = (config: RequestConfig) => {
  return {
    get: createRequest('GET', config),
    post: createRequest('POST', config),
    put: createRequest('PUT', config),
    patch: createRequest('PATCH', config),
    delete: createRequest('DELETE', config),
  }
}

///////////////////////////////////////////////////////////////////////////////

export const useInvestorService = (config: { access_token: string; params: Record<string, string> }) => {
  const requestConfig: RequestConfig = {
    accessToken: config.access_token,
    basePath: '/:slug/entity/:entity_type/:entity_id',
    params: config.params,
  }

  const requests = createRequests(requestConfig)

  return {
    list: () => requests.get('/investors'),
    retrieve: (investor_id: number) => requests.get(`/investor/${investor_id}`),
    listProfiles: () => requests.get('/investors/profiles'),
    listStats: () => requests.get('/investors/stats'),
    retrieveStat: ({ investor_type, investor_id }) => requests.get(`/investors/stats/${investor_type}/${investor_id}`),
  }
}
