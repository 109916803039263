import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useFetch } from '@/modules/shared/composables/use-fetch'
import { clearItems } from '@/modules/shared/utils/store'
import { useNotificationStore } from '@/modules/shared/stores/notification-store'
import { CID } from '@/modules/shared/utils/store.types'

///////////////////////////////////////////////////////////////////////////////
// Utils
///////////////////////////////////////////////////////////////////////////////

// TODO move to shared module
const toArray = (map: Map<any, any>) => Array.from(map.keys()).map((key) => map.get(key))

///////////////////////////////////////////////////////////////////////////////
// Types
///////////////////////////////////////////////////////////////////////////////

type ID = number | string
type UUID = string

type Document = {
  id: ID
  uuid: UUID
  attachment_download_file_name: string
  attachment_download_url: string
  attachment_view_url: string
  fileable: {
    id: ID
    name: string
    type: string
  }
  investor: {
    _cid: CID
    id: ID
    name: string
    type: string
  }
  name: string
  tax_year: number
  updated_at: string
}

///////////////////////////////////////////////////////////////////////////////
// Store
///////////////////////////////////////////////////////////////////////////////

type DocumentMap = Map<CID, Document>

export const useDocumentStore = defineStore('tax/documentStore', () => {
  const route = useRoute()
  const notificationStore = useNotificationStore()
  const baseUrl = computed(() => `/${route.params.slug}/tax/document`)

  const items = ref<DocumentMap>(new Map())
  const documents = computed(() => toArray(items.value))

  const fetchDocuments = async (cid: string = 'group') => {
    const { data, error } = await useFetch(`${baseUrl.value}s?cid=${cid}`).get().json<{ data: Document[] }>()

    if (error.value) {
      notificationStore.enqueue('error', 'Unable to retrieve documents')
      return
    }

    clearItems(items)

    for (const item of data.value.data) {
      items.value.set(item.uuid, item)
    }
  }

  const destroy = async (uuid: UUID) => {
    const { error } = await useFetch(`${baseUrl.value}/${uuid}`).delete().json()

    if (error.value) {
      notificationStore.enqueue('error', 'Unable to delete document')
      return
    }

    items.value.delete(uuid)
  }

  return {
    items,
    documents,

    fetchDocuments,
    destroy,
  }
})
