import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useFetch } from '@/modules/shared/composables/use-fetch'
import { addItem, addItems, clearItems } from '@/modules/shared/utils/store'
import { CID } from '@/modules/shared/utils/store.types'
import { Money } from '@/modules/shared/utils/money'

///////////////////////////////////////////////////////////////////////////////
// Types
///////////////////////////////////////////////////////////////////////////////

type ID = number | string

type Call = {
  _cid: CID
  _key: string
  id: string
  call_type: string
  capital: Money
  company: any
  date: string
  fee_amount: Money
  investor: {
    _cid: CID
    _custom_type: string
    id: ID
    name: string
  }
  management_fee: Money
  other_fee: Money
  payment_method: string
  payment_status: string
  payment_status_marked_at: string
  status: string
  total: Money
  total_commitment: Money
  type: string
}

///////////////////////////////////////////////////////////////////////////////
// Store
///////////////////////////////////////////////////////////////////////////////

type CallMap = Map<CID, Call>

export const useCallStore = defineStore('investing/callStore', () => {
  const route = useRoute()
  const baseUrl = computed(() => `/${route.params.slug}/investing`)

  const items = ref<CallMap>(new Map())
  const calls = computed(() => Array.from(items.value.keys()).map((key) => items.value.get(key)))

  const fetchCalls = async (investable_type, investable_id) => {
    const { data, error } = await useFetch(`${baseUrl.value}/${investable_type}/${investable_id}/calls`)
      .get()
      .json<{ data: Call[] }>()

    if (error.value) {
      // TODO: handle error (e.g., display a message to the user)
      console.error(error.value)
      return
    }

    clearItems(items)
    addItems(
      items,
      data.value.data.map((call) => ({ ...call, _cid: call.id })),
    )
  }

  const fetchCall = async (call_id: CID, investable_type, investable_id) => {
    const { data, error } = await useFetch(`${baseUrl.value}/${investable_type}/${investable_id}/call/${call_id}`)
      .get()
      .json<{ data: Call }>()

    if (error.value) {
      // TODO: handle error (e.g., display a message to the user)
      console.error(error.value)
      return
    }

    const call = data.value.data

    addItem(items, { ...call, _cid: call.id })
  }

  const generateInvoice = async (call_id: ID, investable_type, investable_id) => {
    await useFetch(`${baseUrl.value}/${investable_type}/${investable_id}/call/${call_id}/generate-invoice`)
      .post()
      .json<{}>()
    await fetchCall(call_id, investable_type, investable_id)
  }

  const removeCall = async (call_id: ID, investable_type, investable_id) => {
    await useFetch(`${baseUrl.value}/${investable_type}/${investable_id}/call/${call_id}`).delete()
    await fetchCalls(investable_type, investable_id)
  }

  return {
    items,
    calls,

    fetchCalls,
    fetchCall,
    generateInvoice,
    removeCall,
  }
})
