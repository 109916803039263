<script setup lang="ts">
import IndividualHeader from '@/modules/investing/components/individual-header.vue'
import IndividualNav from '@/modules/investing/components/individual-nav.vue'
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import TaxesList from '@/modules/attaching/components/taxes-list.vue'
import { useRoute } from 'vue-router'
import { computed, onMounted, ref } from 'vue'
import { useOtherEntityStore } from '../stores/other-entity-store'
import { useInvestingInvestorStore } from '../stores/investor-store'

const route = useRoute()
const otherEntityStore = useOtherEntityStore()
const investorStore = useInvestingInvestorStore()
const skeleton = ref(true)
const cid = computed(() => `individual:${route.params.individual_id}`)
const currentIndividual = ref(null)
const taxesUtil = ref(null)
const profileOptions = computed(() => {
  const options = [
    {
      label: currentIndividual.value?.name,
      value: cid.value,
    },
  ]

  otherEntityStore.other_entities.forEach((other_entity) => {
    options.push({
      label: other_entity.name,
      value: other_entity._cid,
    })
  })

  return options
})

onMounted(async () => {
  await Promise.all([
    otherEntityStore.fetchOtherEntities('individual', route.params.individual_id),
    investorStore.fetchIndividual(route.params.individual_id),
  ])

  currentIndividual.value = investorStore.items.get(cid.value)
  taxesUtil.value.setFilter()
  skeleton.value = false
})
</script>

<template>
  <TheLayout>
    <IndividualHeader />
    <IndividualNav />
    <TaxesList v-model="taxesUtil" :cid="cid" :filter_options="profileOptions" :skeleton="skeleton" />
  </TheLayout>
</template>
