// Inspiration: docs/sentry-search-spec.md
import { format } from 'date-fns'
import { get } from 'lodash'

export type Item = {
  [key: string]: any
}

///////////////////////////////////////////////////////////////////////////////
// Utils
///////////////////////////////////////////////////////////////////////////////

function parseCurrency(input: string): number | null {
  const cleanInput = input.replace(/[^\d,.-]/g, '')

  const numericValue = parseFloat(cleanInput.replace(/,/g, ''))

  if (isNaN(numericValue)) {
    return null
  }

  return numericValue
}

///////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////

function parse(query: string): string[] {
  if (typeof query !== 'string') {
    throw new Error('Invalid argument: query must be a string')
  }

  const tokens: string[] = []
  let is_in_quotes = false
  let current_token = ''

  for (const char of query) {
    if (char === ' ' && !is_in_quotes) {
      if (current_token.length > 0) {
        tokens.push(current_token)
        current_token = ''
      }

      continue
    }

    if (char === '"') {
      is_in_quotes = !is_in_quotes
      continue
    }

    current_token += char
  }

  if (current_token.length > 0) {
    tokens.push(current_token)
  }

  return tokens
}

function match(tokens: string[], item: Item): boolean {
  if (!Array.isArray(tokens)) {
    throw new Error('Invalid argument: tokens must be an array')
  }

  if (typeof item !== 'object') {
    throw new Error('Invalid argument: item must be an object')
  }

  for (const key in item) {
    if (item.hasOwnProperty(key) && key !== key.toLowerCase()) {
      throw new Error('Invalid argument: item keys must be lowercase')
    }
  }

  const inclusions = tokens.filter((token) => !token.startsWith('-'))
  const exclusions = tokens.filter((token) => token.startsWith('-')).map((token) => token.slice(1))
  const has_inclusions = inclusions.every((token) => matchToken(token, item))
  const has_exclusions = exclusions.some((token) => matchToken(token, item))

  return has_inclusions && !has_exclusions
}

function matchToken(token: string, item: Item): boolean {
  // if (token.includes('*')) {
  //   // Transform the * wildcard into the RegExp equivalent (.*)
  //   const regExpToken = token.replace('*', '.*')

  //   if (!token.includes(':')) {
  //     const flat_item = flatten(item)
  //     const regExp = new RegExp(regExpToken.toLowerCase())
  //     return Object.values(flat_item).some((v) => regExp.test(v?.toString().toLowerCase()))
  //   } else {
  //     const [key, value] = token.split(':')
  //     const item_value = getNestedProperty(item, key)
  //     const regExp = new RegExp(value.replace('*', '.*').toLowerCase())
  //     return regExp.test(item_value?.toString().toLowerCase())
  //   }
  // }

  // handle raw text matching
  if (!token.includes(':')) {
    // prettier-ignore
    const datePattern = /^(?:(?:0[1-9]|[1-9]|1[0-2])\/(?:0[1-9]|[1-9]|[12][0-9]|3[01])\/(?:19|20)\d{2}|(?:19|20)\d{2}-(?:0[1-9]|[1-9]|1[0-2])-(?:0[1-9]|[1-9]|[12][0-9]|3[01]))$/
    if (!datePattern.test(token)) {
      return Object.values(item).some((v) => v?.toString().toLowerCase().includes(token.toLowerCase()))
    } else {
      const token_date = new Date(token)
      return Object.values(item).some((v) => {
        const date = new Date(v)
        if (isNaN(date)) return false
        return format(date, 'MM/dd/yyyy').includes(format(token_date, 'MM/dd/yyyy'))
      })
    }
  }

  // continue

  const [key, value] = token.split(':')
  const operator = value.match(/(<=|>=|=|!=|<|>)/)?.[0]

  // handle key:value matching
  if (!operator) {
    const item_value = get(item, key)

    if (value.includes(',')) {
      const values = value
        .replace(/\[|\]/g, '')
        .split(',')
        .map((v) => v.trim())

      return values.some((v) => item_value?.toString().toLowerCase() === v.toLowerCase())
    }

    return item_value?.toString().toLowerCase() === value.toLowerCase()
  }

  const numerical_postfix = {
    K: 1_000,
    M: 1_000_000,
    B: 1_000_000_000,
    T: 1_000_000_000_000,
  }
  // handle arithmetic matching
  const item_value = parseCurrency(get(item, key).toString())
  const token_postfix = value.match(/(K|M|B|T)/i)?.[0].toUpperCase()
  const value_multiplier = token_postfix ? numerical_postfix[token_postfix] : 1
  const token_value = parseCurrency(value.replace(operator, '').toString()) * value_multiplier

  if (isNaN(item_value)) {
    throw new Error('Invalid argument: item value must be a number for arithmetic operations')
  }

  if (isNaN(token_value)) {
    throw new Error('Invalid argument: token value must be a number for arithmetic operations')
  }

  const operators: { [key: string]: (a: number, b: number) => boolean } = {
    '>': (a, b) => a > b,
    '>=': (a, b) => a >= b,
    '<': (a, b) => a < b,
    '<=': (a, b) => a <= b,
    '=': (a, b) => a === b,
    '!=': (a, b) => a !== b,
  }

  return operators[operator](item_value, token_value)
}

function search(items: Item[], query: string): Item[] {
  if (!Array.isArray(items)) {
    throw new Error('Invalid argument: items must be an array')
  }

  if (typeof query !== 'string') {
    throw new Error('Invalid argument: query must be a string')
  }

  const tokens = parse(query)

  return items.filter((item) => match(tokens, item))
}

// NOTE, these are not alphabetized, but they are in usage order which feels appropriate
export { parse, match, matchToken, search }
