import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useFetch } from '@/modules/shared/composables/use-fetch'
import { addItem, addItems, clearItems } from '@/modules/shared/utils/store'
import { CID } from '@/modules/shared/utils/store.types'
import { useNotificationStore } from '@/modules/shared/stores/notification-store'

///////////////////////////////////////////////////////////////////////////////
// Types
///////////////////////////////////////////////////////////////////////////////

type ID = number | string

type Beneficiary = {
  id: ID
  birthdate: string
  city: string
  name: string
  percent: number
  relationship: string
  ssn: string
  state: string
  street: string
  type: string
  zip: string
}

///////////////////////////////////////////////////////////////////////////////
// Store
///////////////////////////////////////////////////////////////////////////////

type BeneficiaryMap = Map<CID, Beneficiary>

export const useBeneficiaryStore = defineStore('investing/beneficiaryStore', () => {
  const route = useRoute()
  const notificationStore = useNotificationStore()
  const baseUrl = computed(() => `/${route.params.slug}/investing/individual/${route.params.individual_id}`)

  const items = ref<BeneficiaryMap>(new Map())
  const beneficiaries = computed(() => Array.from(items.value.keys()).map((key) => items.value.get(key)))

  const fetchBeneficiaries = async () => {
    const { data, error } = await useFetch(`${baseUrl.value}/beneficiaries`).get().json<{ data: Beneficiary[] }>()

    if (error.value) {
      // TODO: handle error (e.g., display a message to the user)
      console.error(error.value)
      return
    }

    clearItems(items)
    addItems(
      items,
      data.value.data.map((beneficiary) => ({ ...beneficiary, _cid: beneficiary.id })),
    )
  }

  const addBeneficiary = async (payload) => {
    const { data, error } = await useFetch(`${baseUrl.value}/beneficiary/add`).post(payload).json()

    if (error.value) {
      // TODO: handle error (e.g., display a message to the user)
      console.error(error.value)
      notificationStore.enqueue('error', data.value.data[0] || data.value.data)
      return { data, error }
    }
    await fetchBeneficiaries()
  }

  const removeBeneficiary = async (beneficiary_id: ID) => {
    const { data, error } = await useFetch(`${baseUrl.value}/beneficiary/${beneficiary_id}/remove`).delete()

    if (error.value) {
      // TODO: handle error (e.g., display a message to the user)
      console.error(error.value)
      notificationStore.enqueue('error', 'Beneficiary failed to remove')
      return
    }
    await fetchBeneficiaries()
  }

  return {
    items,
    beneficiaries,

    fetchBeneficiaries,
    addBeneficiary,
    removeBeneficiary,
  }
})
