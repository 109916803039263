import { Ref } from 'vue'
import { CID } from './store.types'

const addItem = (items: Ref<Map<CID, unknown>>, item: any) => {
  if (!item._cid) {
    item._cid = `${item._key}:${item.id}` // enrich with composite id
  }

  items.value.set(item._cid, item)
}

const addItems = (items: Ref<Map<CID, unknown>>, newItems: any[]) => {
  for (const item of newItems) {
    addItem(items, item)
  }
}

const clearItems = (items: Ref<Map<CID, unknown>>) => {
  items.value = new Map()
}

// prettier-ignore
export {
  addItem,
  addItems,
  clearItems
}
