<script setup lang="ts">
import { capitalize } from 'lodash'
import { computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useCan } from '@/modules/auth/composables/use-can'
import { rails_url } from '@/modules/shared/utils/rails'
import VTabList from '@/modules/shared/components/v-tab-list.vue'
import { useOutstandingStore } from '@/modules/dashboard/stores/outstanding-store'

const { can } = useCan()
const { t } = useI18n()
const route = useRoute()
const outstandingStore = useOutstandingStore()

const id = route.params.other_entity_id
const entityURL = computed(() => `${rails_url()}/funding-entities/${id}`)

const isCurrent = (name: string) => route.name === name

const outstanding = computed(() => outstandingStore.outstanding)

onMounted(async () => {
  await Promise.all([outstandingStore.fetchOutstandings(`funding-entity:${id}`)])
})
</script>

<template>
  <div class="mb-12 px-4 sm:px-0">
    <VTabList
      :tabs="[
        {
          current: isCurrent('investing.other-entity.overview'),
          name: capitalize(t('shared.overview')),
          to: {
            name: 'investing.other-entity.overview',
          },
        },
        {
          current: isCurrent('investing.other-entity.portfolio'),
          name: capitalize(t('shared.portfolio')),
          to: {
            name: 'investing.other-entity.portfolio',
          },
        },
        {
          current: isCurrent('investing.other-entity.invoices'),
          name: capitalize(t('shared.invoice', 0)),
          to: {
            name: 'investing.other-entity.invoices',
          },
          count: outstanding.invoices_count,
        },
        {
          current: isCurrent('investing.other-entity.investors'),
          name: capitalize(t('shared.investor', 0)),
          to: {
            name: 'investing.other-entity.investors',
          },
        },
        {
          current: false,
          name: capitalize(t('shared.document', 0)),
          to: `${entityURL}/documents`,
          count: outstanding.documents_count,
        },
        {
          current: false,
          name: capitalize(t('shared.file', 0)),
          to: `${entityURL}/files`,
        },
        {
          current: false,
          name: capitalize(t('shared.note', 0)),
          to: `${entityURL}/notes`,
          visible: can('admin', `${id}`),
        },
      ]"
    />
  </div>
</template>
