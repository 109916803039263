import { createFetch } from '@vueuse/core'
import { useAuthStore } from '@/modules/auth/stores/auth-store'

export const useFetch = createFetch({
  baseUrl: import.meta.env.VITE_VENTURE360_API_URL,
  options: {
    async beforeFetch({ options }) {
      const authStore = useAuthStore()

      if (authStore.access_token) {
        options.headers['Authorization'] = `Bearer ${authStore.access_token}`
      }

      return { options }
    },

    updateDataOnError: true, // enables access to the error object in the returned data
  },
})
