import { CID } from './store.types'

// NOTE: this is duplicated in multiple places
type Option = {
  disabled?: boolean
  label: string
  value: string
}

const createOptions = (items: Record<string, any> | Map<CID, any>, { label }): Option[] => {
  let data = []
  if (items.constructor.name === 'Map') {
    data = Array.from(items.keys())
      .filter((key) => items.get(key)[label])
      .map((key) => ({
        label: items.get(key)[label],
        value: key,
      }))
  } else {
    data = Object.keys(items)
      .filter((key) => items[key][label])
      .map((key) => ({
        label: items[key][label],
        value: key,
      }))
  }
  return data.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))
}

const markDisabledOptions = (options: Option[], selectedItems) => {
  return options.map((option) => {
    return {
      ...option,
      disabled: selectedItems.includes(option.value),
    }
  })
}

export { Option, createOptions, markDisabledOptions }
