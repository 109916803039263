<script setup lang="ts">
import { clamp } from 'lodash'
import { computed } from 'vue'
import { format } from '@/modules/shared/utils/v-table'

const props = defineProps<{
  color?: string
  progress: number
}>()

const colors = {
  'v-blue': 'bg-gradient-to-r from-[#4C8DB1] to-[#40A3C7]',
  'v-green': 'bg-[#408F93]',
}

const color = computed(() => (props.color ? colors[props.color] : colors['v-blue']))
const percent = computed(() => format(props.progress, 'percent'))
const width = computed(() => format(clamp(props.progress, 0, 1), 'percent'))
</script>

<template>
  <div>
    <div class="mb-1 rounded bg-gray-50" :style="{ backgroundImage: 'url(/hazard-12x12.svg)' }">
      <div class="h-2 rounded" :class="[color]" :style="`width: ${width}`"></div>
    </div>
    <div :style="`width: ${width}`">
      <div class="text-right text-xs font-semibold text-gray-600">{{ percent }}</div>
    </div>
  </div>
</template>
