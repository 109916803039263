<script lang="ts" setup>
withDefaults(
  defineProps<{
    color?: string
    size?: string
  }>(),
  {
    color: 'gray',
    size: 'xs',
  },
)

// prettier-ignore
const colors = {
  'emerald': 'bg-emerald-100 text-emerald-700 ring-emerald-200/75',
  'gray': 'bg-gray-100 text-gray-600 ring-gray-200/60',
  'red': 'bg-red-100 text-red-700 ring-red-200/75',
  'sky': 'bg-sky-100 text-sky-700 ring-sky-200/75',
  'v-blue': 'bg-[#3982AF]/10 text-[#3982AF] ring-[#3982AF]/20',
  'v-green': 'bg-[#408F92]/10 text-[#408F92] ring-[#408F92]/20',
}

// prettier-ignore
const sizes = {
  'xxs': 'px-1.5 py-0.5 text-xs rounded',
  'xs': 'px-2 py-1 text-xs rounded-md',
}
</script>

<template>
  <span class="block whitespace-nowrap font-medium ring-1" :class="[colors[color], sizes[size]]">
    <slot></slot>
  </span>
</template>
