<script setup lang="ts">
import { ref, computed } from 'vue'
import { enableTwoFactorService, skipEnableTwoFactorService } from '../api'
import AuthLayout from '../layouts/auth-layout.vue'
import InputGroup from '../components/input-group.vue'
import FormError from '../components/form-error.vue'
import VButton from '../components/v-button.vue'
import VSpinner from '../components/v-spinner.vue'
import { useAuthStore } from '../../../modules/auth/stores/auth-store'
import { useRouter } from 'vue-router'

// state
const loading = ref(false)
const error = ref(null)
const errorAnimated = ref(false)
const router = useRouter()

// form
const two_factor_phone_number = ref(null)

// submit form, handle states, and redirect
const enable_two_factor = async () => {
  // prevent multiple requests
  if (loading.value === true) {
    return
  }

  // start
  loading.value = true

  // contact api
  const payload = {
    two_factor_phone_number: clean_two_faactor_phone_number.value,
  }
  const res = await enableTwoFactorService(payload)
  const { status, data } = res

  // handle success state
  if (status == 'success') {
    router.push({ name: 'auth.verify', query: { channel: 'sms' } })
  }

  // handle fail and error states
  if (status == 'fail' || status == 'error') {
    error.value = res.data.reason
    errorAnimated.value = false
    setTimeout(() => (errorAnimated.value = true), 820)
  }

  // finally,
  loading.value = false
}

const skip_enable_two_factor = async () => {
  const authStore = useAuthStore()
  const res = await skipEnableTwoFactorService()
  const { status } = res

  if (status == 'success') {
    const slug = authStore?.slug ? `slug=${authStore?.slug}` : ''
    window.location.href = `${
      import.meta.env.VITE_VENTURE360_SESSION_URL
    }/redirect?access_token=${authStore?.access_token}&${slug}`
  }
}

const clean_two_faactor_phone_number = computed(() => {
  let raw_number = two_factor_phone_number.value || ''

  if (raw_number.substring(0, 1) !== '+') {
    raw_number = `+1${raw_number}`
  }

  const clean_number = raw_number.replace(/^(\+)|\D/g, '').replace('^\+', '')

  return `+${clean_number}`
})
</script>

<template>
  <AuthLayout>
    <template v-slot:icon>
      <svg width="90" height="91" viewBox="0 0 90 91" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M90.0001 36.8C90.0001 63.12 70.9601 85.64 45.0001 90.04C19.0001 85.64 -0.0399371 63.12 6.2907e-05 36.76V6C6.2907e-05 2.68 2.68006 0 6.00006 0H84.0001C87.2801 0 90.0001 2.68 90.0001 6V36.8Z"
          fill="#111827"
        />
        <path
          d="M8.00012 18.0801H82.0001"
          stroke="#1F2937"
          stroke-width="6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M66.0001 36.04L44.1201 57.92C42.9201 59.08 41.0401 59.08 39.8801 57.92C39.8401 57.88 39.8401 57.88 39.8401 57.88L32.9601 51"
          stroke="white"
          stroke-width="6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </template>

    <div class="flex h-full w-[360px] flex-col justify-between">
      <header class="mt-24">
        <h1 class="text-3xl leading-9 text-gray-900">Secure Your Account</h1>
        <p class="mt-5 text-sm leading-5 text-gray-700">
          In an effort to help protect you and your data, Venture360 has recently enabled two-factor authentication for
          all accounts.
        </p>
        <p class="mt-5 text-sm leading-5 text-gray-700">
          Please enter your phone number and we will text you a code to enter every time you login.
        </p>
      </header>
      <main
        class="animate mb-16 mt-24 flex-grow"
        :class="{ 'animate-shake': errorAnimated === false && error !== null }"
      >
        <InputGroup
          label="Phone Number"
          placeholder="+1 555-555-5555"
          v-model="two_factor_phone_number"
          @keydown.enter="enable_two_factor"
        />
        <FormError :error="error" v-show="error !== null" />
        <VButton @click="enable_two_factor" :disabled="loading">
          <span v-if="loading" class="p-0.5"><VSpinner /></span>
          <span v-else>Next</span>
        </VButton>
      </main>
      <footer class="mb-12">
        <div class="text-center">
          <a href="#" class="text-xs text-gray-500 underline" @click="skip_enable_two_factor"
            >Don't secure my account, but remind me later.</a
          >
        </div>
      </footer>
    </div>
  </AuthLayout>
</template>
