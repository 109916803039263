<script setup lang="ts">
import { useDropZone, useFileDialog } from '@vueuse/core'
import { ref, watch } from 'vue'
import { VButton, VAvatar } from '@/modules/shared/components'

const props = withDefaults(
  defineProps<{
    profile_picture: string
    accept: string[]
    is_image_new?: boolean
    initials: string
    onUpload?: any
    onRemove?: any
  }>(),
  {
    accept: [],
    is_image_new: true,
    onUpload: () => {},
    onRemove: () => {},
  },
)

const uploadImage = async (image: File) => {
  const is_acceptable = props.accept.some((a) => image.name.endsWith(a))
  if (!is_acceptable) return

  await props.onUpload(image)
}

///////////////////////////////////////////////////////////////////////////////
// Drag zone
///////////////////////////////////////////////////////////////////////////////

const drop_zone_ref = ref<HTMLDivElement>()

const onDrop = (dz_files: File[] | null) => {
  if (dz_files === null) return
  uploadImage(dz_files[0])
}

const { isOverDropZone } = useDropZone(drop_zone_ref, onDrop)

///////////////////////////////////////////////////////////////////////////////
// File dialog
///////////////////////////////////////////////////////////////////////////////

const { files: fd_files, open, reset } = useFileDialog({ accept: props.accept.join(',') })

watch(fd_files, (fileList) => {
  const fileArray = Array.from(fileList) as File[]
  uploadImage(fileArray[0])
})
</script>

<template>
  <div class="flex gap-5">
    <div class="relative cursor-pointer" ref="drop_zone_ref" @click="open">
      <VAvatar :image="profile_picture" :initials="initials" size="lg" />
    </div>
    <div class="flex flex-col justify-center gap-3">
      <VButton size="sm" variant="secondary" :click="open"> {{ is_image_new ? 'Add' : 'Change' }} Image </VButton>
      <VButton size="sm" variant="v-red" :click="onRemove" v-if="!is_image_new"> Remove Image </VButton>
    </div>
  </div>
</template>
