<script setup lang="ts">
import { VBadge, VButton } from '@/modules/shared/components'
import { useCustomerStore } from '../stores/customer-store'
import { useProductStore } from '../stores/product-store'
import SubscriptionStatus from './subscription-status.vue'
import { to_currency, to_date } from '../utils'
import { is_sub_canceled, is_sub_ended, is_sub_ending, is_sub_renewing, sub_has_discount } from '../utils/stripe'

const customerStore = useCustomerStore()
const productStore = useProductStore()

///////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////

const props = withDefaults(
  defineProps<{
    actions?: boolean
    subscription: any
  }>(),
  {
    actions: true,
    subscription: null,
  },
)

const subscription_id = props.subscription.id

const transform_price = (price_id: string) => {
  const price = productStore.prices[price_id]

  return price?.metadata?.reference_price_id ? productStore.prices[price.metadata.reference_price_id] : price
}

const transform_subscription = (subscription: any) => {
  // select
  const item = subscription.items[0]
  const price = transform_price(item.price.id)
  const product = productStore.products[price.stripe_product_id]

  // calculations
  const amount = item.quantity * price.unit_amount
  const product_name = price.billing_scheme === 'tiered' ? `${product.name} × ${item.quantity}` : product.name

  return {
    price: {
      amount,
      currency: price.currency.toUpperCase(),
      recurring: {
        interval: price.recurring_interval,
      },
      type: price.type.replace('_', '-'),
    },
    product: {
      name: product_name,
    },
    quantity: item.quantity,
    start_date: item.start_date,
  }
}

const data = transform_subscription(props.subscription)

const discounted_amount = () => {
  let discounted_price = data.price.amount

  if (props.subscription.discount.coupon.percent_off !== null) {
    const percent_off = props.subscription.discount.coupon.percent_off / 100
    discounted_price = data.price.amount * (1 - percent_off)
  } else if (props.subscription.discount.coupon.amount_off !== null) {
    const amount_off = props.subscription.discount.coupon.amount_off
    // check if the coupon applies to a specific product
    if (props.subscription.stripe_cache.discount.coupon.applies_to === undefined) {
      // prettier-ignore
      const subscription_amount = props.subscription.stripe_cache.items.data.reduce((total, item) => 
        total + props.subscription.items[0].quantity * transform_price(item.price.id).unit_amount, 0
      )
      discounted_price = data.price.amount - (data.price.amount / subscription_amount) * amount_off
    } else {
      discounted_price = data.price.amount - amount_off
    }
  }

  return discounted_price < 0 ? 0 : discounted_price
}
</script>

<template>
  <div class="flex items-center justify-start space-x-3">
    <aside>
      <SubscriptionStatus :subscription="subscription" :type="data.price.type" />
    </aside>
    <div>
      <div class="flex items-center space-x-3">
        <h1 class="mb-0 flex items-center text-xl text-gray-800">
          {{ data.product.name }}
        </h1>
        <VBadge v-if="props.subscription.status === 'unpaid'" color="red">Past Due</VBadge>
        <VBadge v-else color="gray" size="xxs">{{ props.subscription.status }}</VBadge>
      </div>

      <div class="mb-0 items-center md:flex md:space-x-2">
        <div v-if="sub_has_discount(subscription)">
          <del>{{ to_currency(data.price.amount, data.price.currency.toUpperCase()) }}</del>
          {{ to_currency(discounted_amount(), data.price.currency.toUpperCase()) }}
          {{ data.price.currency.toUpperCase() }} /
          {{ data.price.type === 'recurring' ? data.price.recurring.interval : data.price.type }}
        </div>
        <div v-else>
          {{ to_currency(data.price.amount, data.price.currency.toUpperCase()) }}
          {{ data.price.currency.toUpperCase() }} /
          {{ data.price.type === 'recurring' ? data.price.recurring.interval : data.price.type }}
        </div>
        <template v-if="data.price.recurring.interval">
          <div class="hidden text-gray-300 md:block">&#x2022;</div>
          <div v-if="is_sub_canceled(subscription)">Plan canceled on {{ to_date(subscription.canceled_at) }}</div>
          <div v-if="is_sub_ended(subscription)">Plan ended on {{ to_date(subscription.ended_at) }}</div>
          <div v-if="is_sub_ending(subscription)">Plan ends on {{ to_date(subscription.cancel_at) }}</div>
          <div v-if="is_sub_renewing(subscription)">Plan renews on {{ to_date(subscription.current_period_end) }}</div>
        </template>

        <div class="flex items-center space-x-2 pl-2" v-if="actions">
          <RouterLink
            v-if="false && customerStore.customer.stripe_subscriptions.length > 0 && !is_sub_ending(subscription)"
            :to="{ name: 'billing-change-plan', params: { subscription_id } }"
            class="inline-block"
          >
            <VButton size="xs">Change plan&hellip;</VButton>
          </RouterLink>
          <RouterLink
            v-if="!is_sub_ended(subscription) && !is_sub_ending(subscription) && !is_sub_canceled(subscription)"
            :to="{ name: 'billing-cancel-plan', params: { subscription_id } }"
            class="inline-block"
          >
            <VButton size="xs">Cancel plan&hellip;</VButton>
          </RouterLink>
          <VButton
            v-if="is_sub_ending(subscription)"
            size="xs"
            :click="customerStore.continue_subscription(subscription.stripe_subscription_id)"
          >
            Continue plan
          </VButton>
        </div>
      </div>
    </div>
  </div>
</template>
