<script setup lang="ts">
import { onMounted, ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useAuthStore } from '@/modules/auth/stores/auth-store'
import {
  RouterLinkBack,
  VAvatar,
  VBadge,
  VButtonInvisible,
  VPopUpModal,
  VButton,
  VMailTo,
  VSection,
  VSkeletonBar,
} from '@/modules/shared/components'
import { useModal } from '@/modules/shared/composables/use-modal'
import { format } from '@/modules/shared/utils/v-table'
import { useWorkspaceStore } from '@/modules/workspace/stores/workspace-store'
import InvestorBadge from '../components/investor-badge.vue'
import { VIcon } from '@/modules/shared/components'
import {
  useInvestingInvestorStore,
  Investor,
  accreditatedStatusInfo,
  amlStatusInfo,
  profileStatusInfo,
  ProfileStatus,
} from '../stores/investor-store'
import { rails_url } from '@/modules/shared/utils/rails'

const skeleton = ref(true)

const route = useRoute()

const cid = `individual:${route.params.individual_id}`

const workspaceStore = useWorkspaceStore()

const authStore = useAuthStore()

const investingInvestorStore = useInvestingInvestorStore()

const current_individual = ref<Investor>()

const accreditedModal = useModal()

const disbursementModal = useModal()

const isAdmin = computed(() => authStore.is_site_or_group_admin)

///////////////////////////////////////////////////////////////////////////////
// Statuses
///////////////////////////////////////////////////////////////////////////////

const accreditationBadge = computed(() => accreditatedStatusInfo[current_individual.value?.accredited_status])
const amlBadge = computed(() => amlStatusInfo[current_individual.value?.aml_status])
const profileBadge = computed(() => profileStatusInfo[current_individual.value?.profile_status])

///////////////////////////////////////////////////////////////////////////////
// AML
///////////////////////////////////////////////////////////////////////////////

const amlModal = useModal()

const can_open_aml_modal = computed(
  () => workspaceStore.current.options.enable_investor_aml_verification && authStore.is_site_or_group_admin,
)

const openAML = () => {
  if (!can_open_aml_modal.value) return
  amlModal.open()
}

const updateAML = async (match_status: string) => {
  const payload = {
    identity_log_id: current_individual.value.aml.identity_log_id,
    individual_id: current_individual.value.id,
    match_status: match_status,
  }
  await investingInvestorStore.updateIndividualAML(payload)
  current_individual.value = investingInvestorStore.items.get(cid)
  amlModal.close()
}

const getAmlStatus = computed(() => {
  let status = current_individual.value?.aml?.is_whitelisted
    ? 'whitelisted'
    : current_individual.value?.aml?.match_status

  switch (status) {
    case 'false_positive':
    case 'no_match':
    case 'true_positive_reject':
    case 'unknown':
    case 'whitelisted':
      return 'pass'
    case 'potential_match':
      return 'potential_match'
    case 'true_positive':
    case 'true_positive_approve':
      return 'match'
  }

  return 'default'
})

///////////////////////////////////////////////////////////////////////////////

onMounted(async () => {
  await workspaceStore.fetchCurrent()
  await investingInvestorStore.fetchIndividual(route.params.individual_id)
  current_individual.value = investingInvestorStore.items.get(cid)
  skeleton.value = false
})
</script>

<template>
  <header class="mb-6 px-4 sm:px-0">
    <div class="-mt-8 mb-6">
      <RouterLinkBack
        v-if="isAdmin"
        :to="{ name: 'investing.individuals' }"
        :fallback="{ name: 'investing.individual-overview' }"
      >
        <VButtonInvisible>← Back to all Individuals</VButtonInvisible>
      </RouterLinkBack>
    </div>

    <div class="flex items-center gap-5">
      <RouterLink :to="{ name: 'investing.individual.edit' }">
        <VAvatar :image="current_individual?.profile_picture" :initials="current_individual?.initials" size="lg" />
      </RouterLink>
      <div class="flex max-w-[70%] flex-col gap-3">
        <VSkeletonBar v-if="skeleton" />

        <h2
          v-if="!skeleton"
          class="whitespace-nowrap text-3xl font-semibold leading-7 text-gray-900 dark:text-gray-300"
        >
          {{ current_individual?.name }}
        </h2>
        <div v-if="current_individual?.email" class="-mt-2">
          <VSkeletonBar v-if="skeleton" />
          <VMailTo v-else :email="current_individual?.email" class="incognito text-sm" />
        </div>
        <div class="flex items-center justify-start space-x-1.5 text-xs">
          <VSkeletonBar v-if="skeleton" />
          <VSkeletonBar v-if="skeleton" />
          <VSkeletonBar v-if="skeleton" />

          <a
            :href="`${rails_url()}/investors/${current_individual?.id}/edit/accreditation`"
            v-if="accreditationBadge"
            class="cursor-pointer"
          >
            <InvestorBadge :color="accreditationBadge.color">{{ accreditationBadge.label }}</InvestorBadge>
          </a>

          <InvestorBadge
            v-if="amlBadge && workspaceStore.current.options.is_aml_enabled"
            @click="openAML"
            :color="amlBadge.color"
            :class="{ 'cursor-pointer': can_open_aml_modal }"
            >{{ amlBadge.label }}</InvestorBadge
          >
          <template v-if="profileBadge">
            <RouterLink
              :to="{ name: 'investing.individual.edit' }"
              v-if="current_individual.profile_status === ProfileStatus.Incomplete"
            >
              <InvestorBadge :color="profileBadge.color">{{ profileBadge.label }}</InvestorBadge>
            </RouterLink>
            <InvestorBadge :color="profileBadge.color" v-else>{{ profileBadge.label }}</InvestorBadge>
          </template>
        </div>
      </div>
    </div>
    <div class="mb-6 sm:mb-0">
      <div class="flex items-center justify-end space-x-2">
        <slot name="actions"></slot>
      </div>
    </div>

    <VPopUpModal :modalStore="accreditedModal"> Accredited Info </VPopUpModal>
    <VPopUpModal :modalStore="amlModal">
      <VSection label="AML Verification">
        <div class="text-gray-500">AML Status</div>
        <div class="flex gap-1">
          <template v-if="getAmlStatus === 'pass'">
            <span class="text-[rgb(64,146,126)]">
              <VIcon name="shield-tick" class="block h-[18px] w-[18px]" />
            </span>
          </template>
          <template v-if="getAmlStatus === 'default'">
            <span class="text-gray-300">
              <VIcon name="hourglass-01" />
            </span>
          </template>
          <template v-if="getAmlStatus === 'potential_match'">
            <span class="text-orange-400">
              <VIcon name="flag-02" />
            </span>
          </template>
          <template v-if="getAmlStatus === 'match'">
            <span class="text-red-600">
              <VIcon name="slash-circle-01" />
            </span>
          </template>
          <a class="hyperlink" :href="current_individual?.aml?.share_url" target="_blank">
            {{ amlBadge?.label }}
          </a>
        </div>
        <div class="mt-5 text-gray-500">AML Last check</div>
        <div class="flex gap-1">
          {{ format(current_individual?.aml?.updated_at, 'date') }},
          {{ format(current_individual?.aml?.updated_at, 'time') }}
        </div>

        <div class="mt-5 flex w-fit flex-col gap-3" v-if="getAmlStatus !== 'pass' && authStore.is_site_admin">
          <VButton variant="v-green" size="sm" :click="() => updateAML('false_positive')"
            >Mark as Pass - No match found</VButton
          >
          <VButton variant="v-red" size="sm" :click="() => updateAML('true_positive')" v-if="getAmlStatus !== 'match'">
            Mark as Fail - Match found
          </VButton>
        </div>
      </VSection>
    </VPopUpModal>
    <VPopUpModal :modalStore="disbursementModal">
      <VSection label="Disbursement Info">
        <table class="disbursement-info w-full">
          <tbody>
            <tr>
              <td class="font-semibold">Method</td>
              <td>{{ current_individual?.disbursement_method }}</td>
            </tr>
            <template v-if="current_individual?.disbursement_method === 'wire'">
              <tr>
                <td class="font-semibold">Bank name</td>
                <td>{{ current_individual?.disbursement_wire_bank_name }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Bank address</td>
                <td>{{ current_individual?.disbursement_wire_bank_address }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Bank routing number</td>
                <td>{{ current_individual?.disbursement_wire_bank_routing_number }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Bank account number</td>
                <td>{{ current_individual?.disbursement_wire_bank_account_number }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Bank swift code</td>
                <td>{{ current_individual?.disbursement_wire_bank_swift_code }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Bank account name</td>
                <td>{{ current_individual?.disbursement_wire_account_name }}</td>
              </tr>
              <tr>
                <td class="font-semibold">For further creadit to</td>
                <td>{{ current_individual?.disbursement_wire_for_further_credit_to }}</td>
              </tr>
            </template>
            <tr v-else-if="current_individual?.disbursement_method === 'check'">
              <td class="font-semibold">Receiver name</td>
              <td>{{ current_individual?.disbursement_check_receiver_name }}</td>
            </tr>
            <tr v-else>
              <td class="font-semibold">Other details</td>
              <td>{{ current_individual?.disbursement_other_details }}</td>
            </tr>
          </tbody>
        </table>
      </VSection>
    </VPopUpModal>
  </header>
</template>

<style scoped>
table.disbursement-info td,
table.disbursement-info th {
  @apply whitespace-nowrap pb-2 pr-5 text-left first:w-[1%];
}
</style>
