<script setup lang="ts">
import { useExtendedI18n } from '@/i18n'
import { capitalize, startCase as _startCase } from 'lodash'
import { VRadio, VSection, VTextField, VTextArea } from '@/modules/shared/components'
import { computed } from 'vue'
import VInputRadio from './v-input-radio.vue'

const { t } = useExtendedI18n()

const props = withDefaults(
  defineProps<{
    modelValue?: any
    v$: any
    check_bank_account_number?: boolean
  }>(),
  {
    check_bank_account_number: true,
  },
)

const emit = defineEmits(['update:modelValue'])

const form_data = computed({
  get: (): any => props.modelValue,
  set: (value) => emit('update:modelValue', value),
})
</script>

<template>
  <VSection class="!mb-0">
    <VInputRadio
      v-model="form_data.disbursement_method"
      label="How do you want to receive disbursements?"
      :options="[
        { label: 'Wire transfer', value: 'wire' },
        { label: 'Check', value: 'check' },
        { label: 'Other', value: 'other' },
      ]"
      :v$="v$"
      class="mb-5"
    />
    <template v-if="form_data.disbursement_method === 'wire'">
      <VTextField
        v-model="form_data.disbursement_wire_bank_name"
        label="Bank Name"
        property="disbursement_wire_bank_name"
        :v$="v$"
        class="mb-5"
      />
      <VTextArea
        :label="capitalize(t('shared.bank address'))"
        v-model="form_data.disbursement_wire_bank_address"
        :v$="v$"
        property="disbursement_wire_bank_address"
        class="mb-5"
      />
      <div class="mb-5 flex gap-5">
        <VTextField
          v-model="form_data.disbursement_wire_bank_routing_number"
          :label="capitalize(t('shared.bank routing number'))"
          property="disbursement_wire_bank_routing_number"
          type="text"
          :v$="v$"
          class="w-1/2"
        />
        <VTextField
          v-model="form_data.disbursement_wire_bank_swift_code"
          :label="capitalize(t('shared.bank swift code'))"
          property="disbursement_wire_bank_swift_code"
          type="text"
          :v$="v$"
          class="w-1/2"
        />
      </div>
      <VTextField
        v-model="form_data.disbursement_wire_bank_account_number"
        :label="capitalize(t('shared.bank account number'))"
        property="disbursement_wire_bank_account_number"
        type="text"
        :v$="v$"
        class="mb-5"
      />
      <VTextField
        v-model="form_data.disbursement_wire_repeat_bank_account_number"
        :label="capitalize(t('shared.verify bank account number'))"
        property="disbursement_wire_repeat_bank_account_number"
        type="text"
        :v$="v$"
        class="mb-5"
        v-if="check_bank_account_number"
      />
      <VTextField
        v-model="form_data.disbursement_wire_account_name"
        :label="capitalize(t('shared.bank account name'))"
        property="disbursement_wire_account_name"
        type="text"
        :v$="v$"
        class="mb-5"
      />
      <VTextField
        v-model="form_data.disbursement_wire_for_further_credit_to"
        :label="capitalize(t('shared.for further credit to'))"
        property="disbursement_wire_for_further_credit_to"
        type="text"
        :v$="v$"
        class="mb-5"
      />
    </template>
    <template v-if="form_data.disbursement_method === 'check'">
      <VTextField
        v-model="form_data.disbursement_check_receiver_name"
        :label="capitalize(t('shared.make check payable to'))"
        property="disbursement_check_receiver_name"
        type="text"
        :v$="v$"
        class="mb-5"
      />
    </template>
    <template v-if="form_data.disbursement_method === 'other'">
      <VTextArea
        :label="capitalize(t('shared.other detail', 0))"
        v-model="form_data.disbursement_other_details"
        :v$="v$"
        property="disbursement_other_details"
        class="mb-5"
      />
    </template>
  </VSection>
</template>
