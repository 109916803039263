<script setup lang="ts">
import { ref, watch } from 'vue'
import { useDropZone, useFileDialog } from '@vueuse/core'
import { useSortable } from '@vueuse/integrations/useSortable'
import { VButton, VIcon } from '@/modules/shared/components'

///////////////////////////////////////////////////////////////////////////////
// Config
///////////////////////////////////////////////////////////////////////////////

const MAX_FILE_SIZE_HUMAN = '15MB'
const MAX_PAGES = 250

const props = withDefaults(
  defineProps<{
    accept: string[]
    disabled?: boolean
    existing_files?: string[]
    multiple_files?: boolean
  }>(),
  {
    accept: [],
    disabled: false,
    existing_files: null,
    multiple_files: true,
  },
)

const emit = defineEmits(['chosen'])

///////////////////////////////////////////////////////////////////////////////
// File management
///////////////////////////////////////////////////////////////////////////////

const files = ref<File[]>([])

const addFile = (file: File) => {
  const is_acceptable = props.accept.some((a) => file.name.endsWith(a))
  const is_duplicate = files.value.some((f) => f.name === file.name && f.size === file.size)

  if (is_acceptable && !is_duplicate) {
    if (!props.multiple_files) emptyFiles()
    files.value.push(file)
  }
}

const removeFile = (index: number) => {
  files.value.splice(index, 1)
}

const emptyFiles = () => files.value.splice(0, files.value.length)

watch(files.value, (files) => {
  if (props.multiple_files) {
    emit('chosen', files)
  } else {
    emit('chosen', files[0])
  }
})

///////////////////////////////////////////////////////////////////////////////
// Drag zone
///////////////////////////////////////////////////////////////////////////////

const drop_zone_ref = ref<HTMLDivElement>()

const onDrop = (dz_files: File[] | null) => {
  if (dz_files === null) return
  dz_files.forEach(addFile)
}

const { isOverDropZone } = useDropZone(drop_zone_ref, onDrop)

///////////////////////////////////////////////////////////////////////////////
// File dialog
///////////////////////////////////////////////////////////////////////////////

const {
  files: fd_files,
  open,
  reset,
} = useFileDialog({ accept: props.accept.join(','), multiple: props.multiple_files })

watch(fd_files, (fileList) => {
  const fileArray = Array.from(fileList) as File[]
  fileArray.forEach(addFile)
})

///////////////////////////////////////////////////////////////////////////////
// Sorting
///////////////////////////////////////////////////////////////////////////////

const sortable_ref = ref<HTMLElement | null>(null)

useSortable(sortable_ref, files, {
  handle: '.handle',
})

///////////////////////////////////////////////////////////////////////////////
// Fomatting
///////////////////////////////////////////////////////////////////////////////

const format_extension = (extension: string) => extension.substring(1).toUpperCase()

const formatted_accept = props.accept.map(format_extension).join(', ')
</script>
<template>
  <button
    class="flex h-64 w-full items-center justify-center rounded-md border-2 border-dashed border-gray-300 bg-white"
    :class="[
      disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer hover:border-gray-600',
      isOverDropZone ? 'border-gray-600' : '',
    ]"
    :disabled="disabled"
    ref="drop_zone_ref"
    type="button"
    @click="open"
  >
    <div class="text-center">
      <p class="text-gray-700">
        <span class="font-medium">Click to browse</span>
        <span> or drag and drop</span>
      </p>
      <p class="text-sm text-gray-500">
        {{ formatted_accept }} (&#60;{{ MAX_FILE_SIZE_HUMAN }} and &#60;{{ MAX_PAGES }} pages)
      </p>
    </div>
  </button>

  <div v-show="files.length || existing_files" class="mt-6">
    <div class="rounded-t-md border border-b-0 bg-gray-50 px-3 py-1 shadow-sm">
      <span class="text-xs font-semibold uppercase tracking-wide">Chosen file(s)</span>
    </div>
    <div>
      <!-- error drawer -->
    </div>

    <!-- file list -->
    <ul ref="sortable_ref" class="rounded-b-md border border-gray-200 text-sm text-gray-600" v-if="files.length">
      <li
        v-for="(file, index) in files"
        :key="file.name"
        class="flex items-center space-x-2 border-b border-gray-100 px-1.5 py-1.5 last:border-none"
      >
        <!-- prettier-ignore -->
        <VButton
          class="handle"
          cursor="move"
          :disabled="disabled"
          size="xs-narrow" 
          variant="invisible"
        >
          <span class="text-gray-400">
            <VIcon name="move" />
          </span>
        </VButton>
        <div class="flex-grow text-sm">{{ file.name }}</div>
        <!-- prettier-ignore -->
        <VButton
          @click="removeFile(index)"
          :disabled="disabled"
          size="xs-narrow"
          variant="invisible"
        >
          <VIcon name="x" />
        </VButton>
      </li>
    </ul>

    <ul class="rounded-b-md border border-gray-200 text-sm text-gray-600" v-if="existing_files && !files.length">
      <li
        v-for="(file, index) in existing_files"
        :key="file"
        class="flex items-center space-x-2 border-b border-gray-100 py-1.5 pl-3 pr-1.5 last:border-none"
      >
        <div class="flex-grow text-sm">{{ file }}</div>
      </li>
    </ul>
  </div>
</template>
