import { readonly, ref } from 'vue'

export function useSort({ key, order }: { key: string; order?: 'asc' | 'desc' }) {
  const sortKey = ref(key)
  const sortOrder = ref(order || 'asc')

  function sort(key: string) {
    sortOrder.value = sortKey.value === key && sortOrder.value === 'asc' ? 'desc' : 'asc'
    sortKey.value = key
  }

  return {
    sortKey: readonly(sortKey),
    sortOrder: readonly(sortOrder),
    sort,
  }
}
