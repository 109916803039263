<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useMagicKeys } from '@vueuse/core'
import { RouterLinkBack, VButton, VButtonInvisible, VSection } from '@/modules/shared/components'
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import SubscriptionCard from '../components/subscription-card.vue'
import ViewInStripeButton from '../components/view-in-stripe-button.vue'
import { useCustomerStore } from '../stores/customer-store'
import { to_date } from '../utils'

const route = useRoute()
const router = useRouter()
const customerStore = useCustomerStore()

///////////////////////////////////////////////////////////////////////////////
// Keyboard shortcuts
///////////////////////////////////////////////////////////////////////////////

const { escape } = useMagicKeys()

watch(escape, (v) => {
  if (v) {
    router.push({ name: 'billing-group' })
  }
})

///////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////

const ready = ref(false)
const subscription = ref(null)
const subscription_id = route.params.subscription_id as string

const assignDefaults = () => {
  ready.value = true
  subscription.value = customerStore.subscriptions[subscription_id]
}

onMounted(async () => {
  // removes page flicker when returning to this page
  if (customerStore.customer !== null) {
    assignDefaults()
  }

  // implement promise.all to allow for parallel requests
  // prettier-ignore
  await Promise.all([
    customerStore.retrieve(),
  ])

  assignDefaults()
})

const submit = async () => {
  await customerStore.cancel_subscription(subscription_id)

  router.push({ name: 'billing-group' })
}
</script>

<template>
  <TheLayout>
    <div v-if="ready">
      <!-- nav -->
      <VSection>
        <div class="flex items-center justify-between">
          <div>
            <RouterLink :to="{ name: 'billing-group' }">
              <VButtonInvisible>← Back</VButtonInvisible>
            </RouterLink>
          </div>
          <div>
            <ViewInStripeButton
              :livemode="customerStore.customer.livemode"
              :resource="{ subscription: subscription_id }"
            />
          </div>
        </div>
      </VSection>
      <!-- main -->
      <VSection label="Cancel Plan">
        <SubscriptionCard :actions="false" :subscription="subscription" />
        <div class="mb-1 mt-6">
          <p>
            Your plan will be canceled, but is still available until the end of your billing period on
            {{ to_date(subscription.current_period_end) }}
          </p>
        </div>
        <div class="mb-6">
          <p>If you change your mind, you can renew your subscription.</p>
        </div>
        <div class="space-x-2">
          <VButton variant="primary" :click="submit">Yes, I'd like to cancel my plan</VButton>
          <RouterLinkBack>
            <VButton>Go back</VButton>
          </RouterLinkBack>
        </div>
      </VSection>
    </div>
  </TheLayout>
</template>
