<script setup lang="ts">
import { VIcon } from '../components'
import { useNotificationStore } from '../stores/notification-store'
const notificationStore = useNotificationStore()
</script>

<template>
  <div class="absolute inset-x-0 top-5 z-[99999] flex w-full justify-center">
    <div class="max-w-96 space-y-1">
      <transition-group name="fade-slide-scale" tag="div">
        <div
          v-for="notification in notificationStore.notifications"
          :key="notification.id"
          class="flex w-[32rem] items-center px-3 py-1.5 pr-2 leading-none text-[#14222d]/90 backdrop-blur lg:inline-flex lg:rounded"
          :class="{
            'bg-[#7ABCBF]/50': notification.type === 'success',
            'bg-[#FECACA]/50': notification.type === 'error',
            'bg-[#FDE68A]/50': notification.type === 'warning',
            'bg-[#93C5FD]/50': notification.type === 'info',
          }"
          role="alert"
        >
          <div class="mr-2 flex-auto text-sm font-medium">{{ notification.message }}</div>
          <div
            @click="() => notificationStore.remove(notification.id)"
            class="cursor-pointer rounded-sm p-1 hover:bg-white/20"
          >
            <VIcon name="x" />
          </div>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<style scoped>
.fade-slide-scale-enter-active {
  transition:
    opacity 0.5s,
    transform 0.5s;
}
.fade-slide-scale-leave-active {
  transition:
    opacity 0.2s,
    transform 0.2s;
}
.fade-slide-scale-enter-from,
.fade-slide-scale-leave-to {
  opacity: 0;
  transform: translateY(-60px) scale(1);
}
.fade-slide-scale-enter-to,
.fade-slide-scale-leave-from {
  opacity: 1;
  transform: translateY(0) scale(1);
}
.transition-item {
  transform-origin: top;
}
</style>
