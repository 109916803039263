import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useFetch } from '@/modules/shared/composables/use-fetch'
import { clearItems } from '@/modules/shared/utils/store'
import { CID } from '@/modules/shared/utils/store.types'

///////////////////////////////////////////////////////////////////////////////
// Types
///////////////////////////////////////////////////////////////////////////////

type Permission = 'admin' | 'investor'

///////////////////////////////////////////////////////////////////////////////
// Store
///////////////////////////////////////////////////////////////////////////////

type VehicleMap = Map<CID, Permission>

export const useInvestingVehicleStore = defineStore('investing/vehicleStore', () => {
  const route = useRoute()
  const baseUrl = computed(() => `/${route.params.slug}/investing`)

  const items = ref<VehicleMap>(new Map())
  const vehicles = computed(() => items.value)

  const fetchVehicles = async () => {
    clearItems(items)

    const { data, error } = await useFetch(`${baseUrl.value}/vehicles`).get().json<{ data: CID[] }>()

    if (error.value) {
      // TODO: handle error (e.g., display a message to the user)
      console.error(error.value)
      return
    }

    items.value = new Map(Object.entries(data.value.data as unknown as VehicleMap))
  }

  return {
    items,
    vehicles,

    fetchVehicles,
  }
})
