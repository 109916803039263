<script setup lang="ts">
import { useRoute } from 'vue-router'
import { rails_url } from '@/modules/shared/utils/rails'
import { VButton, VButtonInvisible, VSection } from '@/modules/shared/components'
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import TemplateForm from '../components/template_form.vue'
import state from '../state'
import { useTemplateStore } from '../stores/template-store'
import { createTemplateable } from '../utils/create-templateable'

///////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////

const route = useRoute()
const templateStore = useTemplateStore()
const templateable = createTemplateable(route)

// set initial state of the template
state.template = {
  id: null,
  name: null,
  title: null,
  message: null,
  file_url: [],
  hellosign_template_id: null,
  hellosign_template_cache: null,
}

const redirect_url =
  templateable.type === 'group'
    ? `${rails_url(route.params.slug)}/group/documents`
    : `${rails_url(route.params.slug)}/funds/${templateable.id}/documents`
</script>

<template>
  <TheLayout>
    <VSection>
      <div class="flex items-center justify-between">
        <div>
          <a :href="redirect_url">
            <VButtonInvisible>← Back</VButtonInvisible>
          </a>
        </div>
        <div>
          <a :href="redirect_url">
            <VButton>Cancel</VButton>
          </a>
        </div>
      </div>
    </VSection>
    <TemplateForm :template="state.template" :save_function="templateStore.create" :redirect_url="redirect_url" />
  </TheLayout>
</template>
