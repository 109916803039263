import { reverse } from 'lodash'

export type TemporalData = {
  [index: number]: any
}

function locateData(temporalData: TemporalData, date: number) {
  if (Object.keys(temporalData).length === 0) return null

  for (const key of reverse(Object.keys(temporalData).map((key) => parseInt(key, 10)))) {
    if (key < date) {
      return temporalData[key]
    }
  }

  return null
}

export { locateData }
