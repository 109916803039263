<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useMagicKeys } from '@vueuse/core'
import { RouterLinkBack, VButton, VButtonInvisible, VSection } from '@/modules/shared/components'
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import PaymentMethodCard from '../components/payment-method-card.vue'
import { useCustomerStore } from '../stores/customer-store'

const route = useRoute()
const router = useRouter()
const customerStore = useCustomerStore()

///////////////////////////////////////////////////////////////////////////////
// Keyboard shortcuts
///////////////////////////////////////////////////////////////////////////////

const { escape } = useMagicKeys()

watch(escape, (v) => {
  if (v) {
    router.push({ name: 'billing-group' })
  }
})

///////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////

const ready = ref(false)
const payment_method = ref(null)

const assignDefaults = () => {
  ready.value = true
  payment_method.value = customerStore.payment_methods[route.params.payment_method_id as string]
}

onMounted(async () => {
  // removes page flicker when returning to this page
  if (customerStore.customer !== null) {
    assignDefaults()
  }

  // implement promise.all to allow for parallel requests
  // prettier-ignore
  await Promise.all([
    customerStore.retrieve(),
  ])

  assignDefaults()
})

const submit = async () => {
  await customerStore.detach_payment_method(route.params.payment_method_id as string)
  const url = window.location.pathname.split('payment-method')[0]
  router.push(url)
}
</script>

<template>
  <TheLayout>
    <div v-if="ready">
      <!-- nav -->
      <VSection>
        <RouterLinkBack>
          <VButtonInvisible>← Back</VButtonInvisible>
        </RouterLinkBack>
      </VSection>
      <!-- main -->
      <VSection label="Remove payment method">
        <PaymentMethodCard :actions="false" :payment_method="payment_method" />
        <div class="my-6">
          <p>
            Your payment method will be removed, but please be aware this may impact our ability to collect payment,
            which could result in subscription delays and limited access to certain features.
          </p>
        </div>
        <div class="space-x-2">
          <VButton variant="primary" :click="submit">Yes, I'd like to remove my payment method</VButton>
          <RouterLinkBack>
            <VButton>Go back</VButton>
          </RouterLinkBack>
        </div>
      </VSection>
    </div>
  </TheLayout>
</template>
