import { createService } from '@/modules/shared/utils/service'

export const useEntitySyncService = createService({
  base_url: `${import.meta.env.VITE_VENTURE360_API_URL}/:slug/tax`,
  // prettier-ignore
  headers: {
    'Accept': 'application/json',
    'Authorization': `Bearer `,
    'Content-Type': 'application/json',
  },
  endpoints: [
    {
      method: 'POST',
      name: 'create_efile',
      url: '/:id/create-efile',
    },
    {
      method: 'POST',
      name: 'download_return',
      url: '/:id/download-return',
    },
    {
      method: 'POST',
      name: 'import_entity',
      url: '/:id/import-entity',
    },
    {
      method: 'POST',
      name: 'print_return',
      url: '/:id/print-return',
    },
    {
      method: 'GET',
      name: 'efiles_validation',
      url: '/:id/efiles-validation',
    },
  ],
})
