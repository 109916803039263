import Big from 'big.js'
import { assertNumeric } from '../math'

export function rvpi(args: { called: number; current_value: number }) {
  const { called, current_value } = args

  assertNumeric(called)
  assertNumeric(current_value)

  if (called === 0) {
    return 0.0
  }

  // prettier-ignore
  return Big(current_value)
    .div(called)
    .toNumber()
}
