<script setup lang="ts">
import { capitalize } from 'lodash'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { TheContent, VDescriptionList, VSection, PortfolioSection } from '@/modules/shared/components'
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import OtherEntityNav from '../components/other-entity-nav.vue'
import OtherEntityHeader from '../components/other-entity-header.vue'
import { useOtherEntityStore } from '../stores/other-entity-store'
import { format, parse } from '@/modules/shared/utils/v-table'
import { usePortfolioStore } from '../stores/portfolio-store'
import { useEntityStore } from '@/modules/investing/stores/entity-store'
import { VButton } from '@/modules/shared/components'
import AllEntitiesTable from '../components/all-entities-table.vue'

const { n, t } = useI18n()

const route = useRoute()
const otherEntityStore = useOtherEntityStore()
const portfolioStore = usePortfolioStore()
const entityStore = useEntityStore()

const cid = computed(() => `funding-entity:${route.params.other_entity_id}`)
const currentOtherEntity = computed(() => otherEntityStore.items.get(cid.value))
const other_entities = computed(() =>
  otherEntityStore.other_entities.filter((other_entity) => other_entity.id !== currentOtherEntity.value?.id),
)
const id = route.params.other_entity_id
const skeleton = ref(true)

const investments = computed(() => portfolioStore.investments)

onMounted(async () => {
  entityStore.reset()
  await Promise.all([
    otherEntityStore.fetchOtherEntities('funding-entity', route.params.other_entity_id),
    portfolioStore.fetchPortfolio({ _cid: `funding-entity:${route.params.other_entity_id}`, as_of_date: new Date() }),
  ])
  // we separate this because of the fetchOtherEntities call above, it resets the list and it removes the current other entity
  await otherEntityStore.fetchOtherEntity(`${id}`)

  for (const entity_cid of currentOtherEntity.value.entities) {
    const type = entity_cid.split(':')[0]
    const id = entity_cid.split(':')[1]
    await entityStore.fetchEntity({ type, id })
  }
  skeleton.value = false
})
</script>

<template>
  <TheLayout>
    <OtherEntityHeader />
    <OtherEntityNav />
    <TheContent>
      <template #main>
        <PortfolioSection
          :is_portfolio_view_visible="false"
          :skeleton="skeleton"
          :investments="investments"
          :stack="true"
          :display_charts="false"
        />

        <AllEntitiesTable
          :cid="cid"
          :filterKeys="[cid]"
          :skeleton="skeleton"
          :funds="entityStore.funds"
          :spvs="entityStore.spvs"
          :other_entities="other_entities"
        />
      </template>
      <template #aside>
        <VSection :label="capitalize(t('shared.general information'))">
          <template #actions>
            <RouterLink :to="{ name: 'investing.other-entity.edit', hash: '#general-information' }">
              <VButton size="xs">{{ capitalize(t('shared.edit')) }}</VButton>
            </RouterLink>
          </template>
          <template #default>
            <VDescriptionList
              class="mb-1"
              :items="[
                {
                  term: capitalize(t('shared.admin', 0)),
                  description: null,
                },
              ]"
              :layout="currentOtherEntity?.admins?.length > 1 ? 'stacked' : 'adjacent'"
              :skeleton="skeleton"
            >
              <template #description="{ item }">
                <span v-for="(admin, index) in currentOtherEntity?.admins" :key="admin.id">
                  <RouterLink
                    class="text-[#3b88af] underline decoration-[#3b88af]/50 hover:text-gray-900 hover:decoration-gray-900/50"
                    :to="{ name: 'investing.individual-overview', params: { individual_id: admin.id } }"
                  >
                    {{ admin.name }}
                  </RouterLink>
                  <span v-if="index !== currentOtherEntity?.admins.length - 1">, </span>
                </span>
              </template>
            </VDescriptionList>
            <VDescriptionList
              class="mb-1"
              :items="[
                {
                  term: capitalize(t('shared.type')),
                  description: currentOtherEntity?.type,
                },
                {
                  term: capitalize(t('shared.date established')),
                  description: format(currentOtherEntity?.date, 'date'),
                },
                {
                  term: t('shared.EIN'),
                  description: currentOtherEntity?.tax_id,
                },
                {
                  term: capitalize(t('shared.currency')),
                  description: currentOtherEntity?.currency,
                },
              ]"
              :skeleton="skeleton"
            />
            <VDescriptionList
              class="mb-1"
              :items="[
                {
                  term: capitalize(t('shared.address')),
                  description: currentOtherEntity?.address,
                },
              ]"
              layout="stacked"
              :skeleton="skeleton"
            >
              <template #description="{ item }">
                <div>
                  <span v-if="item.description.line1">{{ item.description.line1 }}, </span>
                  <span v-if="item.description.line2">{{ item.description.line2 }}, </span>
                  <span v-if="item.description.city">{{ item.description.city }}, </span>
                  <span v-if="item.description.state">{{ item.description.state }}, </span>
                  <span v-if="item.description.postal_code">{{ item.description.postal_code }}, </span>
                  <span v-if="item.description.country">{{ item.description.country }}</span>
                </div>
              </template>
            </VDescriptionList>
          </template>
        </VSection>
        <VSection :label="capitalize(t('shared.structure'))">
          <template #actions>
            <RouterLink :to="{ name: 'investing.other-entity.edit', hash: '#structure' }">
              <VButton size="xs">{{ capitalize(t('shared.edit')) }}</VButton>
            </RouterLink>
          </template>
          <template #default>
            <VDescriptionList
              class="mb-1"
              :items="[
                {
                  term: capitalize(t('shared.management fee')),
                  description: n(parse(currentOtherEntity?.management_fee_percentage || 0, 'number') / 100, 'percent'),
                },
                {
                  term: capitalize(t('shared.carried interest')),
                  description: n(parse(currentOtherEntity?.carried_interest || 0, 'number') / 100, 'percent'),
                },
              ]"
              :skeleton="skeleton"
            />
          </template>
        </VSection>
        <VSection :label="capitalize(t('shared.disbursement preference'))">
          <template #actions>
            <RouterLink :to="{ name: 'investing.other-entity.edit', hash: '#disbursement-preference' }">
              <VButton size="xs">{{ capitalize(t('shared.edit')) }}</VButton>
            </RouterLink>
          </template>
          <template #default>
            <VDescriptionList
              class="mb-1"
              v-if="currentOtherEntity?.disbursement_method === 'wire'"
              :items="[
                {
                  term: capitalize(t('shared.method')),
                  description: currentOtherEntity?.disbursement_method,
                },
                {
                  term: capitalize(t('shared.bank name')),
                  description: currentOtherEntity?.disbursement_wire_bank_name,
                },
                {
                  term: capitalize(t('shared.bank address')),
                  description: currentOtherEntity?.disbursement_wire_bank_address,
                },
                {
                  term: capitalize(t('shared.bank routing number')),
                  description: currentOtherEntity?.disbursement_wire_bank_routing_number,
                },
                {
                  term: capitalize(t('shared.bank account number')),
                  description: currentOtherEntity?.disbursement_wire_bank_account_number,
                },
                {
                  term: capitalize(t('shared.bank swift code')),
                  description: currentOtherEntity?.disbursement_wire_bank_swift_code,
                },
                {
                  term: capitalize(t('shared.account name')),
                  description: currentOtherEntity?.disbursement_wire_account_name,
                },
                {
                  term: capitalize(t('shared.for further credit to')),
                  description: currentOtherEntity?.disbursement_wire_for_further_credit_to,
                },
              ]"
              :skeleton="skeleton"
            />
            <VDescriptionList
              class="mb-1"
              v-else-if="currentOtherEntity?.disbursement_method === 'check'"
              :items="[
                {
                  term: capitalize(t('shared.method')),
                  description: currentOtherEntity?.disbursement_method,
                },
                {
                  term: capitalize(t('shared.check payable to')),
                  description: currentOtherEntity?.disbursement_check_receiver_name,
                },
              ]"
              :skeleton="skeleton"
            />
            <VDescriptionList
              class="mb-1"
              v-else-if="currentOtherEntity?.disbursement_method === 'other'"
              :items="[
                {
                  term: capitalize(t('shared.method')),
                  description: currentOtherEntity?.disbursement_method,
                },
                {
                  term: capitalize(t('shared.other detail', 0)),
                  description: currentOtherEntity?.disbursement_other_details,
                },
              ]"
              :skeleton="skeleton"
            />
            <div class="" v-else>Not setup</div>
          </template>
        </VSection>
      </template>
    </TheContent>
  </TheLayout>
</template>
