import { createHead } from '@vueuse/head'

const head = createHead()

// if (import.meta.env.PROD) {
//   head.push({
//     meta: [
//       {
//         'http-equiv': 'Content-Security-Policy',
//         content: `default-src 'self' https://rsms.me ${import.meta.env.VITE_VENTURE360_API_URL}`,
//       },
//     ],
//   })
// }

export default head
