import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useFetch } from '@/modules/shared/composables/use-fetch'
import { Money } from '@/modules/shared/utils/money'
import { addItems, clearItems } from '@/modules/shared/utils/store'
import { CID } from '@/modules/shared/utils/store.types'

///////////////////////////////////////////////////////////////////////////////
// Types
///////////////////////////////////////////////////////////////////////////////

type ID = number | string

type Contact = {
  id: ID
  date: string
  email: Money
  name: any
}

///////////////////////////////////////////////////////////////////////////////
// Store
///////////////////////////////////////////////////////////////////////////////

type ContactMap = Map<CID, Contact>

export const useContactStore = defineStore('investing/contactStore', () => {
  const route = useRoute()
  const baseUrl = computed(() => `/${route.params.slug}/investing/individual/${route.params.individual_id}`)

  const items = ref<ContactMap>(new Map())
  const contacts = computed(() => Array.from(items.value.keys()).map((key) => items.value.get(key)))

  const fetchContacts = async () => {
    const { data, error } = await useFetch(`${baseUrl.value}/contacts`).get().json<{ data: Contact[] }>()

    if (error.value) {
      // TODO: handle error (e.g., display a message to the user)
      console.error(error.value)
      return
    }

    clearItems(items)
    addItems(
      items,
      data.value.data.map((contact) => ({ ...contact, _cid: contact.id })),
    )
  }

  const addContact = async (payload) => {
    await useFetch(`${baseUrl.value}/contact/add`).post(payload)
    await fetchContacts()
  }

  const removeContact = async (contact_id: ID) => {
    await useFetch(`${baseUrl.value}/contact/${contact_id}/remove`).delete()
    await fetchContacts()
  }

  return {
    items,
    contacts,

    fetchContacts,
    addContact,
    removeContact,
  }
})
