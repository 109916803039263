<script setup lang="ts">
import { sum, toNumber, initialMoney, Money } from '@/modules/shared/utils/money'
import { moic, roi } from '@/modules/shared/utils/reporting'
import { computed, ref } from 'vue'
import { VSection, VSkeletonBar, VStats, VTabListSimple, VIcon } from '@/modules/shared/components'
import AllocationChart from './allocation-chart.vue'
import PerformerChart from './performer-chart.vue'
import { rails_url } from '@/modules/shared/utils/rails'
import { useExtendedI18n } from '@/i18n'
import { capitalize } from 'lodash'
import { useAuthStore } from '@/modules/auth/stores/auth-store'

const { n, t } = useExtendedI18n()

const props = withDefaults(
  defineProps<{
    investments: any[]
    skeleton: boolean
    is_portfolio_view_visible?: boolean
    stack?: boolean
    display_charts?: boolean
    overview_display_data?: ('roi-moic' | 'cash_position-distribution' | 'distribution-accrued')[]
    cash_position?: Money
    no_data_config?: { message: string; display: boolean }
  }>(),
  {
    is_portfolio_view_visible: true,
    stack: false,
    display_charts: true,
    cash_position: initialMoney,
  },
)

const authStore = useAuthStore()
const current_user = computed(() => authStore.current_user)
const isAdmin = computed(() => current_user.value.role !== 'investor')
const investments = computed(() => props.investments)
const portfolioTabIndex = ref(0)

///////////////////////////////////////////////////////////////////////////////
// Portfolio Overview
///////////////////////////////////////////////////////////////////////////////

const currentValue = computed(() => sum(investments.value.map((i) => i.current_value)) || initialMoney)
const initialValue = computed(() => sum(investments.value.map((i) => i.initial_value)) || initialMoney)
const portfolioDistribution = computed(() => sum(investments.value.map((i) => i.disbursement)) || initialMoney)
const overview_display_data = computed(() => props.overview_display_data || ['roi-moic', 'distribution-accrued'])

const xroi = computed(() =>
  roi({
    current_value: toNumber(currentValue.value),
    distributed: toNumber(portfolioDistribution.value),
    initial_value: toNumber(initialValue.value),
  }),
)

const xmoic = computed(() =>
  moic({
    current_value: toNumber(currentValue.value),
    distributed: toNumber(portfolioDistribution.value),
    initial_value: toNumber(initialValue.value),
  }),
)

const accrued_interest = computed(() => sum(investments.value.map((i) => i.accrued_interest)) || initialMoney)

///////////////////////////////////////////////////////////////////////////////
// Allocations
///////////////////////////////////////////////////////////////////////////////]

const generateDataFromInvestments = (key) => {
  const data = {}
  investments.value.forEach((investment) => {
    let data_value
    if (!investment[key]) {
      if (!data['others']) data['others'] = { id: null, name: 'Other' }
      data_value = data['others']
    } else {
      data_value = data[investment[key].id]
      if (!data_value) data_value = { ...investment[key] }
    }
    const initial_value = data_value.initial_value || initialMoney
    const current_value = data_value.current_value || initialMoney
    const disbursement = data_value.disbursement || initialMoney

    data_value.initial_value = sum([initial_value, investment.initial_value])
    data_value.current_value = sum([current_value, investment.current_value])
    data_value.disbursement = sum([disbursement, investment.disbursement])

    if (!investment[key]) {
      data['others'] = data_value
    } else {
      data[investment[key].id] = data_value
    }
  })

  // calculate roi of company
  Object.keys(data).forEach((key) => {
    const data_value = data[key]
    data[key].roi = roi({
      current_value: toNumber(data_value.current_value),
      distributed: toNumber(data_value.disbursement),
      initial_value: toNumber(data_value.initial_value),
    })
  })

  return Object.values(data)
}
const companies = computed(() => generateDataFromInvestments('company'))
const industries = computed(() => generateDataFromInvestments('industry'))
const top_performers_items = computed(() =>
  companies.value
    .filter((company) => company.roi > 0)
    .sort((a, b) => b.roi - a.roi)
    .slice(0, 10),
)
</script>

<template>
  <div class="sm:flex" :class="{ 'sm:flex-col': stack, 'gap-10': !stack }">
    <VSection label="360° Portfolio Overview" class="flex flex-col" :class="{ 'sm:w-full': stack, 'sm:w-1/2': !stack }">
      <template #actions>
        <a
          :href="
            isAdmin ? `${rails_url()}/portfolio` : `${rails_url()}/investors/${current_user.investor_id}/investments`
          "
          class="text-[#3b88af] underline decoration-[#3b88af]/50 hover:text-gray-900 hover:decoration-gray-900/50"
          v-if="is_portfolio_view_visible"
        >
          View
        </a>
      </template>
      <div class="relative">
        <Transition
          enter-active-class="transition-opacity duration-250"
          enter-from-class="opacity-0"
          enter-to-class="opacity-100"
        >
          <div
            v-if="no_data_config?.display"
            class="absolute inset-px z-10 flex items-center justify-center rounded-lg bg-white/90 backdrop-blur-sm"
          >
            <div class="whitespace-pre-line text-center text-sm font-medium text-gray-700">
              {{ no_data_config.message }}
            </div>
          </div>
        </Transition>
        <div
          class="mb-8 flex flex-grow items-center justify-center gap-6 rounded-lg bg-[#3D8A9D] bg-opacity-10 py-4 dark:border-[#161618] dark:bg-[#1B1B1F] sm:gap-10 sm:py-8"
        >
          <div class="text-center">
            <p class="sm:text-basee mb-1 whitespace-nowrap text-sm font-medium text-gray-700">
              {{ capitalize(t('shared.initial value')) }}
            </p>
            <h3 class="text-lg font-medium tracking-tight text-[#336f8f] sm:text-2xl">
              <span v-if="skeleton"><VSkeletonBar class="inline-block" /></span>
              <span v-else>{{ n(initialValue, 'currency', null, true) }}</span>
            </h3>
          </div>
          <VIcon class="w-8 text-[#336f8f]" name="arrow_narrow_right" />
          <div class="text-center">
            <p class="sm:text-basee mb-1 whitespace-nowrap text-sm font-medium text-gray-700">
              {{ capitalize(t('shared.current value')) }}
            </p>
            <h3 class="text-lg font-medium tracking-tight text-[#336f8f] sm:text-2xl">
              <span v-if="skeleton"><VSkeletonBar class="inline-block" /></span>
              <span v-else>{{ n(currentValue, 'currency', null, true) }}</span>
            </h3>
          </div>
        </div>
        <div>
          <VStats
            v-if="overview_display_data.includes('roi-moic')"
            rounded="top"
            :skeleton="skeleton"
            :stats="[
              {
                colspan: 2,
                label: t('shared.ROI'),
                type: 'percent',
                value: xroi,
              },
              {
                colspan: 2,
                label: t('shared.MOIC'),
                type: 'multiple',
                value: xmoic,
              },
            ]"
          />
          <VStats
            v-if="overview_display_data.includes('distribution-accrued')"
            class="-mt-px"
            rounded="bottom"
            :skeleton="skeleton"
            :stats="[
              {
                colspan: 2,
                label: capitalize(t('shared.distribution', 0)),
                type: 'currency',
                value: portfolioDistribution,
              },
              {
                colspan: 2,
                label: capitalize(t('shared.accrued interest')),
                type: 'currency',
                value: accrued_interest,
              },
            ]"
          />
          <VStats
            v-if="overview_display_data.includes('cash_position-distribution')"
            class="-mt-px"
            rounded="bottom"
            :skeleton="skeleton"
            :stats="[
              {
                colspan: 2,
                label: capitalize(t('shared.cash position')),
                type: 'currency',
                value: cash_position,
              },
              {
                colspan: 2,
                label: capitalize(t('shared.distribution', 0)),
                type: 'currency',
                value: portfolioDistribution,
              },
            ]"
          />
        </div>
      </div>
    </VSection>

    <VSection :class="{ 'sm:w-full': stack, 'sm:w-1/2': !stack }" v-if="display_charts">
      <VTabListSimple
        @update:current="(index: number) => (portfolioTabIndex = index)"
        :current="portfolioTabIndex"
        :tabs="['Portfolio Allocation', 'Industry Allocation', 'Top Performers']"
        class="-mt-2"
      />
      <div class="mt-6 rounded-lg border border-gray-200 bg-white p-2 sm:p-6">
        <AllocationChart v-if="portfolioTabIndex === 0" :items="companies" :skeleton="skeleton" />
        <AllocationChart v-if="portfolioTabIndex === 1" :items="industries" :skeleton="skeleton" />
        <PerformerChart
          v-if="portfolioTabIndex === 2"
          :items="top_performers_items"
          :skeleton="skeleton"
          :total_roi="xroi"
        />
      </div>
    </VSection>
  </div>
</template>
