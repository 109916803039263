<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'
import { ref } from 'vue'

const props = defineProps({
  modalStore: {
    type: Object,
    required: true,
  },
})

const target = ref(null)
onClickOutside(target, (e) => props.modalStore.close())
</script>

<template>
  <div
    class="absolute left-0 top-0 z-[999999] h-screen w-screen bg-black/30"
    :class="{ hidden: !modalStore.state.active }"
  >
    <div class="absolute left-1/2 top-20 w-1/2 -translate-x-1/2 rounded-lg bg-white p-10" ref="target">
      <slot></slot>
    </div>
  </div>
</template>
