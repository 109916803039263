<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { capitalize } from 'lodash'
import { ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import {
  VTable,
  VButton,
  VModal,
  VSection,
  VTextField,
  VIcon,
  RouterLinkBack,
  VButtonInvisible,
} from '@/modules/shared/components'
import { useModal } from '@/modules/shared/composables/use-modal'
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import { useContactStore } from '../stores/contact-store'

const { t } = useI18n()
const modal = useModal()
const skeleton = ref(true)

const contactStore = useContactStore()

interface Contact {
  email: string | null
  name: string | null
}

const initialState: Contact = {
  email: null,
  name: null,
}

const loading = ref(false)
const contact = ref({ ...initialState })

const rules = {
  email: { email, required },
  name: { required },
}

const v$ = useVuelidate(rules, contact, { $lazy: true })

const showForm = () => {
  v$.value.$reset()
  contact.value = { ...initialState }
  modal.open()
}

async function addContact() {
  const valid = await v$.value.$validate()
  if (!valid) return

  loading.value = true
  await contactStore.addContact(contact.value)
  loading.value = false
  modal.close()
}

const removeContact = async (contact_id: number) => {
  if (!window.confirm('Are you sure?')) return
  await contactStore.removeContact(contact_id)
}

onMounted(async () => {
  await contactStore.fetchContacts()
  skeleton.value = false
})
</script>

<template>
  <TheLayout>
    <div class="-mt-8 mb-6 flex items-center justify-between">
      <div>
        <RouterLinkBack class="text-lg" :to="{ name: 'investing.individual.account' }">
          <VButtonInvisible>← {{ capitalize(t('shared.back')) }}</VButtonInvisible>
        </RouterLinkBack>
      </div>
      <VButton :click="showForm" size="md" variant="v-blue">
        <div class="mr-1 flex items-center space-x-2">
          <div><VIcon name="plus" /></div>
          <div>{{ capitalize(t('shared.add contact')) }}</div>
        </div>
      </VButton>
    </div>

    <VSection :label="capitalize(t('shared.contact', 0))">
      <VTable
        :columns="[
          {
            key: 'name',
            name: capitalize(t('shared.name')),
            type: 'string',
            align: 'left',
            fixed: true,
            is_visible: true,
          },
          {
            key: 'email',
            name: capitalize(t('shared.email')),
            type: 'string',
            align: 'left',
            is_visible: true,
          },
          {
            key: 'date',
            name: capitalize(t('shared.date added')),
            sorted: true,
            type: 'date',
            align: 'left',
            is_visible: true,
          },
          {
            key: 'actions',
            name: '',
            type: 'actions',
            align: 'right',
            is_visible: true,
          },
        ]"
        :items="contactStore.contacts"
        :skeleton="skeleton"
        name="individual-contacts"
        :slots="['actions']"
        no_data_message="Please add anyone here you would like copied on correspondence such as emails and invoices."
      >
        <template #actions="{ item }">
          <div class="flex space-x-1">
            <VButton :click="() => removeContact(item.id)" size="xs">
              {{ capitalize(t('shared.remove')) }}
            </VButton>
          </div>
        </template>
      </VTable>

      <VModal :modalStore="modal">
        <template #main>
          <VSection label="Add contact">
            <form @submit.prevent="addContact">
              <div class="mt-6 space-y-3">
                <VTextField
                  v-model="contact.name"
                  :label="capitalize(t('shared.name'))"
                  property="name"
                  ref="autofocus"
                  :v$="v$"
                />
                <VTextField v-model="contact.email" :label="capitalize(t('shared.email'))" property="email" :v$="v$" />
              </div>
              <button type="submit" class="hidden"></button>
              <!--- for some reason without this, the 'return' key doesnt trigger the form to submit -->
            </form>
          </VSection>
        </template>
        <template #footer>
          <div class="flex items-center justify-between space-x-3">
            <VButton :click="modal.close" size="lg">{{ capitalize(t('shared.close')) }}</VButton>
            <VButton :click="addContact" :loading="loading" class="w-full" size="lg" variant="primary">
              <span>{{ capitalize(t('shared.add contact')) }}</span>
            </VButton>
          </div>
        </template>
      </VModal>
    </VSection>
  </TheLayout>
</template>
