<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useMagicKeys } from '@vueuse/core'
import { RouterLinkBack, VButton, VButtonInvisible, VSection, VToggle } from '@/modules/shared/components'
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import ViewInStripeButton from '../components/view-in-stripe-button.vue'
import { useCustomerStore } from '../stores/customer-store'
import { useProductStore } from '../stores/product-store'

const route = useRoute()
const router = useRouter()
const customerStore = useCustomerStore()
const productStore = useProductStore()

///////////////////////////////////////////////////////////////////////////////
// Keyboard shortcuts
///////////////////////////////////////////////////////////////////////////////

const { escape } = useMagicKeys()

watch(escape, (v) => {
  if (v) {
    router.push({ name: 'billing-group' })
  }
})

///////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////

const ready = ref(false)
const subscription_id = route.params.subscription_id as string
const selected_interval = ref(null)
const selected_product = ref(null)
const selected_price = computed(() => {
  if (selected_product.value === null || selected_interval.value === null) {
    return null
  }

  // prettier-ignore
  const stripe_product = productStore
    .products
    .filter((product) => product.stripe_product_id === selected_product.value)[0]

  // prettier-ignore
  return stripe_product
    .stripe_prices.filter((price) => price.recurring_interval === selected_interval.value)[0]
    .stripe_price_id
})

const assignDefaults = () => {
  ready.value = true
  selected_interval.value = customerStore.customer.stripe_subscriptions[0].items[0].price.recurring_interval
  selected_product.value = customerStore.customer.stripe_subscriptions[0].items[0].product.id
}

onMounted(async () => {
  // removes page flicker when returning to this page
  if (customerStore.customer !== null && productStore.products.length > 0) {
    assignDefaults()
  }

  // prettier-ignore
  await Promise.all([
    customerStore.retrieve(),
    productStore.list(),
  ])

  assignDefaults()
})

const submit = async () => {
  console.log('selected_interval', selected_interval.value)
  console.log('selected_price', selected_price.value)
  console.log('selected_product', selected_product.value)

  await customerStore.change_subscription({
    subscription_id: customerStore.customer.stripe_subscriptions[0].stripe_subscription_id,
    stripe_price_id: selected_price.value,
  })

  router.push({ name: 'billing-group' })
}
</script>

<template>
  <TheLayout>
    <div class="py-12 sm:mx-12" v-if="ready">
      <!-- nav -->
      <VSection>
        <VSection>
          <div class="flex items-center justify-between">
            <div>
              <RouterLink :to="{ name: 'billing-group' }">
                <VButtonInvisible>← Back</VButtonInvisible>
              </RouterLink>
            </div>
            <div>
              <ViewInStripeButton
                :livemode="customerStore.customer.livemode"
                :resource="{ subscription: subscription_id }"
              />
            </div>
          </div>
        </VSection>
      </VSection>
      <!-- main -->
      <VSection label="Change Plan">
        <VToggle
          class="-mt-4"
          :defaultOption="selected_interval"
          :options="[
            { label: 'Monthly', value: 'month' },
            { label: 'Annually', value: 'year' },
          ]"
          @select="(interval: string) => (selected_interval = interval)"
        />
        <div v-for="product in productStore.products">
          <h1 class="mb-1 text-xl text-gray-800">{{ product.name }}</h1>
          <div class="mb-6">
            <div v-for="price in product.stripe_prices" v-show="price.recurring_interval === selected_interval">
              {{
                (price.unit_amount / 100).toLocaleString('en-US', {
                  style: 'currency',
                  currency: price.currency.toUpperCase(),
                })
              }}
              {{ price.currency.toUpperCase() }} /
              {{ price.recurring_interval }}
            </div>
          </div>
          <div class="space-x-2">
            <VButton :click="submit" variant="primary">Yes, I'd like to change my plan</VButton>
            <RouterLinkBack>
              <VButton>Go back</VButton>
            </RouterLinkBack>
          </div>
        </div>
      </VSection>
    </div>
  </TheLayout>
</template>
