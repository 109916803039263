import * as Sentry from '@sentry/vue'

export const init = (app, router) => {
  Sentry.init({
    app,
    dsn: `${import.meta.env.VITE_SENTRY_DSN}`,
    environment: `${import.meta.env.VITE_SENTRY_ENVIRONMENT}`,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', `${import.meta.env.VITE_VENTURE360_API_URL}`],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}
