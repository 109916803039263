import { createService } from '@/modules/shared/utils/service'

export const useTemplateService = createService({
  base_url: `${import.meta.env.VITE_VENTURE360_API_URL}/:slug/signing/template`,
  // prettier-ignore
  headers: {
    'Accept': 'application/json',
    'Authorization': `Bearer `,
    'Content-Type': 'application/json',
  },
  endpoints: [
    {
      method: 'POST',
      name: 'generate_embed',
      url: '/generate-embed',
    },
    {
      method: 'GET',
      name: 'list',
      url: 's', // looks strange, but pluralizes the base_url
    },
    {
      method: 'GET',
      name: 'retrieve',
      url: '/:template_id',
    },
    {
      method: 'POST',
      name: 'create',
      url: '',
    },
    {
      method: 'POST',
      name: 'replaceTemplate',
      url: '/:template_id/replace',
    },
    {
      method: 'POST',
      name: 'duplicateTemplate',
      url: '/:template_id/duplicate',
    },
  ],
})
