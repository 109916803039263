import BillingEntityPage from '../pages/entity.vue'
import BillingGroupPage from '../pages/group.vue'
// Payment methods
import BillingAddPaymentMethodPage from '../pages/add-payment-method.vue'
import BillingRemovePaymentMethodPage from '../pages/remove-payment-method.vue'
// Subscriptions
import BillingCancelPlanPage from '../pages/cancel-plan.vue'
import BillingChangePlanPage from '../pages/change-plan.vue'
import BillingGroupSelectPlanPage from '../pages/select-plan.vue'

export default [
  {
    component: BillingGroupPage,
    name: 'billing-group',
    path: '/:slug/billing/group',
  },
  {
    component: BillingEntityPage,
    name: 'billing-entity',
    path: '/:slug/billing/:billable_type/:billable_id',
  },
  /////////////////////////////////////////////////////////////////////////////
  // Payment methods
  /////////////////////////////////////////////////////////////////////////////
  {
    component: BillingAddPaymentMethodPage,
    name: 'billing-entity-add-payment-method',
    path: '/:slug/billing/:billable_type/:billable_id/add-payment-method',
  },
  {
    component: BillingAddPaymentMethodPage,
    name: 'billing-group-add-payment-method',
    path: '/:slug/billing/group/add-payment-method',
  },
  {
    component: BillingRemovePaymentMethodPage,
    name: 'billing-entity-remove-payment-method',
    path: '/:slug/billing/:billable_type/:billable_id/payment-method/:payment_method_id/remove',
  },
  {
    component: BillingRemovePaymentMethodPage,
    name: 'billing-group-remove-payment-method',
    path: '/:slug/billing/group/payment-method/:payment_method_id/remove',
  },
  /////////////////////////////////////////////////////////////////////////////
  // Subscriptions
  /////////////////////////////////////////////////////////////////////////////
  {
    component: BillingCancelPlanPage,
    name: 'billing-cancel-plan',
    path: '/:slug/billing/subscription/:subscription_id/cancel',
  },
  {
    component: BillingChangePlanPage,
    name: 'billing-change-plan',
    path: '/:slug/billing/subscription/:subscription_id/change',
  },
  {
    component: BillingGroupSelectPlanPage,
    name: 'billing-group-select-plan',
    path: '/:slug/billing/group/select-plan',
  },
]
