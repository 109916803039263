<script setup lang="ts">
import { computed, onMounted, ref, toRaw } from 'vue'
import { useRoute } from 'vue-router'
import HelloSign from 'hellosign-embedded'
import { VButton, VIcon, VSection, VTextArea, VTextField } from '@/modules/shared/components'
import TheUploader from './the-uploader.vue'
import { createTemplateable } from '../utils/create-templateable'
import state from '../state'
import { useTemplateStore } from '../stores/template-store'
import { useUploader } from '../composables/use-uploader'

///////////////////////////////////////////////////////////////////////////////
// Config
///////////////////////////////////////////////////////////////////////////////

const hellosign_client_id = import.meta.env.VITE_HELLOSIGN_CLIENT_ID
const hellosign_test_mode = import.meta.env.VITE_HELLOSIGN_TEST_MODE
const transloadit_template_id = import.meta.env.VITE_TRANSLOADIT_TEMPLATE_ID

///////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////

const props = defineProps<{
  template: any
  save_function: any
  redirect_url: string
}>()

const route = useRoute()
const templateStore = useTemplateStore()
const templateable = createTemplateable(route)

const statuses = {
  idle: 0,
  uploading: 1,
  uploaded: 2,
  preparing: 3,
  prepared: 4,
  finalizing: 5,
  finalized: 6,
}

// set initial state of the template
state.template = { ...props.template }
const status = ref(statuses.idle)

// init ui state
state.ui = {
  show_hellosign_embed_target: false,
}

const is_field_disabled = ref(false)
const is_prepare_button_disabled = computed(() => {
  if (is_field_disabled.value) return is_field_disabled.value
  return (
    (uploader.state.files.selected.length === 0 || status.value >= statuses.uploading) &&
    props.template.file_url.length === 0
  )
})
const is_prepare_button_loading = computed(() => {
  return [statuses.uploading, statuses.uploaded, statuses.preparing].includes(status.value)
})
const is_finalize_button_disabled = computed(
  () => status.value < statuses.prepared && !props.template.hellosign_template_id,
)
const is_finalize_button_loading = computed(() => status.value === statuses.finalizing)
const is_template_prepared = ref(false)

///////////////////////////////////////////////////////////////////////////////
// Files
///////////////////////////////////////////////////////////////////////////////

const uploader = useUploader({
  accept: ['.pdf'],
  transloadit_template_id,
})

///////////////////////////////////////////////////////////////////////////////
// Misc.
///////////////////////////////////////////////////////////////////////////////

// request template edit_url from HelloSign via our server
const generateEmbedTemplateDraft = async () => {
  const template = {
    ...toRaw(state.template),
    files: uploader.state.files.uploaded.length > 0 ? null : 'merge-completed', // this should be array of file names, but since we only have 1 we don't use array
    file_url:
      uploader.state.files.uploaded.length > 0
        ? uploader.state.files.uploaded.map((file) => file.uploadURL)
        : state.template.file_url,
  }

  if (state.template.hellosign_template_cache) {
    template.form_field_groups = template.hellosign_template_cache.documents.flatMap((document) => {
      return document.field_groups.map((group) => {
        return {
          group_id: group.name,
          group_label: group.rule.groupLabel,
          requirement: group.rule.requirement,
        }
      })
    })

    template.form_fields_per_document = []

    template.hellosign_template_cache.documents.forEach((document, documentIndex) => {
      // Process custom fields
      document.custom_fields.forEach((f) => {
        template.form_fields_per_document.push({
          ...f,
          document_index: documentIndex,
          signer: 'sender',
          type: 'text-merge',
        })
      })

      // Process named form fields
      document.form_fields
        .filter((f) => f.type !== 'dropdown')
        .forEach((f) => {
          template.form_fields_per_document.push({
            ...f,
            document_index: documentIndex,
            signer: parseInt(f.signer) - 1,
          })
        })
    })

    template.signer_roles = state.template.hellosign_template_cache.signer_roles
  }

  const payload = {
    signing_template: {
      files: template.files,
      file_url: template.file_url,
      form_field_groups: template.form_field_groups,
      form_fields_per_document: template.form_fields_per_document,
      message: template.message,
      signer_roles: template.signer_roles,
      templateable_type: templateable.type,
      title: template.title,
    },
  }

  const res = await templateStore.generate_embed(payload)

  state.template.hellosign_template_id = res.template_id

  return res
}

const prepare = async () => {
  is_template_prepared.value = true

  if (status.value < statuses.uploading) {
    status.value = statuses.uploading
    await uploader.upload(uploader.state.files.selected)
    status.value = statuses.uploaded
  }

  /////////////////////////////////////////////////////////////////////////////
  // HelloSign stuff to refactor
  /////////////////////////////////////////////////////////////////////////////

  if (status.value < statuses.preparing) {
    status.value = statuses.preparing

    const res = await generateEmbedTemplateDraft()

    // open the modal
    state.ui.show_hellosign_embed_target = true

    // TODO refactor into separate file
    const hellosign_client = new HelloSign({
      clientId: hellosign_client_id,
      container: document.getElementById('hellosign-embed-target'),
      debug: Number(hellosign_test_mode) === 1 ? true : false,
    })

    hellosign_client.on('cancel', () => {
      state.ui.show_hellosign_embed_target = false
    })

    hellosign_client.on('finish', () => {
      state.ui.show_hellosign_embed_target = false
      status.value = statuses.prepared
    })

    hellosign_client.on('open', () => {})

    hellosign_client.open(res.edit_url, {
      skipDomainVerification: Number(hellosign_test_mode) === 1 ? true : false,
    })

    is_field_disabled.value = true
  }
}

const finalize = async () => {
  // if (status.value >= statuses.finalizing) {
  //   return
  // }

  status.value = statuses.finalizing

  const template = toRaw(state.template)
  const payload = {
    template_id: template.id,
    signing_template: {
      name: template.name,
      hellosign_template_id: template.hellosign_template_id,
      templateable_id: templateable.id,
      templateable_type: templateable.type,
    },
  }

  await props.save_function(payload)

  status.value = statuses.finalized
  window.location.href = props.redirect_url
}
</script>

<template>
  <VSection label="General information">
    <div class="flex items-start justify-between space-x-8">
      <aside class="w-2/5"></aside>
      <main class="w-3/5">
        <VTextField
          description="Give this template a unique name which best describes its purpose."
          label="Template name"
          placeholder="Template: Non-disclosure agreement (NDA)"
          :required="true"
          v-model="state.template.name"
          :disabled="is_field_disabled"
        />
        <VTextField
          class="mt-6"
          label="Document title"
          placeholder="Non-disclosure agreement (NDA)"
          :required="true"
          v-model="state.template.title"
          :disabled="is_field_disabled"
        />
        <VTextArea
          class="mt-6"
          label="Message"
          placeholder="Please review and sign this document."
          v-model="state.template.message"
          :disabled="is_field_disabled"
        />
      </main>
    </div>
  </VSection>

  <VSection label="Edit documents">
    <div class="flex items-start justify-between space-x-8">
      <aside class="w-2/5">
        <p class="text-gray-700">Edit documents or files in the order you'd like them to combine for signing.</p>
      </aside>
      <main class="w-3/5">
        <!-- prettier-ignore -->
        <TheUploader
          :accept="uploader?.props?.accept"
          :disabled="uploader.state.status !== 'idle' || is_field_disabled"
          @chosen="(f) => uploader.state.files.selected = f"
          :existing_files="props.template.file_url.length === 0 ? null : ['Original document(s)']"
        />
      </main>
    </div>
  </VSection>

  <VSection label="Prepare document">
    <div class="flex items-start justify-between space-x-8">
      <aside class="w-2/5 text-gray-700">
        <p>Add signing roles, arrange the signing order, and place fields you wish the signers to fill in.</p>
        <p class="mt-2">
          Certain fields will automatically populate when the cooresponding signer has the information saved to their
          profile.
        </p>
      </aside>
      <main class="w-3/5">
        <VButton @click="prepare" :disabled="is_prepare_button_disabled" :loading="is_prepare_button_loading" size="lg">
          <div class="flex items-center space-x-2">
            <span>Open and prepare the document</span>
            <VIcon name="link-external" />
          </div>
        </VButton>
      </main>
    </div>
  </VSection>

  <VSection label="Finalize">
    <div class="flex items-start justify-between space-x-8">
      <aside class="w-2/5">
        <p class="text-gray-700">This template will be available to use when requesting signatures in the future.</p>
      </aside>
      <main class="w-3/5">
        <!-- prettier-ignore -->
        <VButton
          :disabled="is_finalize_button_disabled"
          :loading="is_finalize_button_loading"
          size="lg"
          variant="primary"
          @click="finalize">Confirm Changes</VButton>
      </main>
    </div>
  </VSection>

  <div
    class="fixed inset-0 z-50 h-full w-full bg-white/20 backdrop-blur-sm"
    v-show="state.ui.show_hellosign_embed_target"
    @click="state.ui.show_hellosign_embed_target = false"
  >
    <div
      id="hellosign-embed-target"
      class="mx-auto h-screen max-w-5xl bg-white shadow-xl ring-1 ring-black/5"
      v-on:click.stop.prevent
    ></div>
  </div>
</template>
