import { getUnixTime, startOfDay } from 'date-fns'
import { omit, uniq } from 'lodash'
import { defineStore } from 'pinia'
import { computed, onMounted, reactive, watch, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
import { useAuthStore } from '@/modules/auth/stores/auth-store'
import { createQueryString } from '@/modules/shared/utils/routing'
import { useInvestorService } from '../services/api/investor-service'

export const useInvestorStore = () => {
  const store = defineStore('entity/investorStore', () => {
    const authStore = useAuthStore()
    const route = useRoute()

    const createConfig = () => {
      const entity_type = (route.params.entity_type || route.params.transacting_type) as string
      const entity_id = (route.params.entity_id || route.params.transacting_id) as string

      return {
        access_token: authStore.access_token,
        params: {
          slug: route.params.slug as string,
          entity_type: entity_type,
          entity_id: entity_id,
        },
      }
    }

    /////////////////////////////////////////////////////////////////////////////
    // STATE
    /////////////////////////////////////////////////////////////////////////////

    const state = reactive({
      items: {},
      profiles: {},
      selectedKeys: [],
      stats: {},
    })

    const isAllSelected = computed(() => {
      return state.selectedKeys.length === Object.keys(state.profiles).length
    })

    function createKey(item) {
      return `${item.ruby_type}:${item.id}`
    }

    function addItem(item: any) {
      state.items = { ...state.items, [item._cid]: item }
    }

    function addProfile(item: any) {
      state.profiles = { ...state.profiles, [item._cid]: item }
    }

    function addStat(item: any) {
      const id = createKey(item)
      const today = getUnixTime(startOfDay(new Date()))
      state.stats = { ...state.stats, [id]: { [today]: item } }
    }

    /////////////////////////////////////////////////////////////////////////////
    // Selected keys
    /////////////////////////////////////////////////////////////////////////////

    // function select(key: string) {
    //   state.selectedKeys = uniq([...state.selectedKeys, key]).sort()
    // }

    // function deselect(key: string) {
    //   state.selectedKeys = state.selectedKeys.filter((k) => k !== key)
    // }

    // function isSelected(key: string) {
    //   return state.selectedKeys.includes(key)
    // }

    // function toggle(key: string) {
    //   if (isSelected(key)) {
    //     deselect(key)
    //     return
    //   }

    //   select(key)
    // }

    function replaceQueryString(queryString: string) {
      window.history.replaceState(null, null, `?${queryString}`)
    }

    function splitQueryString(queryString?: string): string[] | null {
      if (queryString === null || queryString === undefined) {
        return null
      }

      if (queryString === '') {
        return []
      }

      return queryString.split(',')
    }

    function createQueryFromSelectedKeys(keys: string[], totalProfilesLength: number): Record<string, any> {
      if (keys.length === totalProfilesLength) {
        return omit(route.query, 'investors')
      }

      return { ...route.query, investors: keys.join(',') }
    }

    function setQueryParams(investor_keys: string[]) {
      const query = createQueryFromSelectedKeys(investor_keys, Object.keys(state.profiles).length)
      const queryString = createQueryString(query)
      replaceQueryString(queryString)
    }

    const reset = async () => {
      await listProfiles()

      // update query params when selected keys change, but only after the initial state has been set
      watch(
        () => state.selectedKeys,
        (keys: string[]) => setQueryParams(keys),
        { deep: true },
      )

      const availableKeys = Object.keys(state.profiles)
      const selectedKeys = splitQueryString(route.query.investors as string) || availableKeys
      state.selectedKeys = selectedKeys
    }

    // handle initial state from query params
    onMounted(() => reset())

    // keep selected keys sorted
    watchEffect(() => {
      state.selectedKeys = uniq(state.selectedKeys).sort()
    })

    /////////////////////////////////////////////////////////////////////////////
    // ACTIONS
    /////////////////////////////////////////////////////////////////////////////

    // used to fetch all possible investors for a given group
    // NOTE: this may not belong in this store
    async function list() {
      const serviceConfig = createConfig()
      const api = useInvestorService(serviceConfig)
      const [_err, data] = await api.list()

      data.data.forEach(addItem)
    }

    async function listProfiles() {
      const serviceConfig = createConfig()
      const api = useInvestorService(serviceConfig)
      const [_err, data] = await api.listProfiles()
      state.profiles = {}
      data.data.forEach(addProfile)
    }

    async function listStats() {
      const serviceConfig = createConfig()
      const api = useInvestorService(serviceConfig)
      const [_err, data] = await api.listStats()

      const today = startOfDay(new Date()).getTime()
      const items = data.data.reduce((acc, cur) => {
        return {
          ...acc,
          [cur._cid]: { [today]: cur },
        }
      }, {})

      state.stats = { ...items }

      // // prettier-ignore
      // const retrieveStats = Object.values(state.profiles).map(profile => retrieveStat({ investor_id: profile.id, investor_type: profile.type }))

      // // await Promise.all(retrieveStats)
      // console.log(state.stats)
    }

    async function retrieveStat({ investor_id, investor_type }) {
      const serviceConfig = createConfig()
      const api = useInvestorService(serviceConfig)
      const [_err, data] = await api.retrieveStat({ investor_id: investor_id, investor_type: investor_type })

      addStat(data.data)
    }

    /////////////////////////////////////////////////////////////////////////////
    // RETURN
    /////////////////////////////////////////////////////////////////////////////

    return {
      state: state,
      isAllSelected,
      list,
      listProfiles,
      listStats,
      reset,
      retrieveStat,
    }
  })

  return store()
}
