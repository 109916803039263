<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { RouterLinkBack, VButtonInvisible } from '@/modules/shared/components'
import { useOtherEntityStore } from '../stores/other-entity-store'
import { useAuthStore } from '@/modules/auth/stores/auth-store'

const route = useRoute()
const authStore = useAuthStore()
const otherEntityStore = useOtherEntityStore()
const currentOtherEntity = computed(() => otherEntityStore.items.get(`funding-entity:${id}`))

const id = route.params.other_entity_id

const back_url_config = computed(() => {
  if (!authStore.is_site_or_group_admin) {
    return {
      to: {
        name: 'dashboard',
      },
      fallback_url: {
        name: 'investing.other-entity.overview',
        params: {
          other_entity_id: id,
          slug: route.params.slug,
        },
      },
      label: `← Back`,
    }
  } else {
    return {
      to: {
        name: 'investing.other-entities',
        params: { slug: route.params.slug },
      },
      fallback_url: {
        name: 'investing.other-entity.overview',
        params: {
          other_entity_id: id,
          slug: route.params.slug,
        },
      },
      label: `← Back to all Associated Entities`,
    }
  }
})

onMounted(async () => {
  await Promise.all([otherEntityStore.fetchOtherEntity(`${id}`)])
})
</script>

<template>
  <header class="mb-6 px-4 sm:px-0">
    <div class="-mt-8 mb-6">
      <RouterLinkBack :to="back_url_config.to" :fallback="back_url_config.fallback_url">
        <VButtonInvisible>{{ back_url_config.label }} </VButtonInvisible>
      </RouterLinkBack>
    </div>
    <div class="flex flex-col-reverse sm:flex-row sm:items-start sm:justify-between">
      <div>
        <div class="mb-3 hidden text-sm font-medium text-[#3b88af] sm:block">Associated Entity</div>
        <h2 class="mb-2 text-3xl font-bold leading-7 text-gray-900 dark:text-gray-300">
          {{ currentOtherEntity?.name }}
        </h2>
      </div>
      <div class="mb-6 sm:mb-0">
        <div class="flex items-center justify-end space-x-2">
          <slot name="actions"></slot>
        </div>
      </div>
    </div>
  </header>
</template>
