import { reactive, ref, toRaw } from 'vue'

export const useModal = () => {
  const state = reactive({
    active: false,
  })

  const actions = {
    close() {
      state.active = false
    },

    open() {
      state.active = true
    },
  }

  return {
    state,
    ...actions,
  }
}
