<script setup lang="ts">
import { format } from 'date-fns'
import { capitalize, get } from 'lodash'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useAuthStore } from '@/modules/auth/stores/auth-store'
import { PortfolioSection, VButton, VIcon, VTextField, VDropdownImproved, VTable } from '@/modules/shared/components'
import { createOptions } from '@/modules/shared/utils/form'
import { sum, toNumber } from '@/modules/shared/utils/money'
import { rails_url } from '@/modules/shared/utils/rails'
import { roi } from '@/modules/shared/utils/reporting'

///////////////////////////////////////////////////////////////////////////////
// UTILS
///////////////////////////////////////////////////////////////////////////////

const generateDataFromInvestments = (key) => {
  const data = {}
  investments.value.forEach((investment) => {
    let data_value
    if (!investment[key]) {
      if (!data['others']) data['others'] = { id: null, name: 'Other' }
      data_value = data['others']
    } else {
      data_value = data[investment[key].id]
      if (!data_value) data_value = { ...investment[key] }
    }

    if (!investment[key]) {
      data['others'] = data_value
    } else {
      data[investment[key].id] = data_value
    }
  })

  return data
}

const filter_initial_state = {
  company_ids: [],
  industry_ids: [],
  investment_types: [],
  tags: [],
  methods: [],
  as_of_date: format(new Date(), 'yyyy-MM-dd'),
}

const getFilterLabel = (selected_items: any[], items_length: number) => {
  if (selected_items.length === items_length) return 'All'
  return selected_items.length
}

const setFilterAll = () => {
  filter.value = {
    company_ids: Object.keys(companies.value),
    industry_ids: Object.keys(industries.value),
    investment_types: Object.keys(investment_types.value),
    tags: Object.keys(tags.value),
    methods: methodOptions.value.map((option) => option.value),
    as_of_date: format(new Date(), 'yyyy-MM-dd'),
  }
}

const clearFilter = async () => {
  if (filter.value.as_of_date !== format(new Date(), 'yyyy-MM-dd')) {
    await updateInvestments()
  }
  setFilterAll()
}

const updateInvestments = async () => {
  const { call, payload } = props.modelValue.fetch
  if (!call) return
  internalSkeleton.value = true
  payload.as_of_date = filter.value.as_of_date
  await call(payload)
  internalSkeleton.value = false
}

///////////////////////////////////////////////////////////////////////////////

const authStore = useAuthStore()
const { t } = useI18n()

const filter = ref({ ...filter_initial_state })
const internalSkeleton = ref(false)

const props = withDefaults(
  defineProps<{
    modelValue: any
    cid: string
    investments: any[]
    is_column_method_visible?: boolean
    is_header_visible?: boolean
    skeleton: boolean
    title?: string
    is_company_link_disabled?: boolean
    customMethodOptions?: any[]
    filter_by_method?: boolean
  }>(),
  {
    is_column_method_visible: false,
    is_header_visible: true,
    filter_by_method: true,
  },
)

const emit = defineEmits(['update:modelValue'])

const skeleton = computed(() => props.skeleton || internalSkeleton.value)
const current_user = computed(() => authStore.current_user)
const isAdmin = computed(() => current_user.value.role !== 'investor')
const investments = computed(() => props.investments)

const customMethodFilter = (investment) => {
  if (props.cid.split(':')[0] === 'individual' && filter.value.methods.includes(props.cid)) {
    const disabled_cids = props.customMethodOptions
      .filter((option) => !filter.value.methods.includes(option.value))
      .map((option) => option.value)

    return !disabled_cids.includes(investment.investor_cid)
  }
  return filter.value.methods.includes(investment.investor_cid)
}

const filtered_investments = computed(() => {
  let filtered_data = investments.value

  if (filter.value.company_ids.length !== Object.keys(companies.value).length) {
    filtered_data = filtered_data.filter((investment) =>
      filter.value.company_ids.includes(investment.company.id.toString()),
    )
  }

  if (filter.value.industry_ids.length !== Object.keys(industries.value).length) {
    filtered_data = filtered_data.filter((investment) =>
      filter.value.industry_ids.includes(investment.industry?.id.toString() || 'others'),
    )
  }

  if (filter.value.investment_types.length !== Object.keys(investment_types.value).length) {
    filtered_data = filtered_data.filter((investment) =>
      filter.value.investment_types.includes(investment.investment_type),
    )
  }

  if (filter.value.tags.length !== Object.keys(tags.value).length) {
    filtered_data = filtered_data.filter((investment) =>
      investment.company.tags.some((tag) => filter.value.tags.includes(tag.id.toString())),
    )
  }

  if (filter.value.methods.length !== methodOptions.value.length && is_method_visible.value && props.filter_by_method) {
    filtered_data = filtered_data.filter((investment) => {
      if (props.customMethodOptions) return customMethodFilter(investment)
      return filter.value.methods.includes(investment.method_label)
    })
  }

  return filtered_data
})

const companies = computed((): any => generateDataFromInvestments('company'))
const industries = computed((): any => generateDataFromInvestments('industry'))
const investment_types = computed((): any => {
  return {
    equity: { name: 'Equity' },
    convertible_note: { name: 'Convertible Note' },
    revenue_share: { name: 'Revenue Share' },
    safe_note: { name: 'Safe Note' },
    loan: { name: 'Loan' },
    option: { name: 'Option' },
    warrant: { name: 'Warrant' },
  }
})

const tags = computed((): any =>
  Object.values(companies.value)
    .map((company: any) => company.tags)
    .flat()
    .reduce((acc, tag: any) => {
      acc[tag.id] = tag
      return acc
    }, {}),
)

const is_method_visible = computed(() =>
  ['individual', 'funding-entity', 'spv', 'fund', 'gp'].includes(props.cid.split(':')[0]),
)
const methods = computed((): any =>
  investments.value.reduce((acc, investment_or_term_sheet: any) => {
    acc[investment_or_term_sheet.method_label] = { name: investment_or_term_sheet.method_label }
    return acc
  }, {}),
)

const table_investments = computed(() => {
  const data = {}
  filtered_investments.value.forEach((investment) => {
    const company = investment.company
    const investment_data = {
      ...investment,
      name: investment.term_sheet?.name,
      roi: roi({
        current_value: toNumber(investment.current_value),
        distributed: toNumber(investment.disbursement),
        initial_value: toNumber(investment.initial_value),
      }),
    }
    if (investment.current_shares && investment.total_shares && parseFloat(investment.total_shares) !== 0) {
      investment_data.ownership_percent = investment.current_shares / investment.total_shares
    }

    // initialize company
    if (!data[company.id]) {
      data[company.id] = {
        ...company,
        accrued_interest: investment.accrued_interest,
        disbursement: investment.disbursement,
        current_price: investment.current_price,
        current_shares: investment.current_shares,
        current_value: investment.current_value,
        initial_capital: investment.initial_capital,
        initial_price: investment.initial_price,
        initial_shares: investment.initial_shares,
        initial_value: investment.initial_value,
        investments: [investment_data],
        minimum_annual_return: investment.minimum_annual_return,
      }
    } else {
      data[company.id].accrued_interest = sum([data[company.id].accrued_interest, investment.accrued_interest])
      data[company.id].disbursement = sum([data[company.id].disbursement, investment.disbursement])
      data[company.id].current_shares = data[company.id].current_shares + investment.current_shares
      data[company.id].current_value = sum([data[company.id].current_value, investment.current_value])
      data[company.id].initial_capital = sum([data[company.id].initial_capital, investment.initial_capital])
      data[company.id].initial_shares = data[company.id].initial_shares + investment.initial_shares
      data[company.id].initial_value = sum([data[company.id].initial_value, investment.initial_value])
      data[company.id].investments.push(investment_data)
      data[company.id].minimum_annual_return = sum([
        data[company.id].minimum_annual_return,
        investment.minimum_annual_return,
      ])
    }

    // compute company roi
    data[company.id].roi = roi({
      current_value: toNumber(data[company.id].current_value),
      distributed: toNumber(data[company.id].disbursement),
      initial_value: toNumber(data[company.id].initial_value),
    })
  })
  return Object.values(data)
})

///////////////////////////////////////////////////////////////////////////////
// FILTER OPTIONS
///////////////////////////////////////////////////////////////////////////////

const companyOptions = computed(() => createOptions(companies.value, { label: 'name' }))
const industryOptions = computed(() => createOptions(industries.value, { label: 'name' }))
const investmentTypeOptions = computed(() => createOptions(investment_types.value, { label: 'name' }))
const tagOptions = computed(() => createOptions(tags.value, { label: 'name' }))
const methodOptions = computed(() => props.customMethodOptions || createOptions(methods.value, { label: 'name' }))

onMounted(async () => {
  emit('update:modelValue', {
    setFilterAll: setFilterAll,
    current_filter: filter,
  })
})
</script>

<template>
  <div>
    <div class="flex justify-between" v-if="is_header_visible">
      <h2 class="mb-4 text-2xl font-bold leading-5 text-gray-900 dark:text-gray-300 sm:text-3xl sm:leading-7">
        {{ title }}
      </h2>
      <a :href="`${rails_url()}/investments/new`" v-if="authStore.is_site_or_group_admin">
        <VButton variant="v-blue" class="h-fit">
          <div class="flex items-center gap-2">
            <VIcon name="plus" />
            Add Investment
          </div>
        </VButton>
      </a>
    </div>
    <div
      class="mb-10 mt-5 flex flex-wrap items-center gap-3 rounded border-l-4 border-[#85B5C9] bg-[#ABD0DF] p-1 py-3 sm:mt-0 sm:flex-row sm:p-3"
    >
      <VDropdownImproved v-model="filter.company_ids" aligned="left" :options="companyOptions" class="z-50">
        <VButton size="md">
          <div class="flex items-center space-x-2">
            <div>Companies: {{ getFilterLabel(filter.company_ids, Object.values(companies).length) }}</div>
            <VIcon name="chevron_selector_vertical" />
          </div>
        </VButton>
      </VDropdownImproved>
      <span>where</span>
      <VDropdownImproved v-model="filter.industry_ids" aligned="left" :options="industryOptions" class="z-50">
        <VButton size="md">
          <div class="flex items-center space-x-2">
            <div>Industries: {{ getFilterLabel(filter.industry_ids, Object.values(industries).length) }}</div>
            <VIcon name="chevron_selector_vertical" />
          </div>
        </VButton>
      </VDropdownImproved>
      <VDropdownImproved v-model="filter.investment_types" aligned="left" :options="investmentTypeOptions" class="z-50">
        <VButton size="md">
          <div class="flex items-center space-x-2">
            <div>
              Investment types: {{ getFilterLabel(filter.investment_types, Object.values(investment_types).length) }}
            </div>
            <VIcon name="chevron_selector_vertical" />
          </div>
        </VButton>
      </VDropdownImproved>
      <VDropdownImproved v-model="filter.tags" aligned="left" :options="tagOptions" class="z-50">
        <VButton size="md">
          <div class="flex items-center space-x-2">
            <div>Tags: {{ getFilterLabel(filter.tags, Object.values(tags).length) }}</div>
            <VIcon name="chevron_selector_vertical" />
          </div>
        </VButton>
      </VDropdownImproved>
      <VDropdownImproved
        v-model="filter.methods"
        aligned="left"
        :options="methodOptions"
        class="z-50"
        v-if="is_method_visible"
      >
        <VButton size="md">
          <div class="flex items-center space-x-2">
            <div>Methods: {{ getFilterLabel(filter.methods, methodOptions.length) }}</div>
            <VIcon name="chevron_selector_vertical" />
          </div>
        </VButton>
      </VDropdownImproved>
      <span>as of</span>
      <!-- <input type="date" @change="updateInvestments" v-model="filter.as_of_date" class="py-0.5" /> -->
      <VTextField
        v-model="filter.as_of_date"
        type="date"
        :onChange="updateInvestments"
        inputClass="py-1.5 text-sm"
        class="-mt-1"
      />
      <div class="sm:flex-grow">
        <VButton class="ml-auto mr-0" :click="clearFilter">Clear Filters</VButton>
      </div>
    </div>
    <PortfolioSection :investments="filtered_investments" :skeleton="skeleton" :is_portfolio_view_visible="false" />
    <VTable
      :columns="[
        {
          key: 'name',
          name: capitalize(t('shared.name')),
          type: 'string',
          align: 'left',
          fixed: true,
          sorted: true,
          is_visible: true,
        },
        {
          key: 'method_label',
          name: capitalize(t('shared.method')),
          type: 'string',
          align: 'left',
          is_visible: is_column_method_visible,
        },
        {
          key: 'initial_value',
          name: capitalize(t('shared.initial value')),
          type: 'currency',
          aggregate: 'sum',
          align: 'right',
          is_visible: true,
        },
        {
          key: 'current_value',
          name: capitalize(t('shared.current value')),
          sorted: true,
          type: 'currency',
          aggregate: 'sum',
          align: 'right',
          is_visible: true,
        },
        {
          key: 'disbursement',
          name: capitalize(t('shared.disbursement')),
          sorted: true,
          type: 'currency',
          aggregate: 'sum',
          align: 'right',
          is_visible: true,
        },
        {
          key: 'roi',
          name: capitalize(t('shared.ROI')),
          type: 'percent',
          align: 'center',
          is_visible: true,
        },
        {
          key: 'initial_shares',
          name: capitalize(t('shared.initial shares')),
          type: 'number',
          align: 'right',
          is_visible: false,
        },
        {
          key: 'current_shares',
          name: capitalize(t('shared.current shares')),
          type: 'number',
          align: 'right',
          is_visible: false,
        },
        {
          key: 'initial_price',
          name: capitalize(t('shared.initial price')),
          type: 'currency',
          align: 'right',
          is_visible: false,
        },
        {
          key: 'current_price',
          name: capitalize(t('shared.current price')),
          type: 'currency',
          align: 'right',
          is_visible: false,
        },
        {
          key: 'accrued_interest',
          name: capitalize(t('shared.accrued interest')),
          type: 'currency',
          align: 'right',
          is_visible: false,
        },
        {
          key: 'minimum_annual_return',
          name: capitalize(t('shared.minimum annual return')),
          type: 'currency',
          align: 'right',
          is_visible: false,
        },
        {
          key: 'initial_capital',
          name: capitalize(t('shared.initial capital')),
          type: 'currency',
          align: 'right',
          is_visible: false,
        },
        {
          key: 'valuation_cap',
          name: capitalize(t('shared.valuation cap')),
          type: 'currency',
          align: 'right',
          is_visible: false,
        },
        {
          key: 'ownership_percent',
          name: capitalize(t('shared.ownership percent')),
          type: 'percent',
          align: 'right',
          is_visible: false,
        },
        {
          key: 'expiration_date',
          name: capitalize(t('shared.expiration date')),
          type: 'date',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'terms.annual_dividend_rate',
          name: capitalize(t('shared.annual dividend rate')),
          type: 'number',
          align: 'right',
          is_visible: false,
        },
        {
          key: 'terms.board_approval_date',
          name: capitalize(t('shared.board approval date')),
          type: 'date',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'terms.type',
          name: capitalize(t('shared.type')),
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'terms.shares_authorized',
          name: capitalize(t('shared.shares authorized')),
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'terms.seniority',
          name: capitalize(t('shared.seniority')),
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'terms.issue_price',
          name: capitalize(t('shared.issue price')),
          type: 'number',
          align: 'right',
          is_visible: false,
        },
        {
          key: 'terms.common_conversion_rate',
          name: capitalize(t('shared.common conversion rate')),
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'terms.pro_rata',
          name: capitalize(t('shared.pro rata')),
          type: 'boolean',
          align: 'center',
          is_visible: false,
        },
        {
          key: 'terms.liquidation_preference',
          name: capitalize(t('shared.liquidation preference')),
          type: 'boolean',
          align: 'center',
          is_visible: false,
        },
        {
          key: 'terms.liquidation_preference_multiple',
          name: capitalize(t('shared.liquidation preference multiple')),
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'terms.participation_rights',
          name: capitalize(t('shared.participation rights')),
          type: 'boolean',
          align: 'center',
          is_visible: false,
        },
        {
          key: 'terms.participation_cap',
          name: capitalize(t('shared.participation cap')),
          type: 'boolean',
          align: 'center',
          is_visible: false,
        },
        {
          key: 'terms.participation_cap_multiple',
          name: capitalize(t('shared.participation cap multiple')),
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'terms.discount_rate',
          name: capitalize(t('shared.discount rate')),
          type: 'percent',
          align: 'right',
          is_visible: false,
        },
        {
          key: 'terms.first_payment_date',
          name: capitalize(t('shared.first payment date')),
          type: 'date',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'terms.interest_rate',
          name: capitalize(t('shared.interest rate')),
          type: 'percent',
          align: 'right',
          is_visible: false,
        },
        {
          key: 'terms.interest_type',
          name: capitalize(t('shared.interest type')),
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'terms.compounding_frequency',
          name: capitalize(t('shared.compounding frequency')),
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'terms.converts_to',
          name: capitalize(t('shared.converts to')),
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'terms.maturity_date',
          name: capitalize(t('shared.maturity date')),
          type: 'date',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'terms.annual_share_of_revenue',
          name: capitalize(t('shared.annual share of revenue')),
          type: 'number',
          align: 'right',
          is_visible: false,
        },
        {
          key: 'terms.minimum_annual_return_percentage',
          name: capitalize(t('shared.minimum annual return percentage')),
          type: 'percent',
          align: 'right',
          is_visible: false,
        },
        {
          key: 'terms.term_value',
          name: capitalize(t('shared.term value')),
          type: 'number',
          align: 'right',
          is_visible: false,
        },
        {
          key: 'terms.term_units',
          name: capitalize(t('shared.term units')),
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'terms.payment_frequency',
          name: capitalize(t('shared.payment frequency')),
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'terms.option_type',
          name: capitalize(t('shared.option type')),
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'terms.shares_allocated',
          name: capitalize(t('shared.shares allocated')),
          type: 'number',
          align: 'right',
          is_visible: false,
        },
        {
          key: 'terms.exercise_price',
          name: capitalize(t('shared.exercise price')),
          type: 'number',
          align: 'right',
          is_visible: false,
        },
        {
          key: 'terms.vesting_period_months',
          name: capitalize(t('shared.vesting period months')),
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'terms.cliff_months',
          name: capitalize(t('shared.cliff months')),
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'terms.after_cliff_vest_months',
          name: capitalize(t('shared.after cliff vest months')),
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'terms.vest_start_date',
          name: capitalize(t('shared.vest start date')),
          type: 'date',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'terms.expiration_period_years',
          name: capitalize(t('shared.expiration period years')),
          type: 'number',
          align: 'right',
          is_visible: false,
        },
      ]"
      :items="table_investments"
      :name="`portfolio`"
      :skeleton="skeleton"
      sub_item_key="investments"
      :slots="['name']"
      :expand="true"
    >
      <template #name="{ item }">
        <div class="flex items-center gap-1">
          <img :src="item.logo_url" class="h-[20px] w-[20px]" v-if="item.logo_url" />
          <div class="h-[20px] w-[20px]" v-else></div>
          <a class="hyperlink" :href="`${rails_url()}/companies/${item.id}`" v-if="!is_company_link_disabled">
            {{ item.name }}
          </a>
          <span v-else> {{ item.name }} </span>
        </div>
      </template>
      <template #subgroup.name="{ item }">
        <a
          class="cursor-pointer whitespace-break-spaces font-medium text-[#51889B] underline decoration-[#51889B]/50"
          :href="`${rails_url()}/companies/${item.company.id}/terms/${get(item, 'term_sheet.id')}`"
          v-if="isAdmin"
        >
          {{ item.name }}
        </a>
        <span v-else> {{ item.name }} </span>
      </template>
    </VTable>
  </div>
</template>
