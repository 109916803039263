<script setup lang="ts">
import { computed, ref } from 'vue'
import { VLoading } from '@/modules/shared/components'

const props = withDefaults(
  defineProps<{
    active?: boolean
    click?: any
    cursor?: string
    disabled?: boolean
    loading?: boolean
    size?: string
    type?: 'button' | 'submit'
    variant?: string
  }>(),
  {
    active: false,
    click: null,
    cursor: 'pointer',
    disabled: false,
    loading: undefined,
    size: 'md',
    type: 'button',
    variant: 'secondary',
  },
)

///////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////

const internalLoading = ref(false)
const is_loading = computed(() => (props.loading !== undefined ? props.loading : internalLoading.value))

const on_click = async () => {
  if (props.click === null) {
    return
  }

  try {
    internalLoading.value = true
    await props.click()
  } finally {
    internalLoading.value = false
  }
}

const disabled = computed(() => props.disabled || is_loading.value)

///////////////////////////////////////////////////////////////////////////////
// Styling
///////////////////////////////////////////////////////////////////////////////

const base =
  'relative block rounded-md whitespace-nowrap hover:transition-shadow active:transition-none active:translate-y-px'

// prettier-ignore
const cursors = {
  'disabled': 'cursor-not-allowed',
  'move': 'cursor-move',
  'pointer': 'cursor-pointer',
}

// prettier-ignore
const base_variants = {
  'invisible': 'rounded-md font-medium text-gray-600 hover:bg-gray-100 active:bg-gray-200 active:translate-y-0',
  'primary': 'bg-gray-800 font-medium text-white shadow-sm hover:shadow-md active:shadow-sm hover:bg-gray-700',
  'secondary': 'border-none bg-white font-medium text-gray-700 shadow-sm hover:shadow-md active:shadow-sm',
  'v-blue': 'border-none bg-[#3b88af] font-medium text-white shadow-sm hover:shadow-md active:shadow-sm',
  'v-green': 'border-none bg-[#408F93] font-medium text-white shadow-sm hover:shadow-md active:shadow-sm',
  'v-red': 'border-none bg-red-600 font-medium text-white shadow-sm hover:shadow-md active:shadow-sm',
  'v-white': 'border-[#3b88af] border-[1px] text-[#3b88af] font-medium shadow-sm hover:shadow-md active:shadow-sm',
}

// array is when [false, true] is passed in as "active"
const active_variants = {
  secondary: ['ring-1 ring-gray-700/20 hover:ring-gray-700/30', 'ring-1 ring-[#3b88af] hover:ring-[#3b88af]'],
}

// prettier-ignore
const disabled_variants = {
  invisible: 'text-gray-400 opacity-50 active:bg-transparent hover:bg-transparent shadow-none hover:shadow-none active:shadow-none',
  primary: 'bg-gray-200 text-gray-400 opacity-50 shadow-none hover:shadow-none active:shadow-none',
  secondary: 'bg-gray-200 text-gray-400 opacity-50 shadow-none hover:shadow-none active:shadow-none ring-1 ring-gray-200/20',
}

const sizes = {
  xs: 'px-2 py-1 text-xs',
  'xs-narrow': 'px-1 py-1 text-xs',
  sm: 'px-2 py-1 text-sm',
  md: 'sm:px-2.5 sm:py-1.5 sm:text-sm px-2 py-1 text-sm',
  lg: 'px-3 py-2 text-sm',
  xl: 'px-3.5 py-2.5 text-sm',
}

const classes = computed(() => {
  const cursorClass = cursors[props.cursor] || ''
  const variantClass = base_variants[props.variant] || ''
  const sizeClass = sizes[props.size] || ''

  const classList = [base, variantClass, sizeClass]

  try {
    if (props.active) {
      classList.push(active_variants[props.variant][1])
    } else {
      classList.push(active_variants[props.variant][0])
    }
  } catch (e) {
    // do nothing
  }

  if (disabled.value) {
    classList.push(disabled_variants[props.variant])
    classList.push('cursor-not-allowed')
  } else {
    classList.push(cursorClass)
  }

  return classList
})
</script>

<template>
  <button :type="type" :class="classes" :disabled="disabled || is_loading" @click="on_click">
    <div :class="is_loading ? 'invisible' : 'visible'">
      <slot></slot>
    </div>

    <template v-if="is_loading">
      <div class="absolute inset-0 flex items-center justify-center">
        <VLoading />
      </div>
    </template>
  </button>
</template>
