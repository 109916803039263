<script setup lang="ts">
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import { allCountries } from 'country-region-data'
import { computed } from 'vue'

const countries = computed(() => {
  return allCountries.filter((country) => country[1] === 'US')
})
</script>

<template>
  <TheLayout> </TheLayout>
</template>
