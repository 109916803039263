import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useFetch } from '@/modules/shared/composables/use-fetch'
import { addItems, clearItems } from '@/modules/shared/utils/store'
import { CID } from '@/modules/shared/utils/store.types'

///////////////////////////////////////////////////////////////////////////////
// Types
///////////////////////////////////////////////////////////////////////////////

// TODO: move to shared types
enum EntityType {
  Fund = 'fund',
  GP = 'gp',
  SPV = 'spv',
}

// TODO: move to shared types
type Entity = {
  _cid: CID // currently, this doesn't exist on the server
  _key: string // used to create _cid until the server provides a composite id
  id: string
  date_established: string
  entity_type: EntityType
  legal_name: string
  name: string
  tax_id: string
  group: {
    name: string
    subdomain: string
  }
}

///////////////////////////////////////////////////////////////////////////////
// Store
///////////////////////////////////////////////////////////////////////////////

type EntityMap = Map<CID, Entity>

export const useEntityStore = defineStore('admin/entityStore', () => {
  const route = useRoute()
  const baseUrl = computed(() => `/${route.params.slug}/admin`)

  const items = ref<EntityMap>(new Map())
  const entities = computed(() => Array.from(items.value.values()))

  const fetchEntities = async () => {
    clearItems(items)

    const { data, error } = await useFetch(`${baseUrl.value}/entities`)
      .get()
      .json<{ data: Entity[]; has_more: boolean }>()

    if (error.value) {
      // TODO: handle error (e.g., display a message to the user)
      console.error(error.value)
    }

    addItems(
      items,
      data.value.data.map((item) => ({ ...item, _key: item.entity_type })),
    )
  }

  return {
    items,
    entities,
    fetchEntities,
  }
})
