<script setup lang="ts">
import { ref } from 'vue'

interface Option {
  label: string
  value: string
}

const props = defineProps<{
  defaultOption?: string
  options: Option[]
}>()

const emit = defineEmits<{
  (e: 'select', value: string): void
}>()

const selectedOption = ref(props.defaultOption)

const getOptionClasses = (option: Option) => {
  return [
    'cursor-pointer',
    'rounded-full',
    'px-2.5',
    'py-1',
    selectedOption.value === option.value
      ? 'bg-gray-700 text-white hover:bg-gray-600'
      : 'bg-gray-100 hover:bg-gray-200',
  ]
}

const selectOption = (option: Option) => {
  selectedOption.value = option.value
  emit('select', option.value)
}
</script>

<template>
  <div class="flex items-center justify-center">
    <div
      class="inline-flex space-x-1 rounded-full bg-white p-1 text-xs font-medium text-gray-700 shadow-sm ring-1 ring-gray-700/10"
    >
      <div
        v-for="(option, index) in options"
        :key="index"
        :class="getOptionClasses(option)"
        @click="selectOption(option)"
      >
        {{ option.label }}
      </div>
    </div>
  </div>
</template>
