<script setup lang="ts">
import { onClickOutside, useConfirmDialog, useMagicKeys } from '@vueuse/core'
import { computed, ref, watch } from 'vue'
import { VIcon } from './'
import { useSearch } from '../composables/use-search'
import { search } from '../utils/deep-search'

// NOTE: this is duplicated in multiple places
// TODO: move to shared types
type Option = {
  disabled?: boolean
  label: string
  value: string
}

const props = withDefaults(
  defineProps<{
    aligned?: string
    hideDisabled?: boolean
    modelValue: string[]
    multi?: boolean
    showSelectedList?: boolean
    options: Option[]
  }>(),
  {
    aligned: 'left',
    hideDisabled: false,
    multi: true,
    showSelectedList: false,
  },
)

const emit = defineEmits(['update:modelValue'])

const { isRevealed, reveal, cancel } = useConfirmDialog()

function isSelected(value: string) {
  return props.modelValue.includes(value)
}

const selectOption = (value: string) => {
  let newValue

  if (!isSelected(value)) {
    if (props.multi) {
      newValue = [...props.modelValue, value]
    } else {
      newValue = [value]
    }

    emit('update:modelValue', newValue)
  }

  cancel()
}

const deselectOption = (value: string) => {
  let newValue

  if (props.multi) {
    newValue = props.modelValue.filter((val) => val !== value)
  } else {
    newValue = []
  }

  emit('update:modelValue', newValue)
}

const target = ref(null)

onClickOutside(target, (event) => cancel())

const { escape } = useMagicKeys()

///////////////////////////////////////////////////////////////////////////////
// Search
///////////////////////////////////////////////////////////////////////////////

const { clearQuery, query } = useSearch()
const preparedOptions = computed(() => search(props.options, query.value))

watch(
  () => props.modelValue,
  () => clearQuery(),
)

watch(escape, (v) => {
  if (v) {
    cancel()
  }
})
</script>

<template>
  <div class="relative z-50" :class="aligned === 'left' ? 'text-left' : 'text-right'" ref="target">
    <span @click="isRevealed ? cancel() : reveal()">
      <slot name="default"></slot>
    </span>
    <ul
      v-show="isRevealed"
      class="absolute z-50 mt-1 max-h-[480px] w-full min-w-[220px] divide-y-0 divide-gray-100 overflow-y-auto rounded-md bg-white shadow-lg ring-[1px] ring-gray-700/20"
      :class="aligned === 'left' ? 'left-0' : 'right-0'"
    >
      <li>
        <div class="relative flex-grow">
          <input
            type="text"
            class="relative z-40 w-full rounded-none rounded-tl-md border-b border-l-0 border-r-0 border-t-0 border-gray-200 bg-transparent text-sm focus:border-gray-200 focus:ring focus:ring-sky-200 focus:ring-opacity-0"
            :placeholder="`Search by anything`"
            v-model="query"
            ref="search_bar"
          />
          <div class="pointer-events-none absolute inset-y-0 right-0 z-50 flex items-center pr-3">
            <button
              @click="clearQuery"
              class="curosr-pointer pointer-events-auto inline-block rounded-md bg-gray-100 p-1"
              v-show="query"
            >
              <VIcon name="x" class="h-3 w-3 text-gray-400" />
            </button>
          </div>
        </div>
      </li>
      <li
        v-for="option in preparedOptions"
        v-show="!(option.disabled && hideDisabled)"
        :key="option.label"
        class="relative flex cursor-pointer items-center space-x-2 whitespace-nowrap px-3 py-1.5 pr-6 text-sm text-gray-700 hover:bg-gray-100"
        @click="() => selectOption(option.value)"
      >
        <div class="font-medium">
          {{ option.label }}
        </div>
      </li>
    </ul>
    <ul v-if="multi && showSelectedList" class="mt-3 flex flex-wrap items-start gap-2">
      <li
        v-for="option in options"
        :key="option.label"
        v-show="isSelected(option.value)"
        class="flex items-center gap-1 rounded-full bg-gray-100 px-2.5 py-0.5 text-sm"
      >
        {{ option.label }}
        <VIcon name="x" @click="deselectOption(option.value)" />
      </li>
    </ul>
  </div>
</template>
