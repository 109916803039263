import { createApp } from 'vue'
import { createPinia } from 'pinia'
import head from './head'
import { i18n } from './i18n'
import router from './router'
import App from './App.vue'
import { init as SentryInit } from './integrations/sentry/sentry'

import './index.css'

const app = createApp(App)
const pinia = createPinia()

SentryInit(app, router)

// prettier-ignore
app
  .use(pinia)
  .use(head)
  .use(i18n)
  .use(router)
  .mount('#app')
