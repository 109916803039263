import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { createObjectFromArray } from '@/modules/shared/utils/create-object-from-array'
import { useAuthStore } from '../../auth/stores/auth-store'
import { useProductService } from '../services/product-service'

// Utils

const handle_error = (err: any) => {
  // TODO handle error
  console.error(err)
}

export const useProductStore = defineStore('product', () => {
  const authStore = useAuthStore()
  const route = useRoute()
  const service = useProductService({
    headers: {
      Authorization: `Bearer ${authStore.access_token}`,
    },
    route,
  })

  /////////////////////////////////////////////////////////////////////////////
  // STATE
  /////////////////////////////////////////////////////////////////////////////

  // list of products
  const items = ref([])

  /////////////////////////////////////////////////////////////////////////////
  // GETTERS
  /////////////////////////////////////////////////////////////////////////////

  const createPriceLookup = (products: any) => {
    return products.reduce((acc, product) => {
      product.stripe_prices.forEach((price) => {
        acc[price.id] = price
      })
      return acc
    }, {})
  }

  const prices = computed(() => createPriceLookup(items.value))

  const products = computed(() => createObjectFromArray(items.value, 'stripe_product_id'))

  const group_products = computed(() => {
    return items.value.filter((product: any) => product.metadata.category === 'group')
  })

  const group_products_ids = computed(() => {
    return items.value
      .filter((product: any) => product.metadata.category === 'group')
      .map((product: any) => product.stripe_product_id)
  })

  const price_to_product_lookup = computed(() => {
    return Object.keys(prices.value).reduce((acc, id) => {
      return { ...acc, [id]: prices.value[id].stripe_product_id }
    }, {})
  })

  // TODO fund_products_ids

  // TODO spv_products_ids

  /////////////////////////////////////////////////////////////////////////////
  // ACTIONS
  /////////////////////////////////////////////////////////////////////////////

  const actions = {
    list: async () => {
      const [err, data] = await service.list()
      if (err) return handle_error(err)
      items.value = data.data
    },
  }

  /////////////////////////////////////////////////////////////////////////////
  // RETURN
  /////////////////////////////////////////////////////////////////////////////

  return {
    // state
    items,
    prices,
    products,
    // getters
    price_to_product_lookup,
    group_products,
    group_products_ids,
    // actions
    ...actions,
  }
})
