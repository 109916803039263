<script setup lang="ts">
import { onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useMagicKeys } from '@vueuse/core'
import { loadStripe } from '@stripe/stripe-js'
import { RouterLinkBack, VButton, VButtonInvisible, VSection } from '@/modules/shared/components'
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import { useCustomerStore } from '../stores/customer-store'

const router = useRouter()
const route = useRoute()
const customerStore = useCustomerStore()

///////////////////////////////////////////////////////////////////////////////
// Keyboard shortcuts
///////////////////////////////////////////////////////////////////////////////

const { escape } = useMagicKeys()

watch(escape, (v) => {
  if (v) {
    router.push({ name: 'billing-group' })
  }
})

///////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////

let stripe
let elements

const createBillable = (route) => ({
  id: route.params.billable_id !== undefined ? route.params.billable_id : customerStore.customer.metadata.group_id,
  type: route.params.billable_type !== undefined ? route.params.billable_type : 'group',
})

const billable = createBillable(route)
// If you disable collecting fields in the Payment Element, you
// must pass equivalent data when calling `stripe.confirmPayment`.
const handleSubmit = async () => {
  var url = window.location.href.split('add-payment-method')[0]

  const { error } = await stripe.confirmSetup({
    elements,
    confirmParams: {
      return_url: url,
      payment_method_data: {
        billing_details: {
          name: customerStore.customer.name,
          email: customerStore.customer.email,
        },
        metadata: {
          billables: `${billable.type}:${billable.id}`,
        },
      },
    },
  })
}

// https://stripe.com/docs/payments/save-and-reuse
// https://stripe.com/docs/js/elements_object/create_payment_element
// https://stripe.com/docs/elements/appearance-api?platform=web#rules

onMounted(async () => {
  await customerStore.retrieve()
  const setup_intent = await customerStore.retrieve_setup_intent()

  stripe = await loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY)

  // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 3
  elements = stripe.elements({
    clientSecret: setup_intent.client_secret,
    appearance: {
      labels: 'above',
      theme: 'stripe',
    },
  })

  const paymentElement = elements.create('payment', {
    fields: {
      billingDetails: {
        name: 'never',
        email: 'never',
      },
    },
    layout: {
      defaultCollapsed: true,
      radios: false,
      spacedAccordionItems: false,
      type: 'accordion',
    },
  })

  paymentElement.mount('#payment-element')
})

// Customize which fields are collected by the Payment Element
</script>

<template>
  <TheLayout>
    <!-- nav -->
    <VSection>
      <RouterLinkBack>
        <VButtonInvisible>← Back</VButtonInvisible>
      </RouterLinkBack>
    </VSection>
    <!-- main -->
    <VSection label="Add Payment Method">
      <form id="payment-form" class="mb-6">
        <div id="payment-element">
          <!-- Elements will create form elements here -->
        </div>
        <div id="error-message">
          <!-- Display error message to your customers here -->
        </div>
      </form>
      <div class="space-x-2">
        <VButton id="submit" variant="primary" :click="handleSubmit">Save for later</VButton>
        <RouterLinkBack>
          <VButton>Go back</VButton>
        </RouterLinkBack>
      </div>
    </VSection>
  </TheLayout>
</template>
